import { SxProps, Theme } from '@mui/material'

type errorFallbackStylesProps = 'page'

export const errorFallbackStyles: Record<errorFallbackStylesProps, SxProps<Theme> | undefined> = {
  page: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 24,
    height: '100vh',
    [down('sm')]: {},
  }),
}
