import { FC, JSX } from 'react'
import { Box, Grid, Button, Container } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { notFoundStyles } from './not-found.styles'
import { Layout } from '../../components'
import { img } from '../../imgs'

export const NotFoundPage: FC = (): JSX.Element => {
  const navigate = useNavigate()

  const onNavigate = () => navigate('../')

  return (
    <Layout>
      <Box sx={notFoundStyles.container}>
        <Container sx={notFoundStyles.content}>
          <Box sx={notFoundStyles.error}>404</Box>
          <Box sx={notFoundStyles.text}>
            <Box fontWeight={600} fontSize={{ xs: '5vw', sm: '3vw', md: 30 }} maxWidth={450}>
              Упс! Такой страницы у нас нет
            </Box>
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              sx={{
                maxWidth: { xs: '50vw', sm: '30vw', md: 300 },
                padding: { xs: '2.5vw 6.66vw', sm: '1.5vw 4vw!important', md: '0!important' },
                fontSize: { xs: '2.66vw!important', sm: '1.6vw!important', md: '16px!important' },
              }}
              onClick={onNavigate}
            >
              На главную
            </Button>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}
