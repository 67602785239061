import { FC, JSX } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { PatternFormat } from 'react-number-format'
import { Box, TextField, FormHelperText } from '@mui/material'

type PhoneProps = {
  name?: string
  label?: string
  disabled?: boolean
  required?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  variant?: 'standard' | 'filled' | 'outlined'
}

export const PhoneInput: FC<PhoneProps> = ({
  form,
  name = 'phone',
  label = 'Телефон',
  disabled = false,
  required = false,
  placeholder = 'Введи телефон',
  variant = 'outlined',
}): JSX.Element => {
  const { control, formState } = form

  return (
    <Box>
      <Controller
        name={name}
        defaultValue=""
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <PatternFormat
            fullWidth
            label={label}
            value={value}
            onBlur={onBlur}
            variant={variant}
            required={required}
            onChange={onChange}
            disabled={disabled}
            customInput={TextField}
            placeholder={placeholder}
            format="+7 (###) ### ## ##"
            InputProps={{ inputProps: { inputMode: 'numeric' } }}
          />
        )}
      />
      <FormHelperText>
        <>{formState.errors[name]?.message}</>
      </FormHelperText>
    </Box>
  )
}
