import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IGameState } from './game.types'
import { getInfoGame } from './game.service'
import { IGame } from '../../types'

const initialState: IGameState = {
  data: undefined,
  init: false,
  loading: false,
}

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setResetGame(state: IGameState) {
      return { ...state, ...initialState }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoGame.pending, (state: IGameState) => {
      state.loading = true
    })
    builder.addCase(getInfoGame.fulfilled, (state: IGameState, action: PayloadAction<{ data: { game: IGame[] } }>) => {
      state.data = [...action.payload.data.game]
      state.init = true
      state.loading = false
    })
    builder.addCase(getInfoGame.rejected, (state: IGameState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.init = false
      state.loading = false
    })
  },
})

export const gameActions = gameSlice.actions
export const gameReducer = gameSlice.reducer
