import { FC, JSX, ReactNode } from 'react'
import { Box, Fade, SxProps, Theme, useMediaQuery } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { MenuRoot } from './header/header'
import { Footer } from './footer/footer'

interface ILayout {
  children: ReactNode
}

export const Layout: FC<ILayout> = ({ children }): JSX.Element => {
  const { pathname } = useLocation()
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  return (
    <Fade in timeout={{ enter: 500 }}>
      <Box sx={layoutStyles.body}>
        <Box sx={layoutStyles.header}>
          <MenuRoot />
        </Box>

        <Box sx={layoutStyles.main}>{children}</Box>

        <Box sx={layoutStyles.footer}>
          <Footer />
        </Box>
      </Box>
    </Fade>
  )
}

type LayoutStylesProps = 'body' | 'header' | 'main' | 'footer'

const layoutStyles: Record<LayoutStylesProps, SxProps<Theme> | undefined> = {
  body: ({ palette }: Theme) => ({
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: palette.background.default,
  }),
  header: () => ({ zIndex: 101 }),
  main: ({ breakpoints: { down } }: Theme) => ({
    zIndex: 99,
    flex: '1 0 auto',
    position: 'relative',
    minHeight: 'calc(100vh - 350px)',
    [down('md')]: { minHeight: 'calc(100vh - 490px)' },
    [down(760)]: { minHeight: 'calc(100vh - 560px)' },
    [down('sm')]: { minHeight: 'auto' },
  }),
  footer: () => ({ zIndex: 98, flexShrink: 0 }),
}
