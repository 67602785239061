import { FC, JSX, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid, SxProps, Theme } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { EmailInput, PasswordInput, Snackbar, StopAction, SubmitButton, TrackEvent, validation } from '../../components'
import { FormProps } from '../../types'
import { signin } from '../../api'
import { getInfoUser } from '../../store/auth/auth.service'
import { useReduxDispatch } from '../../hooks'

type SignInProps = { email: string; password: string }

const schema = validation({
  email: yup.string().required().email(),
  password: yup.string().required().length(6),
})
interface ISignInForm {
  message?: string | undefined
  clearToken: () => void
}

export const SigninForm: FC<ISignInForm> = ({ message, clearToken = () => {} }): JSX.Element => {
  const { parse } = queryString
  const { search } = useLocation()
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const parsed = parse(search)

  const hookForm = useForm<any>({
    defaultValues: { email: parsed.username ? parsed.username : parsed.login },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })
  const [formProps, setFormProps] = useState<FormProps<SignInProps>>({
    data: { email: '', password: '' },
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const onSubmitForm = async ({ email, password }: SignInProps) => {
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined }, processed: true })
    return await signin(email, password.replace(/\s/g, ''))
      .then(() => {
        TrackEvent({
          eventCategory: 'Authorization',
          eventAction: 'send_a_form',
          eventLabel: 'success',
        })
        dispatch(getInfoUser()).then(() => {
          clearToken()
          navigate(parsed.subscribe === 'true' ? 'subscribe' : '/profile')
        })
      })
      .catch((err) => {
        setFormProps({
          ...formProps,
          processed: false,
          snackbar: { ...formProps.snackbar, message: err.response.data.message },
        })
        TrackEvent({
          eventCategory: 'Authorization',
          eventAction: 'send_a_form',
          eventLabel: 'unsuccess',
          eventContext: err.response.data.message,
        })
      })
  }
  const onForgot = () => {
    navigate(`../?forgot${hookForm.getValues('email') ? `&login=${hookForm.getValues('email')}` : ''}`, {
      replace: true,
    })
    TrackEvent({
      eventCategory: 'Authorization',
      eventAction: 'click_forgot',
    })
  }

  const onSignup = () => {
    navigate({ pathname: '../', search: 'signup' })
    TrackEvent({
      eventCategory: 'Authorization',
      eventAction: 'click_registration',
    })
  }

  useEffect(() => hookForm.setFocus('email'), [])

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      <Box py={1}>
        <Grid container direction="row" justifyContent="center">
          <Grid item width="100%" maxWidth={390}>
            {message && (
              <Box mb={2} color="#C5061D" fontWeight={500} textAlign="center" sx={{ fontSize: { xs: 15, sm: 17 } }}>
                {message}
              </Box>
            )}
            {/* <Box width="100%" mb={3}> */}
            {/*  <Social label="Авторизоваться через" type="signin" /> */}
            {/* </Box> */}
            <Box width="100%" height={75}>
              <EmailInput variant="outlined" name="email" form={hookForm} disabled={formProps.processed} />
            </Box>
            <Box width="100%" height={75} mt={1}>
              <PasswordInput name="password" form={hookForm} variant="outlined" disabled={formProps.processed} />
            </Box>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Box sx={formStyles.link} onClick={onForgot}>
                  Забыли пароль?
                </Box>
              </Grid>
            </Grid>
            <Snackbar {...formProps.snackbar} variant="error" />
          </Grid>
        </Grid>

        <Grid container justifyContent="center">
          <Grid item width="100%" maxWidth={290}>
            <Box width="100%" mt={{ xs: 2, sm: 4 }}>
              <SubmitButton fullWidth title="Войти" disabled={formProps.processed} processed={formProps.processed} />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}

type FormStylesProps = 'link' | 'signup'

const formStyles: Record<FormStylesProps, SxProps<Theme> | undefined> = {
  link: ({ breakpoints: { down }, font, palette }: Theme) => ({
    mt: '-10px',
    mb: '16px',
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    transition: '0.3s',
    textDecoration: 'none',
    color: palette.secondary.contrastText,
    textDecorationColor: `${palette.secondary.contrastText}!important`,
    '-webkit-tap-highlight-color': 'transparent',
    '&:hover': {
      textDecoration: 'underline',
    },
    [down('sm')]: {
      fontSize: 16,
    },
  }),
  signup: ({ breakpoints: { down }, palette }: Theme) => ({
    m: '15px',
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer',
    transition: '0.3s',
    textTransform: 'uppercase',
    color: palette.secondary.main,
    '&:hover': { color: `#FF5863!important` },
    [down('sm')]: {
      fontSize: 16,
    },
  }),
}
