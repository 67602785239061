import { FC, JSX, useEffect, useState } from 'react'
import { Grid, SnackbarContent, IconButton, Box, Collapse, useTheme } from '@mui/material'
import { Close, Warning, Info, Error, CheckCircle } from '@mui/icons-material'
import { SnackbarProps, messageVariants } from '../../types'
import { str } from '../../utils'

type VariantProps = {
  message: string
  variant: messageVariants
}

export const Snackbar: FC<SnackbarProps> = ({ variant = 'error', message, onClose }): JSX.Element => {
  const {
    palette: { success, warning, error, info },
  } = useTheme()

  const [variantState, setVariantState] = useState<VariantProps>({ variant: 'reset', message: '' })

  const variantColor = { success, warning, error, info, reset: { main: 'white' } }
  const variantIcon = { success: CheckCircle, info: Info, warning: Warning, error: Error, reset: undefined }

  const Icon = variantIcon[variantState.variant]
  const [open, setOpen] = useState<boolean>(false)

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false)
      if (onClose) onClose()
    }, 150)
  }

  useEffect(() => {
    if (message) {
      setVariantState({ variant, message })
      setTimeout(() => {
        setOpen(true)
      }, 150)
    } else handleClose()
  }, [message])

  return (
    <Collapse in={open}>
      <SnackbarContent
        message={
          <Box id="client-snackbar" color={variantColor[variantState.variant]?.main}>
            <Grid container wrap="nowrap" justifyContent="space-between" alignItems="center" spacing={2}>
              <Grid item>{Icon && <Icon color="inherit" style={{ fontSize: 24 }} />}</Grid>

              <Grid item>
                <Box key="snackbar-message">{str.normalize(variantState.message)}</Box>
              </Grid>

              <Grid item>
                <IconButton color="error" sx={{ mr: '-12px' }} onClick={handleClose}>
                  <Close style={{ fontSize: 24 }} />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        }
      />
    </Collapse>
  )
}
