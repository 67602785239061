import { ForwardRefExoticComponent, FC, JSX, forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import { Box, Button, Collapse, SxProps, Theme, Container } from '@mui/material'
import { Modal } from '..'

export const FinishAction: FC = (): JSX.Element => {
  const [show, setshow] = useState<boolean>(true)
  const [collapse, setCollapse] = useState<boolean>(true)

  const onClose = () => {
    setCollapse(false)
  }

  useEffect(() => {
    if (!collapse) {
      setTimeout(() => setCollapse(true), 200)
      setshow(false)
    }
  }, [collapse])

  return (
    <Modal
      open={show}
      title="Уважаемые участники!"
      onClose={onClose}
      closeButton
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
          },
        },
        root: {
          style: {
            zIndex: 1301,
          },
        },
      }}
      PaperProps={{
        style: {
          width: 'calc(100% - 17px)',
          margin: 'auto 8.5px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Collapse in={collapse}>
        <Box sx={FinishStyles.box}>Регистрация в акции завершена 31&nbsp;августа&nbsp;2024&nbsp;года.</Box>
      </Collapse>
    </Modal>
  )
}

type FinishStylesProps = 'box'

const FinishStyles: Record<FinishStylesProps, SxProps<Theme> | undefined> = {
  box: ({ breakpoints: { down }, palette }: Theme) => ({
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: '24.8px',
  }),
}
