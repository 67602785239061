export enum DisplayMode {
  BROWSER = 'browser',
  STANDALONE = 'standalone',
}

export enum DetectDevice {
  IOS = 'IOS',
  ANDROID = 'ANDROID',
  OTHER = 'OTHER',
}

export enum DetectBrowser {
  YA = 'YA',
  CHROME = 'CHROME',
  SAFARI = 'SAFARI',
  FIREFOX = 'FIREFOX',
  OPERA = 'OPERA',
  EDG = 'EDG',
  OTHER = 'OTHER',
}

export type SoundProps = {
  src: string
  loop?: boolean
}

export type SoundResponse = {
  isPlayed: boolean
  onPlay: () => void
  onStop: () => void
}
