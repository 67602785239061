import { FC, JSX, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { ToastContainer } from 'react-toastify'
import * as ls from 'local-storage'
import { RoutesRoot } from './routes'
import { ModalRoot } from './modal-root'
import { Toast } from '../types'
import { Preloader, StartAction } from '../components'
import { getInfoConstants } from '../store/constants/constants.service'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../hooks'
import { getInfoUser } from '../store/auth/auth.service'
import { NotificationRoot, banFirebase } from '../firebase'

export const App: FC = (): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setGreetings } = useReduxActions()

  const { data: constants, init: isConstants } = useReduxSelector((state) => state.constants)
  const { show: isGreetings } = useReduxSelector((state) => state.greetings)

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [ageCheck, setageCheck] = useState<boolean>(false)

  const uploadInfoConstants = useCallback(() => {
    dispatch(getInfoConstants()).then(() => {
      setTimeout(() => setIsLoading(true), 1500)
    })
    setTimeout(() => uploadInfoConstants(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoUser = useCallback(() => {
    dispatch(getInfoUser())
    setTimeout(() => uploadInfoUser(), 1000 * 60 * 30) // 30 минут
  }, [])

  useLayoutEffect(() => {
    uploadInfoConstants()
    uploadInfoUser()
  }, [])

  useEffect(() => {
    if (isConstants && !isGreetings) {
      if (constants?.IS_PROJECT_STOP_ACTION) {
        // setGreetings({ show: true })
      } else if (!ls.get('_info_modal_main')) {
        // setGreetings({ show: true })
      }
    }
  }, [isConstants])

  if (!isConstants || !isLoading) {
    return <Preloader />
  }

  return (
    <StartAction>
      <RoutesRoot />
      <ModalRoot />
    </StartAction>
  )
}
