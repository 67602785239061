import * as ls from 'local-storage'
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ApiAnswerStatus, Token } from '../types'

const { REACT_APP_API_URL = '/', REACT_APP_API_AUTH } = process.env
const axiosClient = axios.create({
  baseURL: `${REACT_APP_API_URL.replace(/^\/+/, '')}/`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Basic ${REACT_APP_API_AUTH}`,
  },
})

const apiFetchData = async (config: AxiosRequestConfig) => {
  return await axiosClient
    .request(config)
    .then((res: AxiosResponse<any, any>) => {
      return res.data
    })
    .catch((e) => {
      throw e
    })
}

export const reFetchPrivateAPIToken = (refreshToken: string) =>
  apiFetchData({
    url: '/oauth/refresh',
    method: 'post',
    data: {
      refresh_token: refreshToken,
    },
  })

const fetchPrivateAPIToken = async (username: string, password: string) =>
  await apiFetchData({
    url: '/oauth/token',
    method: 'post',
    data: { username, password },
  })
    .then((res: any) => {
      ls.set(Token.PRIVATE, res.data)
    })
    .catch((e) => {
      throw e
    })

const withPrivateAPIToken = async (config: AxiosRequestConfig) => {
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(Token.PRIVATE)

  if (!(token && token.access_token)) {
    return apiFetchData(config)
  } else {
    return await apiFetchData({ ...config, headers: { Authorization: `Bearer ${token.access_token}` } })
      .then((res: any) => {
        return res
      })
      .catch((e) => {
        if (e.response.status === ApiAnswerStatus.UNAUTHENTICATED) {
          return reFetchPrivateAPIToken(token.refresh_token || '')
            .then((res: any) => {
              ls.remove(Token.PRIVATE)
              ls.set(Token.PRIVATE, res.data)

              return apiFetchData({ ...config, headers: { Authorization: `Bearer ${res.data.access_token}` } })
            })
            .catch((err) => {
              if (err.response.status === ApiAnswerStatus.UNAUTHENTICATED) {
                ls.remove(Token.PRIVATE)
                throw err
              } else {
                return apiFetchData({ ...config })
              }
            })
        } else if (e.response.status === ApiAnswerStatus.NEED_FULL_REGISTER) {
          throw e.response
        } else if (e.response.status === ApiAnswerStatus.BAD_REQUEST) {
          throw e.response
        } else {
          throw e
        }
      })
  }
}

const withPublicAPIToken = async (config: AxiosRequestConfig) => {
  return await apiFetchData({ ...config })
    .then((res: any) => {
      return res
    })
    .catch((e) => {
      console.error('ошибка public запроса', e)
      throw e
    })
}

export { apiFetchData, withPrivateAPIToken, withPublicAPIToken, fetchPrivateAPIToken }
