import { SxProps, Theme } from '@mui/material'
import { img } from '../../imgs'

type startStylesProps = 'page'

export const startStyles: Record<startStylesProps, SxProps<Theme> | undefined> = {
  page: ({ breakpoints: { down } }: Theme) => ({
    height: '100vh',
    overflowX: 'hidden',
    position: 'relative',
    backgroundColor: '#FFFFFF',
  }),
}
