import { FC, JSX, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { Box, Container, Grid, Tab, Tabs } from '@mui/material'
import { infoProfileStyles } from './info-profile.styles'
import { TabPanel } from '../../../../components'
import { DocsList, PrizesList } from './tables'
import { useReduxDispatch, useReduxSelector } from '../../../../hooks'
import { getInfoDocs, getInfoPrizes } from '../../../../store/profile/profile.service'

const countArray = (ar: any[]) => (ar ? ar.length : 0)

export const InfoProfileSection: FC<{ uploadInfoProfile: () => void }> = ({ uploadInfoProfile }): JSX.Element => {
  const dispatch = useReduxDispatch()

  const { data: profileInfo } = useReduxSelector((state) => state.profile)

  const { docs, prizes } = profileInfo || {}

  const [tab, setTab] = useState<number>(0)

  const a11yProps = (index: number) => {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }
  }

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const loadDocList = useCallback(() => {
    dispatch(getInfoDocs())
  }, [])

  const loadPrizeList = useCallback(() => {
    dispatch(getInfoPrizes())
  }, [])

  useEffect(() => {
    loadDocList()
    loadPrizeList()
  }, [])

  return (
    <Box sx={infoProfileStyles.section}>
      <Box>
        <Grid container justifyContent="center">
          <Grid item xs="auto">
            <Box sx={infoProfileStyles.tabs}>
              <Tabs value={tab} onChange={handleChangeTab}>
                <Tab label="Мои чеки" {...a11yProps(0)} sx={infoProfileStyles.tabItem} />
                <Tab label="Мои призы" {...a11yProps(1)} sx={infoProfileStyles.tabItem} />
              </Tabs>
            </Box>
          </Grid>
        </Grid>

        <TabPanel value={tab} index={0}>
          <DocsList updateInfoDoc={() => loadDocList()} />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <PrizesList updateInfoPrize={() => loadPrizeList()} />
        </TabPanel>
      </Box>
    </Box>
  )
}
