import { FC, JSX } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import { SxProps } from '@mui/system'

type TimeProps = {
  name: string
  label: string
  disabled?: boolean
  form: UseFormReturn<any>
  variant?: 'standard' | 'filled' | 'outlined'
}

export const TimeInput: FC<TimeProps> = ({
  name,
  label,
  form,
  disabled = false,
  variant = 'outlined',
}): JSX.Element => {
  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, onBlur, value, ...rest }, formState }) => (
        <DesktopTimePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          disableOpenPicker
          {...rest}
          slotProps={{
            textField: {
              onBlur,
              variant,
              fullWidth: true,
              color: 'secondary',
              helperText: `${formState.errors[name]?.message || ''}`,
            },
          }}
        />
      )}
    />
  )
}
