import { SxProps, Theme } from '@mui/material'
import { img } from '../../imgs'

type ProfileStylesProps = 'page' | 'content' | 'title' | 'subTitle' | 'userSection' | 'infoSection'

export const profileStyles: Record<ProfileStylesProps, SxProps<Theme> | undefined> = {
  page: ({ breakpoints: { down } }: Theme) => ({
    background: `url(${img.profile_bg}) bottom center no-repeat, #D0EAF9`,
    backgroundSize: '100% auto, auto',
  }),
  content: ({ breakpoints: { down } }: Theme) => ({
    [down('sm')]: {},
  }),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    mb: '15px',
    fontSize: 32,
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.primary.dark,
    [down('md')]: {
      mb: '5px',
      fontSize: 26,
    },
    [down('sm')]: {
      fontSize: 22,
    },
  }),
  subTitle: ({ breakpoints: { down }, palette }: Theme) => ({
    mb: '30px',
    fontSize: 15,
    fontWeight: 400,
    textAlign: 'center',
    color: palette.primary.dark,
    [down('md')]: {
      mb: '20px',
      fontSize: 15,
    },
    [down('sm')]: {
      mb: '10px',
      fontSize: 14,
    },
  }),
  userSection: ({ breakpoints: { down } }: Theme) => ({
    width: '100%',
    p: '120px 0 0',
    position: 'relative',
    [down(1400)]: {
      backgroundPosition: 'top -30px left, top -80px right -5%, top 80px left 34%, top 110px right 32%, top center',
      backgroundSize: '13% auto, 19% auto, 4% auto, 4% auto, cover',
    },
    [down('md')]: {
      p: '100px 0 0',
    },
    [down('sm')]: {
      p: '85px 0 0',
    },
  }),
  infoSection: ({ breakpoints: { down } }: Theme) => ({
    width: '100%',
    minHeight: 500,
    height: 'fit-content',
    [down('md')]: {
      minHeight: 'auto',
    },
  }),
}
