import { SxProps, Theme } from '@mui/material'
import { keyframes } from '@mui/system'
import { img } from '../../../../imgs'

type loginStylesProps =
  | 'section'
  | 'sectionLogin'
  | 'sectionRules'
  | 'loginToggleHolder'
  | 'loginToggleButton'
  | 'loginButton'
  | 'sectionRulesHead'
  | 'sectionRulesOption'
  | 'sectionRulesSeparator'

export const loginStyles: Record<loginStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    minHeight: '730px',
    display: 'flex',
    mt: '-100px',
    pt: '150px',
    flexDirection: 'row',
    borderRadius: '28px',
    [down('md')]: {
      height: 'fit-content',
      flexDirection: 'column-reverse',
      mt: '0',
      pt: '50px',
    },
  }),
  sectionLogin: ({ breakpoints: { down } }: Theme) => ({
    minHeight: '100%',
    width: '50%',
    display: 'flex',
    bgcolor: '#FFFFFF',
    flexDirection: 'column',
    p: '50px',
    boxSizing: 'border-box',
    borderRadius: '28px 0 0 28px',
    [down('md')]: {
      height: '50%',
      width: '100%',
      p: '40px 10px 10px 10px',
      bgcolor: 'rgba(255, 255, 255, 0.05)',
      backdropFilter: 'blur(50px)',
      borderRadius: '0 0 28px 28px',
    },
    [down('sm')]: {
      p: '20px 10px 10px 10px',
    },
  }),
  loginToggleHolder: ({ breakpoints: { down } }: Theme) => ({
    height: '60px',
    width: '100%',
    maxWidth: '480px',
    display: 'flex',
    position: 'relative',
    bgcolor: '#FFFFFF',
    borderRadius: '30px',
    m: '0 auto 50px auto',
    [down('md')]: {
      maxWidth: '50%',
      height: '6vw',
      minHeight: '44px',
      m: '0 auto 34px auto',
    },
    [down('sm')]: {
      maxWidth: '390px',
      height: '8vw',
    },
  }),
  loginToggleButton: ({ breakpoints: { down } }: Theme) => ({
    height: '100%',
    width: '50%',
    display: 'flex',
    position: 'absolute',
    bgcolor: '#AB0F7E',
    borderRadius: '30px',
    transition: '0.2s',
  }),
  loginButton: ({ breakpoints: { down } }: Theme) => ({
    height: '100%',
    width: '50%',
    zIndex: 1,
    fontSize: 18,
    fontWeight: 700,
    textTransform: 'uppercase',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.2s',
    '& div': {
      width: 'fit-content',
      cursor: 'pointer',
    },
    [down(1350)]: {
      fontSize: '1.33vw',
    },
    [down('md')]: {
      fontSize: '1.63vw',
    },
    [down('sm')]: {
      fontSize: '3vw',
    },
  }),
  sectionRules: ({ breakpoints: { down }, palette }: Theme) => ({
    minHeight: '100%',
    width: '50%',
    borderRadius: '0 28px 28px 0',
    bgcolor: '#003D84',
    p: '50px',
    boxSizing: 'border-box',
    color: palette.primary.main,
    fontWeight: 800,
    [down('md')]: {
      height: '50%',
      width: '100%',
      borderRadius: '28px 28px 0 0',
      p: '20px 30px',
      bgcolor: '#FFFFFF',
      color: palette.primary.dark,
    },
  }),
  sectionRulesHead: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 32,
    textTransform: 'uppercase',
    width: '70%',
    mb: '50px',
    [down(1350)]: {
      fontSize: '2.37vw',
    },
    [down(1150)]: {
      width: '70%',
    },
    [down('md')]: {
      width: '40%',
      m: '0 auto',
      textAlign: 'center',
      fontSize: '2.9vw',
      mb: '20px',
    },
    [down('sm')]: {
      fontSize: '5.86vw',
      width: '90%',
    },
  }),
  sectionRulesOption: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 24,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    '& div:nth-of-type(1)': {
      minHeight: '40px',
      minWidth: '40px',
      fontSize: 28,
      border: '5px solid #79A8DF',
      borderRadius: '50%',
      textAlign: 'center',
      alignContent: 'center',
      color: '#79A8DF',
      fontWeight: 800,
    },
    '& div:nth-of-type(2)': {
      ml: '30px',
    },
    [down(1350)]: {
      fontSize: '1.77vw',
    },
    [down('md')]: {
      fontSize: '2.18vw',
      '& div:nth-of-type(1)': {
        minHeight: '4vw',
        minWidth: '4vw',
        fontSize: '2.54vw',
        border: '4px solid #AB0F7E',
        color: '#AB0F7E',
      },
      '& div:nth-of-type(2)': {
        ml: '3vw',
      },
    },
    [down('sm')]: {
      fontSize: '3.73vw',
      alignItems: 'center',
      '& div:nth-of-type(1)': {
        minHeight: '7vw',
        minWidth: '7vw',
        fontSize: '4.66vw',
        border: '3px solid #AB0F7E',
      },
      '& div:nth-of-type(2)': {
        ml: '6vw',
      },
    },
  }),
  sectionRulesSeparator: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    height: '50px',
    width: '3px',
    ml: '23.5px',
    [down('md')]: {
      height: '30px',
      width: '3px',
      ml: '2.4vw',
    },
    [down('sm')]: {
      ml: '4vw',
    },
  }),
}
