import { FC, JSX } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { useReduxSelector } from '../../hooks'

type DateProps = {
  name: string
  label?: string
  required?: boolean
  disabled?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  variant?: 'standard' | 'filled' | 'outlined'
}

export const DateInput: FC<DateProps> = ({
  form,
  name,
  disabled = false,
  label = 'Дата регистрации',
  variant = 'outlined',
}): JSX.Element => {
  const { control } = form
  const { data: constants } = useReduxSelector((state) => state.constants)

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange, onBlur, ...rest }, formState }) => (
        <DesktopDatePicker
          label={label}
          value={value}
          onChange={onChange}
          disabled={disabled}
          maxDate={constants?.PROJECT_STOP_DOC_REGISTER}
          minDate={constants?.PROJECT_START_DOC_REGISTER}
          disableOpenPicker
          {...rest}
          slotProps={{
            textField: {
              onBlur,
              variant,
              fullWidth: true,
              color: 'primary',
              helperText: `${formState.errors[name]?.message || ''}`,
            },
          }}
        />
      )}
    />
  )
}
