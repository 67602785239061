/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, JSX, useEffect, useState } from 'react'
import { AppBar, Box, Button, Container, Grid, Link, Slide, SxProps, Theme, useScrollTrigger } from '@mui/material'
import { HashLink } from 'react-router-hash-link'
import { useNavigate } from 'react-router-dom'
import Scrollspy from 'react-scrollspy-navigation'
import { MyLink } from '../../my-link'
import { useReduxSelector } from '../../../hooks'
import { str } from '../../../utils'
import { ProgressBar } from '../../progress-bar'
import { StopAction } from '../../stop-action'
import { TrackEvent } from '../../GA'
import { PopoverAuth } from '../../popper-auth'
import { img } from '../../../imgs'
import { FiveIcon } from './icons'

type MenuItem = {
  key: string
  title: string
  hash: boolean
  target: '_self' | '_blank'
}

const { REACT_APP_5KA: URL_5KA } = process.env

const menu: MenuItem[] = [
  {
    hash: true,
    key: 'rules',
    target: '_self',
    title: 'Правила',
  },
  {
    hash: true,
    key: 'prizes',
    title: 'Призы',
    target: '_self',
  },
  {
    hash: true,
    key: 'products',
    target: '_self',
    title: 'Продукция',
  },
  {
    hash: true,
    key: 'winners',
    target: '_self',
    title: 'Победители',
  },
  {
    hash: true,
    key: 'faq',
    target: '_self',
    title: 'Вопрос-Ответ',
  },
]

export const DesktopMenu: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const trigger = useScrollTrigger({ target: window })

  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const [logoWidth, setLogoWidth] = useState(102)

  const onScroll = () => window.scrollTo(0, 0)

  const onAuth = () => {
    navigate('../?signin')
  }

  const onReg = () => {
    navigate('../?signup')
  }

  const onNavigate = () => {
    const element = document.getElementById('login')
    element?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  return (
    <>
      <AppBar position="fixed" sx={{ zIndex: 1200 }}>
        <ProgressBar height={2} />
      </AppBar>

      {/* <Slide appear={false} direction="down" in={!trigger}> */}
      <Slide appear={false} direction="down" in>
        <AppBar position="fixed" sx={menuStyles.appBar}>
          <Container maxWidth="lg">
            <Box sx={menuStyles.container}>
              <Box sx={menuStyles.logo}>
                <Link sx={menuStyles.linkLogo} rel="noopener noreferrer" href="/">
                  <img src={img.logo} alt="" />
                </Link>
              </Box>

              <Box>
                <Grid container sx={menuStyles.title_header}>
                  <Scrollspy activeClass="current_header_section" offsetTop={60}>
                    {menu.map((i, k) => {
                      const { key, title, hash, target } = i
                      return (
                        <Grid key={k + key} item xs="auto" marginRight="32px" height="100%">
                          {hash ? (
                            <Box sx={menuStyles.linkHash}>
                              {window.location.pathname === '/' ? (
                                <a href={`#${key}`}>{title}</a>
                              ) : (
                                <HashLink
                                  to={`/#${key}`}
                                  scroll={(el: HTMLElement) =>
                                    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
                                  }
                                >
                                  {title}
                                </HashLink>
                              )}
                            </Box>
                          ) : (
                            <>
                              {target === '_blank' ? (
                                <Link href={key} target={target} rel="noopener noreferrer" sx={menuStyles.link}>
                                  {title}
                                </Link>
                              ) : window.location.pathname === '/' ? (
                                <Box sx={menuStyles.linkHash}>
                                  <a
                                    href={
                                      key === 'main'
                                        ? window.location.pathname === '/'
                                          ? '#main'
                                          : '/#main'
                                        : `/${key}`
                                    }
                                  >
                                    {str.normalize(title)}
                                  </a>
                                </Box>
                              ) : (
                                <Link
                                  component={MyLink}
                                  onClick={() => {
                                    onScroll()
                                    if (key === 'game') {
                                      TrackEvent({
                                        eventLabel: 'game',
                                        eventCategory: 'Game',
                                        eventAction: 'menu_game_click',
                                      })
                                    }
                                  }}
                                  sx={menuStyles.link}
                                  to={key === 'main' ? '/' : !auth && key === 'game' ? '/?signin' : `/${key}`}
                                >
                                  {str.normalize(title)}
                                </Link>
                              )}
                            </>
                          )}
                        </Grid>
                      )
                    })}

                    {auth ? (
                      <Grid item height="100%" maxWidth="calc(100% - 574px)">
                        <PopoverAuth userData={userData} auth={auth} />
                      </Grid>
                    ) : (
                      <Grid item xs="auto">
                        <Link component={MyLink} onClick={onNavigate} to="#login" sx={menuStyles.sign}>
                          Войти
                        </Link>
                      </Grid>
                    )}
                  </Scrollspy>
                </Grid>
              </Box>
            </Box>
          </Container>
        </AppBar>
      </Slide>
    </>
  )
}

type MenuStylesProps =
  | 'appBar'
  | 'container'
  | 'logo'
  | 'link'
  | 'linkHash'
  | 'linkGame'
  | 'linkLogo'
  | 'sign'
  | 'title_header'

const menuStyles: Record<MenuStylesProps, SxProps<Theme> | undefined> = {
  appBar: () => ({
    mt: '2px',
  }),
  container: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 63,
    width: '100%',
    backgroundColor: palette.background.paper,
    display: 'flex',
    flexDirection: 'row',
    mt: '12px',
    borderRadius: 64,
    justifyContent: 'space-between',
  }),
  logo: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    '& img': {
      height: 63,
      userDrag: 'none',
      userSelect: 'none',
      transition: '0.2s',
    },
  }),
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    height: '100%',
    alignContent: 'center',
    cursor: 'pointer',
    transition: '0.2s',
    position: 'relative',
    fontSize: '14px!important',
    fontWeight: '700!important',
    textDecoration: 'none!important',
    color: `#051F4A!important`,
    boxSizing: 'border-box',
    alignItems: 'center',
    textTransform: 'uppercase',
    '-webkit-tap-highlight-color': 'transparent',
  }),
  linkHash: ({ breakpoints: { down }, palette }: Theme) => ({
    height: '100%',
    '& a': {
      display: 'flex',
      height: '100%',
      alignContent: 'center',
      cursor: 'pointer',
      position: 'relative',
      fontSize: '14px!important',
      fontWeight: '700!important',
      textDecoration: 'none!important',
      color: `#051F4A`,
      boxSizing: 'border-box',
      alignItems: 'center',
      textTransform: 'uppercase',
      '-webkit-tap-highlight-color': 'transparent',
    },
  }),
  linkGame: ({ palette }: Theme) => ({
    '&.active': { color: `#E51523!important` },
  }),
  linkLogo: () => ({
    display: 'block',
    cursor: 'pointer',
    textDecoration: 'none!important',
    '-webkit-tap-highlight-color': 'transparent',
  }),
  sign: ({ breakpoints: { down, up }, palette }: Theme) => ({
    display: 'block',
    height: '100%',
    alignContent: 'center',
    cursor: 'pointer',
    transition: '0.2s',
    position: 'relative',
    fontSize: '14px!important',
    fontWeight: '500!important',
    textDecoration: 'none!important',
    textTransform: 'uppercase',
    color: `#FFFFFF!important`,
    boxSizing: 'border-box',
    bgcolor: '#AB0F7E',
    p: '10px 30px',
    borderRadius: 19,
    '&:hover': {
      bgcolor: '#E72AB0',
    },
  }),
  title_header: () => ({
    display: 'flex',
    height: 63,
    alignItems: 'center',
    flexWrap: 'nowrap',
    p: '13px 15px',
  }),
}
