import { useEffect, useState } from 'react'
import { DetectDevice } from '../types'
import { useWindowDimension } from './use-win-dimension'

export function useDetectDevice() {
  const { width } = useWindowDimension()
  const detectIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
  const detectAndroid = /(android)/i.test(navigator.userAgent)

  const [detect, setDetect] = useState<DetectDevice>(DetectDevice.OTHER)

  useEffect(() => {
    if (detectIOS) setDetect(DetectDevice.IOS)
    else if (detectAndroid) setDetect(DetectDevice.ANDROID)
    else setDetect(DetectDevice.OTHER)
  }, [width])

  return detect
}
