/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, JSX, useState } from 'react'
import {
  Box,
  Grid,
  Hidden,
  IconButton,
  Popover,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Theme,
} from '@mui/material'
import HelpIcon from '@mui/icons-material/Help'
import Moment from 'react-moment'
import { IPrizeTableList, Prizes, Sticker } from '../../../../../types'
import { Loader, PaginationCustom, TrackEvent } from '../../../../../components'
import { useReduxSelector } from '../../../../../hooks'
import { aHref } from '../../../../../utils'
import { img } from '../../../../../imgs'

const countArray = (ar: any[]) => (ar ? ar.length : 0)

const SaveStatus: FC<{ items: Sticker[] }> = ({ items = [] }): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const onSave = (data: Sticker) => {
    aHref(`${data.link}`)
  }

  return (
    <>
      <Box
        aria-haspopup="true"
        onClick={handlePopoverOpen}
        sx={infoPrizestsStyles.link}
        aria-owns={open ? 'mouse-over-popover' : undefined}
      >
        Забрать
      </Box>
      <Popover
        open={open}
        anchorEl={anchorEl}
        disableRestoreFocus
        id="mouse-over-popover"
        onClose={handlePopoverClose}
        sx={infoPrizestsStyles.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box p={2}>
          {items.map((k: Sticker, i: number) => (
            <Grid container alignItems="center" spacing={1} mb={i === items.length - 1 ? 0 : '10px'}>
              <Grid key={`link-save-${i}`} item>
                <img src={img[`${k.channel}`]} alt="" />
              </Grid>
              <Grid item>
                <Box className="popover-link" onClick={() => onSave(k)}>
                  {k.channel === 'wa' ? 'Скачать архив' : 'Перейти в Телеграм'}
                </Box>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export const PrizesList: FC<IPrizeTableList> = ({ updateInfoPrize }): JSX.Element => {
  const {
    loading,
    data: { prizes },
  } = useReduxSelector((state) => state.profile)

  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(5)

  const handleChange = (event: any, value: number) => {
    setPage(value)
  }

  const onLoadStikerPack = (link: string) => {
    aHref(link)
    TrackEvent({
      eventCategory: 'Present',
      eventAction: 'click_get_btn',
    })
  }

  const onWa = () => {
    aHref('/backend/files/Instruction_sticker_WhatsApp.pdf')
  }

  return !loading ? (
    prizes.list && countArray(prizes.list) > 0 ? (
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <Hidden smDown>
                <TableCell>
                  <Box>Дата&nbsp;приза</Box>
                </TableCell>
                <TableCell>
                  <Box>Приз</Box>
                </TableCell>
                <TableCell>
                  <Box>Статус</Box>
                </TableCell>
                <TableCell />
              </Hidden>
            </TableRow>
          </TableHead>
          {prizes.list.length > 0 && (
            <TableBody>
              {prizes.list.slice(perPage * (page - 1), page * perPage).map((k: Prizes, i: number) => (
                <>
                  <Hidden smDown>
                    <TableRow key={i}>
                      <TableCell>
                        <Moment format="DD.MM.YYYY">{k.create_datetime}</Moment>
                      </TableCell>
                      <TableCell>
                        <Box>{k.name}</Box>
                      </TableCell>
                      <TableCell>
                        <Box>{k.status.webname}</Box>
                      </TableCell>
                      <TableCell>
                        {k.sticker === null && !k?.certcode && '-'}
                        {k.sticker && <SaveStatus items={k.sticker} />}
                        {k.certcode?.code && (
                          <Box onClick={() => onLoadStikerPack(`${k.certcode?.link}`)} sx={infoPrizestsStyles.link}>
                            Cкачать
                          </Box>
                        )}
                      </TableCell>
                    </TableRow>
                  </Hidden>

                  <Hidden smUp>
                    <TableRow key={i}>
                      <TableCell>
                        <Box textAlign="left">
                          <Box mb="5px">
                            Дата&nbsp;заявки:&nbsp;<Moment format="DD.MM.YYYY">{k.create_datetime}</Moment>
                          </Box>
                          <Box mb="5px">Приз:&nbsp;{k.name}</Box>
                          <Box mb="5px">Статус:&nbsp;{k.status.webname}</Box>
                          <Box>
                            {k.sticker === null && !k?.certcode && '-'}
                            {k.sticker && <SaveStatus items={k.sticker} />}
                            {k.certcode?.code && (
                              <Box onClick={() => onLoadStikerPack(`${k.certcode?.link}`)} sx={infoPrizestsStyles.link}>
                                Cкачать
                              </Box>
                            )}
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </Hidden>
                </>
              ))}
            </TableBody>
          )}
        </Table>

        {prizes.list && countArray(prizes.list) > perPage && (
          <Box pt={3} width="100%" display="flex" justifyContent="center">
            <PaginationCustom
              page={page}
              onChange={handleChange}
              count={Math.ceil(countArray(prizes.list) / perPage)}
            />
          </Box>
        )}
      </Box>
    ) : (
      <Box sx={infoPrizestsStyles.text} my={1} textAlign="center">
        Здесь будет информация
        <br />о ваших призах
      </Box>
    )
  ) : (
    <Grid container justifyContent="center" alignItems="center" height={300}>
      <Grid item>
        <Loader />
      </Grid>
    </Grid>
  )
}

type InfoPrizesStylesProps = 'text' | 'popover' | 'selectColor' | 'link'

const infoPrizestsStyles: Record<InfoPrizesStylesProps, SxProps<Theme> | undefined> = {
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    fontWeight: 400,
    padding: '50px 25px',
    color: palette.primary.dark,
    [down('sm')]: { fontSize: 17 },
  }),
  popover: ({ breakpoints: { down }, palette }: Theme) => ({
    '& .MuiPopover-paper': {
      maxWidth: 300,
      fontWeight: 400,
      lineHeight: 1.3,
      backgroundColor: '#343F2B!important',
      '& .popover-link': {
        fontSize: 17,
        cursor: 'pointer',
        transition: '0.3s',
        textDecoration: 'underline',
        color: palette.primary.main,
        textDecorationColor: 'rgba(255, 255, 255, 0.6)!important',
        '&:hover': { textDecorationColor: `${palette.primary.main}!important` },
      },
    },
  }),
  selectColor: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: '90%',
    cursor: 'pointer',
    color: palette.primary.dark,
    textDecoration: 'underline',
    '-webkit-tap-highlight-color': 'transparent',
  }),
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    cursor: 'pointer',
    transition: '0.3s',
    textDecoration: 'underline',
    color: palette.secondary.main,
    textDecorationColor: 'rgba(47, 194, 33, 0.6)!important',
    '&:hover': { textDecorationColor: `${palette.secondary.main}!important` },
  }),
}
