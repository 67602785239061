import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getInfoConstants } from './constants.service'
import { IConstState } from './constants.types'
import { Constants } from '../../types'

const initialState: IConstState = {
  data: undefined,
  loading: false,
  init: false,
  error: undefined,
}

export const constSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    setIframe(state: IConstState, action: PayloadAction<{ isIframe: boolean }>) {
      return { ...state, data: { ...state.data, IS_IFRAME: action.payload.isIframe } }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoConstants.pending, (state: IConstState) => {
      state.loading = true
    })
    builder.addCase(getInfoConstants.fulfilled, (state: IConstState, action: PayloadAction<{ data: Constants }>) => {
      state.data = action.payload.data
      state.init = true
      state.loading = false
    })
    builder.addCase(getInfoConstants.rejected, (state: IConstState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.init = false
      state.loading = false
    })
  },
})

export const constActions = constSlice.actions
export const constReducer = constSlice.reducer
