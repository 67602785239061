import '../../styles/filepond.scss'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'

import { FC, JSX, useEffect, useRef, useState } from 'react'
import { Box, Button, Collapse, Grid, SxProps, Theme } from '@mui/material'
import { FilePond, registerPlugin } from 'react-filepond'
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'
import jsQR from 'jsqr'
import filePondLocale from './file-pond-locale'
import { RegisterCheckForm } from './register-check-form'
import { TrackEvent } from '../GA'
import { Modal } from '../modal'
import { Loader } from '../loader'
import { ApiAnswerStatus, Token } from '../../types'
import { reFetchPrivateAPIToken } from '../../api'
import { useReduxActions } from '../../hooks'
import { RequireCheck } from '../require-check'

const ls = require('local-storage')

interface WidgetRegisterCheckProps {
  show: boolean
  onOpen: () => void
  onClose: () => void
  onSuccess?: () => void
  loadInfoLk?: () => void
}

function validateExternalQr(props: any) {
  const { fn, fp, i, s, t } = props || {}
  return !!fn && !!fp && !!i && !!s && !!t
}

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
)

const filePondProps = {
  maxFiles: 1,
  maxFileSize: '15MB',
  allowReorder: false,
  allowMultiple: false,
  allowImagePreview: true,
  imagePreviewMaxFileSize: '3MB',
  labelFileTypeNotAllowed: 'Файл неверного типа.',
  labelMaxFileSizeExceeded: 'Файл слишком большой.',
  labelMaxFileSize: 'Максимальный размер файла {filesize}.',
  acceptedFileTypes: ['image/jpeg', 'image/jpg', 'image/png'],
  fileValidateTypeLabelExpectedTypes: 'Разрешенные типы файлов: {allTypes}.',
}

const localeLabel = filePondLocale

export const WidgetRegisterCheck: FC<WidgetRegisterCheckProps> = ({
  show = false,
  onOpen = () => {},
  onClose = () => {},
  onSuccess = () => {},
  loadInfoLk = () => {},
}): JSX.Element => {
  const frame = useRef<number>()
  const stream = useRef<MediaStream>()
  const video = useRef<HTMLVideoElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)
  const canvasCtx = useRef<CanvasRenderingContext2D | null>(null)

  const token = ls.get(Token.PRIVATE)

  const { setAlert } = useReduxActions()

  const [message, setMessage] = useState<string | undefined>(undefined)
  const [onlineMessage, setOnlineMessage] = useState<string | undefined>(undefined)

  const [collapse, setCollapse] = useState<boolean>(true)

  const [pond, setPond] = useState<any>(undefined)
  const [reset, setReset] = useState<boolean>(false)

  const [scan, setScan] = useState<boolean>(false)
  const [played, setPlayed] = useState<boolean>(false)

  const [uploadAnswer, setUploadAnswer] = useState<any>()
  const [validExternalQr, setValidExternalQr] = useState<any>()

  const [type, setType] = useState<string | undefined>(undefined)

  const [preText, setPreText] = useState<boolean>(true)
  const [showFileLoad, setShowFileLoad] = useState<boolean>(false)
  const [showOnlineLoader, setShowOnlineLoader] = useState<boolean>(false)
  const [showFormQr, setShowFormQr] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)

  const handleShow = () => {
    TrackEvent({
      eventCategory: 'Check',
      eventAction: 'click_button',
      eventLabel: 'profile',
    })
    setCollapse(true)
    onOpen()
    setMessage(undefined)
    setType('FILE')
  }

  const handleClose = () => {
    setCollapse(false)
    onClose()
    setTimeout(() => {
      setReset(true)
      setType(undefined)
      disableStream()
    }, 150)
  }

  const onSuccessHandle = () => {
    setType(undefined)
    onSuccess()
    loadInfoLk()
    handleClose()
  }

  const onInit = () => {}

  const handleUploadSuccess = (data: string) => {
    const res = JSON.parse(data)

    if (res.status === 1) {
      setUploadAnswer(res.data)
      setType('FILE_UPLOAD')

      TrackEvent({
        eventCategory: 'Check',
        eventAction: 'download_check',
        eventContext: 'success',
        eventError: 'Загрузка чека прошла успешно!',
      })
    } else {
      setType('FILE_UPLOAD_ERROR')

      setAlert({ alert: { message: res.message, onClose: () => {} } })
    }

    return data
  }

  const handlerErrorUpload = (error: any) => {
    setType('FILE_UPLOAD_ERROR')
    handleClose()
    if (error?.code && error?.code === ApiAnswerStatus.UNAUTHENTICATED) {
      reFetchPrivateAPIToken(token.refresh_token || '').then((res) => {
        ls.remove(Token.PRIVATE)
        ls.set(Token.PRIVATE, res)

        setAlert({
          alert: {
            message: 'Ошибка загрузки фото.<br />Попробуйте ещё раз.',
            onClose: () => {
              handleShow()
            },
          },
        })
      })
    } else if (error.main && error.sub) {
      setAlert({ alert: { message: `${error.main} ${error.sub}`, onClose: () => handleShow() } })
    } else
      setAlert({ alert: { message: `Ошибка загрузки фото.<br />Попробуйте ещё раз.`, onClose: () => handleShow() } })
    TrackEvent({
      eventCategory: 'Check',
      eventAction: 'download_check',
      eventContext: 'unsuccess',
      eventError: `${error.main}` || 'Ошибка загрузки чека!',
    })
  }

  const handleScan = (data: string) => {
    if (data) {
      let qr_data: any = {}
      data.split('&').map((i) => {
        const [key, val] = i.split('=')
        qr_data[key] = val
        return i
      })

      if (validateExternalQr(qr_data)) {
        disableStream()
        setUploadAnswer({ qr: qr_data })
        setType('QR_ONLINE_FIND')
      } else {
        qr_data = undefined
        setOnlineMessage('Некорректный QR-код')
        setTimeout(() => {
          setOnlineMessage(undefined)
        }, 3000)
      }
    }
  }

  const tick = () => {
    const { current: videoC } = video
    const { current: canvasC } = canvas
    const { current: ctx } = canvasCtx

    if (videoC && canvasC && ctx && videoC.readyState === videoC.HAVE_ENOUGH_DATA) {
      ctx.save()
      ctx.clearRect(0, 0, videoC.videoWidth, videoC.videoHeight)
      ctx.drawImage(videoC, 0, 0, videoC.videoWidth, videoC.videoHeight)
      ctx.restore()

      const imageData = ctx.getImageData(0, 0, videoC.videoWidth, videoC.videoHeight)
      const code = jsQR(imageData.data, imageData.width, imageData.height)

      if (code) handleScan(code.data)
    }
  }

  const disableStream = () => {
    if (frame.current) cancelAnimationFrame(frame.current)

    stream.current?.getTracks().forEach((track) => track.stop())

    if (played) {
      video.current?.pause()
      setPlayed(false)
    }
  }

  const enableStream = async () => {
    navigator.mediaDevices
      .getUserMedia({ audio: false, video: { facingMode: 'environment' } })
      .then((res) => {
        stream.current = res

        const { current: videoCurrent } = video
        const { current: canvasCurrent } = canvas

        if (videoCurrent && canvasCurrent) {
          canvasCtx.current = canvasCurrent.getContext('2d')
          videoCurrent.srcObject = res
          videoCurrent.setAttribute('playsinline', 'true')
          if (!played) {
            videoCurrent.play()
            setPlayed(true)
          }

          setInterval(() => {
            frame.current = requestAnimationFrame(tick)
          }, 700)
        }
      })
      .catch(() => {
        setMessage(undefined)
        setType('FILE')
      })
  }

  useEffect(() => {
    if (reset) {
      if (pond) pond.removeFiles()
      setUploadAnswer(undefined)
      setValidExternalQr(undefined)
    }
    return () => setReset(false)
  }, [reset])

  useEffect(() => {
    setShowOnlineLoader(false)
    setShowLoader(false)
    setShowFormQr(false)

    switch (type) {
      case 'ONLINE':
        setReset(true)
        setShowFileLoad(false)
        enableStream().then(() => {})
        setTimeout(() => setShowOnlineLoader(true), 100)
        break

      case 'FILE':
        setReset(true)
        setPreText(true)
        setShowFileLoad(true)
        setTimeout(() => disableStream(), 150)
        break

      case 'FILE_UPLOAD':
        TrackEvent({ eventCategory: 'Check', eventAction: 'download_check', eventLabel: 'success' })
        if (uploadAnswer) {
          const resUploadFile = validateExternalQr(uploadAnswer.qr)
          setValidExternalQr(resUploadFile)
          if (resUploadFile) {
            setScan(false)
            setType('QR_FIND')
          } else {
            setType('FORM_QR')
          }
        }
        break

      case 'FILE_UPLOAD_ERROR':
        TrackEvent({ eventCategory: 'Check', eventAction: 'download_check', eventLabel: 'unsuccess' })
        break

      case 'FORM_QR':
        setShowFileLoad(true)
        setShowFormQr(true)
        break

      case 'QR_FIND':
        setShowFileLoad(false)
        setShowLoader(true)
        setShowFormQr(true)
        break

      case 'QR_ONLINE_FIND':
        if (uploadAnswer) {
          const resQrOnline = validateExternalQr(uploadAnswer.qr)
          setValidExternalQr(resQrOnline)
          if (resQrOnline) {
            setScan(true)
            setType('QR_FIND')
          } else {
            setType('QR_NOT_FIND')
          }
        }
        break

      default:
        break
    }
  }, [type])

  return (
    <>
      <Button fullWidth variant="contained" color="secondary" sx={regCheckWidgetStyles.btn} onClick={handleShow}>
        Зарегистрировать чек
      </Button>

      <Modal
        title="РЕГИСТРАЦИЯ ЧЕКА"
        open={show}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { maxWidth: '600px!important' } }}
      >
        <Collapse in={collapse}>
          {message && (
            <Grid item xs={12}>
              <Box width="100%" display="flex" justifyContent="center" pt={3} pb={6}>
                <Loader />
              </Box>
              <Box sx={regCheckWidgetStyles.text} mb={5} dangerouslySetInnerHTML={{ __html: message }} />
            </Grid>
          )}

          {!message && (
            <>
              <Grid container justifyContent="center" alignItems="center">
                {!message && showLoader && (
                  <Grid item xs={12}>
                    <Box width="100%" display="flex" justifyContent="center" py={10}>
                      <Loader />
                    </Box>
                  </Grid>
                )}

                <Grid item xs={12}>
                  {showFileLoad && (
                    <Box sx={regCheckWidgetStyles.filepond}>
                      <FilePond
                        name="check"
                        oninit={onInit}
                        ref={(ref) => setPond(ref)}
                        onaddfilestart={() => setPreText(false)}
                        server={{
                          process: {
                            url: `${process.env.REACT_APP_API_URL}private/upload/file`,
                            headers: { Authorization: `Bearer ${token.access_token}` },
                            onload: handleUploadSuccess,
                          },
                          revert: (uniqueFileId, load) => load(),
                        }}
                        onerror={handlerErrorUpload}
                        onremovefile={() => {
                          setType('FILE')
                          setPreText(true)
                        }}
                        {...filePondProps}
                        {...localeLabel}
                      />

                      {/* {type === 'FILE' && preText && ( */}
                      {/*  <Box sx={regCheckWidgetStyles.preText}> */}
                      {/* eslint-disable-next-line max-len */}
                      {/*    На фото должен быть четко виден <span style={{ whiteSpace: 'nowrap' }}>QR-код</span> и данные */}
                      {/*    чека */}
                      {/*  </Box> */}
                      {/* )} */}

                      <Collapse
                        timeout={{ enter: 400, exit: 0 }}
                        in={uploadAnswer && (type === 'FILE' || type === 'FORM_QR')}
                      >
                        <>
                          {!validExternalQr && (
                            <>
                              <Box sx={regCheckWidgetStyles.text} mt={{ xs: 1, sm: 2 }} mb={{ xs: 2, sm: 3 }}>
                                К сожалению, информация на чеке не была распознана.
                                <br />
                                <span
                                  aria-hidden="true"
                                  style={{ textDecoration: 'underline', cursor: 'pointer', color: '#AB0F7E' }}
                                  onClick={() => setType('FILE')}
                                >
                                  Загрузите
                                </span>
                                , пожалуйста, другую фотографию чека или введите данные чека вручную.
                              </Box>
                              <RegisterCheckForm
                                scan={scan}
                                autoSave={validExternalQr}
                                uploadAnswer={uploadAnswer}
                                onSuccess={onSuccessHandle}
                              />
                            </>
                          )}
                        </>
                      </Collapse>
                    </Box>
                  )}
                  {showFormQr && validExternalQr && (
                    <RegisterCheckForm
                      scan={scan}
                      autoSave={validExternalQr}
                      uploadAnswer={uploadAnswer}
                      onSuccess={onSuccessHandle}
                    />
                  )}
                </Grid>
              </Grid>
              <Collapse in={showOnlineLoader} timeout={{ enter: 200, exit: 0 }}>
                <>
                  <Box>
                    <Box sx={regCheckWidgetStyles.text} mb={3}>
                      Наведите камеру на QR-код
                    </Box>
                    <Box sx={regCheckWidgetStyles.qrReader}>
                      <video width="100%" ref={video} />
                      <canvas width="640px" height="480px" ref={canvas} />
                      {onlineMessage !== undefined && <Box sx={regCheckWidgetStyles.qrMessage}>{onlineMessage}</Box>}
                    </Box>
                    <Box sx={regCheckWidgetStyles.text} my={3}>
                      QR-код не считывается?
                    </Box>
                  </Box>
                  <Box sx={{ maxWidth: 330, mx: 'auto', my: 2 }}>
                    <Button fullWidth color="secondary" variant="contained" onClick={() => setType('FILE')}>
                      Загрузить фото чека
                    </Button>
                  </Box>
                </>
              </Collapse>
            </>
          )}
        </Collapse>
      </Modal>
    </>
  )
}

type RegCheckWidgetStylesProps = 'btn' | 'text' | 'qrReader' | 'qrMessage' | 'requireCheck' | 'preText' | 'filepond'

const regCheckWidgetStyles: Record<RegCheckWidgetStylesProps, SxProps<Theme> | undefined> = {
  btn: ({ breakpoints: { down }, palette }: Theme) => ({
    minHeight: 50,
    [down('sm')]: {
      fontSize: 15,
      minHeight: 48,
      minWidth: 250,
    },
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    width: '100%',
    fontWeight: 500,
    textAlign: 'center',
    '& span': { whiteSpace: 'nowrap', color: palette.primary.dark },
    [down('sm')]: { fontSize: 17 },
  }),
  qrReader: ({ breakpoints: { down } }: Theme) => ({
    maxWidth: '100%',
    height: 'auto',
    margin: '0 60px',
    position: 'relative',
    // padding: '5px',
    borderRadius: '4px',
    border: '2px solid #ffffff',
    '& video': { display: 'block', maxHeight: 480, borderRadius: '4px' },
    '& canvas': { width: '100%', display: 'none', borderRadius: '4px' },
    [down('sm')]: { margin: '0 20px' },
  }),
  qrMessage: () => ({
    top: '50%',
    p: '10px',
    left: '50%',
    textAlign: 'center',
    position: 'absolute',
    transform: 'translate(-50%,-50%)',
    background: 'rgba(18, 40, 88, .7)',
    textShadow: '0px 0px 2px 2px rgba(0, 0, 0, .6)',
  }),
  requireCheck: () => ({ width: '100%', textAlign: 'center', margin: '10px 0 15px' }),
  preText: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 17,
    maxWidth: 320,
    fontWeight: 400,
    padding: '0 20px',
    transition: '0.3s',
    borderRadius: '4px',
    textAlign: 'center',
    margin: '20px auto 0',
    [down('sm')]: { fontSize: 14, margin: '15px auto 0' },
  }),
  filepond: ({ breakpoints: { down } }: Theme) => ({
    pt: 1,
    m: '0 auto',
    maxWidth: 530,
    [down('sm')]: { pt: 0 },
  }),
}
