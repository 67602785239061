import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ModalAction, AlertAction } from './modal.types'

const initialState: ModalAction & AlertAction = {
  modal: {},
  alert: {},
}

export const modalSlice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    setModal(state: ModalAction & AlertAction, action: PayloadAction<ModalAction>) {
      return { ...state, modal: { ...action.payload.modal } }
    },
    setAlert(state: ModalAction & AlertAction, action: PayloadAction<AlertAction>) {
      return { ...state, alert: { ...action.payload.alert } }
    },
  },
  extraReducers: () => {},
})

export const modalActions = modalSlice.actions
export const modalReducer = modalSlice.reducer
