/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, JSX, ReactNode, useEffect, useState } from 'react'
import {
  Box,
  Grid,
  Link,
  Theme,
  Slide,
  AppBar,
  Drawer,
  Button,
  SxProps,
  Container,
  IconButton,
  useMediaQuery,
  useScrollTrigger,
} from '@mui/material'
import MenuRoundedIcon from '@mui/icons-material/MenuRounded'
import { useLocation, useNavigate } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import queryString from 'query-string'
import { usePrevious } from 'react-use'
import { img } from '../../../imgs'
import { useDetectDevice, useReduxActions, useReduxSelector } from '../../../hooks'
import { aHref, str } from '../../../utils'
import { CloseIcon, BurgerIcon } from './icons'
import { MyLink } from '../../my-link'
import { TrackEvent } from '../../GA'

type MenuItem = {
  key: string
  path?: string
  title: string
  isHash: boolean
  icon?: ReactNode
  target: '_self' | '_blank'
}

const { REACT_APP_RULES_URL: RULES, REACT_APP_5KA: URL_5KA } = process.env

const menuList: MenuItem[] = [
  {
    isHash: true,
    key: 'rules',
    target: '_self',
    title: 'Правила',
  },
  {
    isHash: true,
    key: 'prizes',
    title: 'Призы',
    target: '_self',
  },
  {
    isHash: true,
    key: 'products',
    target: '_self',
    title: 'Продукция',
  },
  {
    isHash: true,
    key: 'winners',
    target: '_self',
    title: 'Победители',
  },
  {
    isHash: true,
    key: 'faq',
    target: '_self',
    title: 'Вопрос-Ответ',
  },
]

export const MobileMenu: FC = (): JSX.Element => {
  const { parse } = queryString
  const navigate = useNavigate()
  const device = useDetectDevice()
  const { setIframe } = useReduxActions()
  const { pathname, search, hash } = useLocation()
  const trigger = useScrollTrigger({ target: window })

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down(600))
  const xs = useMediaQuery(({ breakpoints: { down } }: Theme) => down(350))

  const { data: constants } = useReduxSelector((state) => state.constants)
  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const [open, setOpen] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const onNavigate = (item: MenuItem) => {
    if (item.key === 'main') {
      navigate('../')
    } else if (item.key === 'burger') {
      handleDrawerOpen()
    } else if (item.key === 'factory') {
      if (auth) navigate('../factory')
      else navigate('../?signin')
    } else if (item.key === 'five') {
      aHref(`${item.path}`)
    } else {
      navigate(`../${item.key}`)
    }
    window.scrollTo(0, 0)
  }

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClose = () => setAnchorEl(null)

  const handleClickExit = () => {
    handleClose()
    navigate('../logout')
  }

  useEffect(() => {
    const query = parse(search)

    if (!constants?.IS_IFRAME) {
      switch (true) {
        case 'iframe' in query:
          setIframe({ isIframe: true })
          break
        default:
          setIframe({ isIframe: false })
          break
      }
    }
  }, [])

  return (
    <>
      <Slide appear={false} direction="down" in>
        {/* <Slide appear={false} direction="down" in={!trigger}> */}
        <AppBar position="fixed" sx={menuStyles.appBarTop}>
          <Grid container spacing={0} height="100%" direction="row" alignItems="center" justifyContent="space-between">
            <Grid item height="100%">
              <Link href="/" sx={menuStyles.linkLogo}>
                <img src={img.logo} alt="" />
              </Link>
            </Grid>
            <Grid item sx={menuStyles.menu_holder} onClick={handleDrawerOpen}>
              <IconButton style={{ color: '#051F4A' }}>
                <MenuRoundedIcon sx={{ height: 40, width: 40 }} />
              </IconButton>
            </Grid>
          </Grid>
        </AppBar>
      </Slide>

      <Drawer sx={menuStyles.drawer} anchor="left" open={open} onClose={handleDrawerClose}>
        <Grid mt={4} container direction="column" justifyContent="flex-start" height="80vh">
          <Grid item>
            <Container sx={menuStyles.container}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                height="100%"
              >
                <Grid item height={50}>
                  <Link href="https://galamart.ru/" sx={menuStyles.linkLogo}>
                    <img src={img.logo} alt="" />
                  </Link>
                </Grid>
                <Grid item>
                  <Box width={{ xs: 50, sm: 60 }} height={{ xs: 50, sm: 60 }}>
                    <IconButton color="primary" onClick={handleDrawerClose}>
                      <CloseIcon width={sm ? 34 : 44} height={sm ? 34 : 44} />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Container>

            <Grid container direction="column" justifyContent="space-between" alignItems="center" spacing={1} mt={3}>
              {menuList.map((i, k) => {
                const { key, title, isHash, target } = i
                return (
                  <Grid key={k + key} item xs="auto">
                    {isHash ? (
                      <Box sx={menuStyles.linkHash}>
                        <HashLink
                          to={`/#${key}`}
                          onClick={handleDrawerClose}
                          scroll={(el: HTMLElement) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                        >
                          {title}
                        </HashLink>
                      </Box>
                    ) : (
                      <>
                        {target === '_blank' ? (
                          <Link href={key} target={target} rel="noopener noreferrer" sx={menuStyles.link}>
                            <Grid container wrap="nowrap" alignItems="flex-start" spacing={1}>
                              <Grid item>{title}</Grid>
                            </Grid>
                          </Link>
                        ) : (
                          <Link
                            component={MyLink}
                            onClick={() => {
                              // onScroll()
                              if (key === 'game') {
                                TrackEvent({
                                  eventLabel: 'game',
                                  eventCategory: 'Game',
                                  eventAction: 'menu_game_click',
                                })
                              }
                            }}
                            sx={menuStyles.link}
                            to={key === 'main' ? '/' : !auth && key === 'game' ? '/?signin' : `/${key}`}
                          >
                            {str.normalize(title)}
                          </Link>
                        )}
                      </>
                    )}
                  </Grid>
                )
              })}

              {auth ? (
                <Grid item xs="auto">
                  <Link component={MyLink} sx={menuStyles.link} to="/profile">
                    Личный кабинет
                  </Link>
                </Grid>
              ) : (
                <Grid item>
                  <Link component={MyLink} onClick={handleDrawerClose} sx={menuStyles.sign} to="?signin">
                    Войти
                  </Link>
                </Grid>
              )}
            </Grid>
          </Grid>
          {auth ? (
            <Grid item px={4} mt={2}>
              <Box sx={menuStyles.infoUser}>
                <Grid container spacing={1} sx={menuStyles.holder}>
                  <Grid item sx={menuStyles.text} style={{ color: '#00051F80' }}>
                    Имя:
                  </Grid>
                  <Grid item sx={menuStyles.text} style={{ color: '#00051F80' }}>
                    Телефон:
                  </Grid>
                  <Grid item sx={menuStyles.text} style={{ color: '#00051F80' }}>
                    E-mail:
                  </Grid>
                </Grid>
                <Grid container spacing={1} ml={2} sx={menuStyles.holder}>
                  <Grid
                    item
                    sx={menuStyles.text}
                    maxWidth={{ xs: 220, sm: 320 }}
                    style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {userData?.firstname}
                  </Grid>
                  <Grid item sx={menuStyles.text}>
                    {userData?.phone_format}
                  </Grid>
                  <Grid item sx={menuStyles.text}>
                    {userData?.email}
                  </Grid>
                </Grid>
              </Box>
              <Box sx={menuStyles.btn_holder}>
                <Box onClick={handleClickExit} sx={menuStyles.linkLogout}>
                  Выйти из аккаунта
                </Box>
              </Box>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Drawer>
    </>
  )
}

type MenuStylesProps =
  | 'appBarTop'
  | 'drawer'
  | 'drawerContainer'
  | 'logoDrawer'
  | 'appBarBottom'
  | 'container'
  | 'containerBottom'
  | 'title'
  | 'iconButton'
  | 'link'
  | 'linkHash'
  | 'linkGame'
  | 'linkLogo'
  | 'sign'
  | 'user'
  | 'noActiveLink'
  | 'btnReg'
  | 'btnAuth'
  | 'infoUser'
  | 'menu_holder'
  | 'holder'
  | 'text'
  | 'btn_holder'
  | 'linkLogout'

const menuStyles: Record<MenuStylesProps, SxProps<Theme> | undefined> = {
  appBarTop: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 50,
    width: 'calc(100% - 40px)',
    borderRadius: '40px',
    m: '10px 20px 0 20px',
    boxSizing: 'border-box',
    backgroundColor: '#FFFFFF',
    display: 'flex',
    [down('sm')]: { height: 50 },
  }),
  container: ({ breakpoints: { down } }: Theme) => ({
    width: '100%',
    [down('sm')]: { width: '100%' },
  }),
  drawer: ({ breakpoints: { down }, palette }: Theme) => ({
    width: '100%',
    maxWidth: 500,
    '& .MuiPaper-root': {
      width: '100%',
      maxWidth: 500,
      paddingBottom: 50,
      backgroundImage: 'none',
      backgroundColor: `${palette.background.paper}!important`,
      backgroundSize: '350px auto',
      [down('sm')]: { maxWidth: 375, backgroundSize: '300px auto' },
    },
    '& .MuiBackdrop-root': { backgroundColor: 'rgba(0, 0, 0, .7)' },
    [down('sm')]: { maxWidth: 375 },
  }),
  drawerContainer: () => ({
    maxWidth: '95%',
  }),
  logoDrawer: ({ breakpoints: { down } }: Theme) => ({ width: 165, [down('sm')]: { width: 140 } }),
  appBarBottom: ({ breakpoints: { down }, palette }: Theme) => ({
    bottom: 0,
    height: 80,
    top: 'auto',
    width: '100%',
    backgroundColor: `${palette.success.main}!important`,
    [down('sm')]: { height: 70 },
  }),
  containerBottom: ({ breakpoints: { down } }: Theme) => ({
    width: '90%',
    [down('sm')]: {
      width: '100%',
      padding: '0 5px!important',
    },
  }),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 12,
    fontWeight: 600,
    marginTop: '-3px',
    color: palette.primary.main,
    [down('sm')]: {
      fontSize: 10,
    },
  }),
  menu_holder: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 23px',
    borderRadius: '8px',
    cursor: 'pointer',
    '& button': {
      padding: 0,
    },
  }),
  iconButton: () => ({
    padding: 0,
    margin: 0,
    '&:hover': { background: 'none' },
  }),
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'block',
    cursor: 'pointer',
    transition: '0.3s',
    position: 'relative',
    marginTop: '3px!important',
    fontSize: '28px!important',
    fontWeight: '700!important',
    textDecoration: 'none!important',
    color: palette.primary.dark,
    '-webkit-tap-highlight-color': 'transparent',
    [down('sm')]: { fontSize: '20px!important' },
  }),
  linkHash: ({ breakpoints: { down }, palette }: Theme) => ({
    '& a': {
      display: 'block',
      cursor: 'pointer',
      transition: '0.3s',
      position: 'relative',
      marginTop: '3px!important',
      fontSize: '28px!important',
      fontWeight: '700!important',
      textDecoration: 'none!important',
      color: `${palette.primary.dark}!important`,
      '-webkit-tap-highlight-color': 'transparent',
      '&.active': {
        color: '#FCB12B!important',
      },
      [down('sm')]: { fontSize: '20px!important' },
    },
  }),
  linkGame: ({ palette }: Theme) => ({
    '&.active': { color: `${palette.primary.main}!important` },
  }),
  linkLogo: ({ breakpoints: { down } }: Theme) => ({
    display: 'block',
    height: '100%',
    cursor: 'pointer',
    textDecoration: 'none!important',
    '-webkit-tap-highlight-color': 'transparent',
    '& img': {
      height: '100%',
    },
  }),
  sign: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'block',
    cursor: 'pointer',
    transition: '0.3s',
    position: 'relative',
    marginTop: '3px!important',
    fontSize: '28px!important',
    fontWeight: '700!important',
    textDecoration: 'none!important',
    color: `${palette.primary.dark}!important`,
    '-webkit-tap-highlight-color': 'transparent',
    [down('sm')]: { fontSize: '20px!important' },
  }),
  user: ({ breakpoints: { down } }: Theme) => ({
    width: 32,
    height: 32,
    [down('sm')]: {
      width: 29,
      height: 29,
    },
  }),
  noActiveLink: () => ({ opacity: 0.7 }),
  btnReg: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 18,
    minHeight: 56,
    minWidth: 300,
    color: palette.success.main,
    '&:hover': {
      color: palette.success.main,
    },
    [down('sm')]: {
      fontSize: 16,
      minHeight: 48,
      minWidth: 264,
    },
  }),
  btnAuth: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 18,
    minHeight: 56,
    minWidth: 300,
    color: palette.primary.main,
    backgroundColor: palette.success.main,
    border: `1px solid ${palette.primary.main}`,
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.success.main,
    },
    [down('sm')]: {
      fontSize: 16,
      minHeight: 48,
      minWidth: 264,
    },
  }),
  infoUser: () => ({
    display: 'flex',
    minWidth: '100%',
    boxSizing: 'border-box',
    flexDirection: 'row',
    textAlign: 'left',
    padding: '5px 0px',
    maxWidth: '350px!important',
    borderTop: '1px solid #CFCFCF',
  }),
  holder: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    color: palette.primary.dark,
    [down('sm')]: { fontSize: 16 },
  }),
  btn_holder: ({ breakpoints: { down }, palette }: Theme) => ({
    borderTop: '1px solid #CFCFCF',
  }),
  linkLogout: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 22,
    minWidth: 141,
    minHeight: 38,
    fontWeight: 700,
    color: palette.secondary.contrastText,
    padding: '10px 0 0 5px',
    transition: '0.1s',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      color: '#E72AB0',
    },
    [down('sm')]: { fontSize: 16 },
  }),
}
