import { FC, JSX, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Grid, SxProps, Theme, Collapse, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { Check as CheckIcon } from '@mui/icons-material'
import {
  CheckboxInput,
  EmailInput,
  formLocale,
  PhoneInput,
  Snackbar,
  SubmitButton,
  TextInput,
  TrackEvent,
  validation,
  Modal,
} from '../../components'
import { FormProps, RegisterUser } from '../../types'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../../hooks'
import { signup, userUpdate } from '../../api'
import { getInfoUser } from '../../store/auth/auth.service'

interface ISignupForm {
  email: string | null
  token: string | null
}

const schema = validation({
  first_name: yup
    .string()
    .required()
    .min(2)
    .max(30)
    .matches(/^[а-яёА-ЯЁa\s-]*$/i, 'Только буквенные символы кириллицы'),
  phone: yup
    .string()
    .required()
    .transform((value) => value.replace(/\s|\(|\)|\-|\+7/g, ''))
    .matches(/^(9)\d{9}$/, 'Указан некорректный номер телефона'),
  email: yup
    .string()
    .required()
    .max(50)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Указан некорректный e-mail'),
  rules: yup.boolean().oneOf([true], 'Необходимо согласиться с условиями').default(false),
})

export const SignupForm: FC<ISignupForm> = ({ email, token }): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()
  const navigate = useNavigate()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const [phoneConfirm, setPhoneConfirm] = useState(false)
  const [collapse, setCollapse] = useState<boolean>(true)
  const [show, setshow] = useState<boolean>(false)
  const [userData, setUserData] = useState<RegisterUser | null>(null)

  const {
    auth: { auth },
    constants: { data: constants },
  } = useReduxSelector((state) => state)

  const { serverError, rulesCheckbox, subscribeEmailCheckbox, subscribeSmsCheckbox } = formLocale

  const dirtyForm: any[] = []

  const hookForm = useForm<any>({
    defaultValues: {
      phone: '',
      first_name: '',
      rules: undefined,
      email: email || '',
      subscribe_email: undefined,
    },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<RegisterUser>>({
    data: {
      phone: '',
      first_name: '',
      age: undefined,
      rules: undefined,
      email: email || '',
      token: token || undefined,
      subscribe_email: undefined,
    },
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const loadUserData = useCallback(() => {
    dispatch(getInfoUser())
  }, [])

  const onRecaptchaChange = async (recaptcha: string) => {
    if (!auth) {
      signup({ ...formProps.data, recaptcha })
        .then((res) => {
          const { status = 0, message: messages } = res || {}

          setFormProps({ ...formProps, processed: false })

          if (status === 1) {
            TrackEvent({
              eventCategory: 'Registration',
              eventAction: 'send_r_form',
              eventLabel: 'success',
              eventContext: messages,
            })

            setAlert({
              alert: {
                message: messages,
                actionButtonText: 'Хорошо',
                title: 'Подтверждение данных',
                onClose: () => {
                  navigate(`../?signin${hookForm.getValues('email') ? `&login=${hookForm.getValues('email')}` : ''}`, {
                    replace: true,
                  })
                },
              },
            })
          } else {
            setFormProps({
              ...formProps,
              processed: false,
              snackbar: { ...formProps.snackbar, message: messages },
            })

            TrackEvent({
              eventCategory: 'Registration',
              eventAction: 'send_r_form',
              eventLabel: 'unsuccess',
              eventContext: messages,
            })
          }
        })
        .catch((e) => {
          if (e.response.data.data) {
            const err = Object.values(e.response.data.data)

            setFormProps({
              ...formProps,
              processed: false,
              snackbar: { ...formProps.snackbar, message: `${err[0]}` },
            })
          } else {
            setFormProps({
              ...formProps,
              processed: false,
              snackbar: { ...formProps.snackbar, message: serverError },
            })
          }

          TrackEvent({
            eventCategory: 'Registration',
            eventAction: 'send_r_form',
            eventLabel: 'unsuccess',
            eventContext: 'Ошибка сервера. Попробуйте позже',
          })
        })
        .finally(() => {
          recaptchaRef.current?.reset()
        })
    } else {
      const { subscribe_email } = formProps.data

      userUpdate({
        subscribe_email,
      })
        .then((res) => {
          const { status = 0, message: messages } = res || {}

          if (status === 1) {
            loadUserData()
          } else {
            setFormProps({
              ...formProps,
              processed: false,
              snackbar: { ...formProps.snackbar, message: messages },
            })
          }
        })
        .catch((e) => {
          setFormProps({
            ...formProps,
            processed: false,
            snackbar: { ...formProps.snackbar, message: serverError },
          })
        })
        .finally(() => {
          recaptchaRef.current?.reset()
        })
    }
  }

  const onSubmitForm = (data: RegisterUser) => {
    // setshow(true)
    setUserData(data)
    onSubmitPhone(data)
  }

  const onSubmitPhone = (data: RegisterUser) => {
    // setshow(false)

    // console.log(userData)

    setFormProps({
      ...formProps,
      data: { ...formProps.data, ...data },
      processed: true,
    })
    recaptchaRef.current?.execute()
  }

  const onRecaptchaExpired = () => {
    setFormProps({
      ...formProps,
      processed: false,
      snackbar: undefined,
    })
  }

  const onSignin = () => {
    navigate({ pathname: '../', search: '?signin' })
    TrackEvent({
      eventCategory: 'Registration',
      eventAction: 'click_profile',
    })
  }

  useEffect(() => hookForm.setFocus('first_name'), [])

  useEffect(() => {
    return () => {
      if (hookForm.formState.dirtyFields && !hookForm.formState.isSubmitSuccessful) {
        Object.keys(hookForm.formState.dirtyFields).forEach((item) => {
          if (item !== 'subscribe_email') {
            dirtyForm.push(item)
          }
        })

        if (dirtyForm.toString() !== '') {
          TrackEvent({
            eventCategory: 'Registration',
            eventAction: 'leave_registration',
            eventLabel: dirtyForm.toString(),
          })
        }
      }
    }
  }, [])

  const onClose = () => {
    setCollapse(false)
    setPhoneConfirm(false)
  }

  useEffect(() => {
    if (!collapse) {
      setTimeout(() => setCollapse(true), 200)
      setshow(false)
    }
  }, [collapse])

  const check = {
    icon: <Box sx={formStyles.icon} />,
    checked: (
      <Box sx={formStyles.iconActive}>
        <Box sx={formStyles.checkBox}>
          <CheckIcon sx={formStyles.check} />
        </Box>
      </Box>
    ),
  }

  return (
    <>
      <Modal
        open={show}
        title="Регистрация"
        onClose={onClose}
        sx={{ '& .MuiPaper-root': { maxWidth: '580px!important' } }}
      >
        <Collapse in={collapse}>
          <Box sx={formStyles.phoneTitle}>
            <Box mb="10px">Подтверди правильность указанного номера телефона.</Box>
            Данный номер будет использован для зачисления гарантированного кешбэка за первый зарегистрированный валидный
            чек.
          </Box>
          <Box sx={formStyles.phoneTitle2}>Указанный номер телефона:</Box>
          <Box sx={formStyles.phoneNumber}>{`+7 ${userData?.phone.substring(0, 3)} 
            ${userData?.phone.substring(3, 6)}
            ${userData?.phone.substring(6, 8)}
            ${userData?.phone.substring(8, 10)}`}</Box>
          <Box sx={formStyles.buttons}>
            <SubmitButton
              title="Подтвердить"
              onClick={() => {}}
              disabled={formProps.processed}
              processed={formProps.processed}
            />
            <Box sx={formStyles.phoneButton} onClick={onClose}>
              Изменить
            </Box>
          </Box>
        </Collapse>
      </Modal>
      <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
        {constants?.RECAPTCHA_SITE_KEY && (
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            onExpired={onRecaptchaExpired}
            sitekey={constants?.RECAPTCHA_SITE_KEY}
            onErrored={() => console.error('onRecaptchaErrored')}
            onChange={(tokenRecaptcha) => onRecaptchaChange(tokenRecaptcha ?? '')}
          />
        )}

        <Box py={1}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item width="100%" maxWidth={400}>
              {/* <Box width="100%" mb={3}> */}
              {/*  <Social label="Зарегистрироваться через" type="signup" /> */}
              {/* </Box> */}
              <Box width="100%" height={75}>
                <TextInput
                  label="Имя"
                  form={hookForm}
                  name="first_name"
                  variant="outlined"
                  placeholder="Введи имя"
                  disabled={formProps.processed}
                />
              </Box>
              <Box width="100%" height={75} mt={1}>
                <EmailInput name="email" form={hookForm} variant="outlined" disabled={formProps.processed} />
              </Box>
              <Box width="100%" height={75} mt={1}>
                <PhoneInput variant="outlined" name="phone" form={hookForm} disabled={formProps.processed} />
              </Box>
              <Box textAlign="left" m="5px">
                <CheckboxInput
                  required
                  name="rules"
                  form={hookForm}
                  icon={check.icon}
                  label={rulesCheckbox}
                  checked={check.checked}
                />
                <CheckboxInput
                  form={hookForm}
                  icon={check.icon}
                  name="subscribe_email"
                  checked={check.checked}
                  label={subscribeEmailCheckbox}
                />
              </Box>
              <Grid container direction="row" justifyContent="center">
                <Grid item xs={12}>
                  <Snackbar {...formProps.snackbar} variant="error" />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item width="100%" maxWidth={{ xs: 250, sm: 310 }}>
              <Box width="100%" mt={{ xs: 2, sm: 4 }}>
                <SubmitButton fullWidth title="Зарегистрироваться" />
                {/* Зарегистрироваться */}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </>
  )
}

type FormStylesProps =
  | 'icon'
  | 'iconActive'
  | 'checkBox'
  | 'check'
  | 'signin'
  | 'phoneTitle'
  | 'phoneTitle2'
  | 'phoneNumber'
  | 'buttons'
  | 'phoneButton'

const formStyles: Record<FormStylesProps, SxProps<Theme> | undefined> = {
  icon: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: `1px solid #AB0F7E`,
  }),
  iconActive: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: `1px solid #AB0F7E`,
  }),
  checkBox: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#AB0F7E',
  }),
  check: ({ palette }: Theme) => ({
    color: '#FFFFFF',
    fontSize: '20px!important',
  }),
  signin: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer',
    transition: '0.3s',
    textTransform: 'uppercase',
    color: palette.secondary.main,
    '&:hover': { color: `#FF5863!important` },
    [down('sm')]: {
      fontSize: 16,
    },
  }),
  phoneTitle: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '20.8px',
  }),
  phoneTitle2: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 700,
    textAlign: 'center',
    lineHeight: '20.8px',
    mt: '20px',
  }),
  phoneNumber: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 30,
    fontWeight: 600,
    textAlign: 'center',
    lineHeight: '34px',
    mt: '10px',
    color: '#AB0F7E',
  }),
  buttons: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 240,
    m: '30px auto 0 auto',
    '& > *': {
      mt: '14px',
    },
  }),
  phoneButton: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    minWidth: '240px',
    minHeight: '50px',
    color: '#E51523',
    alignItems: 'center',
    fontSize: 16,
    fontWeight: 700,
    cursor: 'pointer',
    transition: '0.3s',
    textTransform: 'uppercase',
    '&:hover': {
      color: '#FF5863',
    },
  }),
}
