import { FC, JSX, useState } from 'react'
import { Box, SxProps, Theme } from '@mui/material'

type ProgressBarProps = {
  height: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ height }): JSX.Element => {
  const [scrolled, setScrolled] = useState<string>('0%')

  const scrollProgress = () => {
    const scrollPx = document.documentElement.scrollTop
    const winHeightPx = document.documentElement.scrollHeight - document.documentElement.clientHeight
    setScrolled(`${(scrollPx / winHeightPx) * 100}%`)
  }

  const ref = () => {
    window.addEventListener('scroll', scrollProgress)
  }

  return (
    <Box sx={progressBarStyles.progress} width="100%" height={height} ref={ref}>
      <Box sx={progressBarStyles.progressBar} width={scrolled} height={height} />
    </Box>
  )
}

type ProgressBarStylesProps = 'progress' | 'progressBar'

const progressBarStyles: Record<ProgressBarStylesProps, SxProps<Theme> | undefined> = {
  progress: ({ palette }: Theme) => ({
    backgroundColor: palette.primary.main,
    // borderBottom: `1px solid ${palette.primary.contrastText}`,
  }),
  progressBar: ({ palette }: Theme) => ({
    backgroundColor: '#AB0F7E',
    transition: '0s',
  }),
}
