import { SxProps, Theme } from '@mui/material'
import { keyframes } from '@mui/system'
import { img } from '../../../../imgs'

type productsStylesProps = 'section' | 'title' | 'carouselItem' | 'card' | 'img' | 'titleCard' | 'textCard' | 'more'

export const productsStyles: Record<productsStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    mt: '-100px',
    pt: '150px',
    pb: '100px',
    flexDirection: 'column',
    borderRadius: '28px',
    background: `linear-gradient(#FFFFFF, #CFEAF9)`,
    [down('md')]: {
      height: 'fit-content',
      mt: '0',
      pt: '50px',
      pb: '80px',
    },
  }),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: '42px',
    fontWeight: 800,
    textAlign: 'center',
    mt: '100px',
    mb: '60px',
    color: palette.primary.dark,
    [down(1350)]: {
      fontSize: '3.11vw',
    },
    [down('md')]: {
      fontSize: '3.81vw',
      mt: '40px',
      mb: '40px',
    },
    [down('sm')]: {
      fontSize: '5vw',
      mb: '20px',
    },
  }),
  carouselItem: ({ breakpoints: { down }, palette }: Theme) => ({
    width: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'uppercase',
    flexDirection: 'column',
    fontSize: '24px',
    fontWeight: 800,
    color: palette.primary.dark,
    '& img': {
      maxHeight: 270,
      height: '100%',
      mt: '20px',
    },
  }),
  card: ({ breakpoints: { down } }) => ({
    width: '26.38vw',
    maxWidth: '380px',
    height: '32.63vw',
    maxHeight: '470px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'left',
    backgroundColor: '#FFFFFF',
    borderRadius: '28px',
    boxSizing: 'border-box',
    p: '24px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)',
    m: '0 auto',
    [down(1350)]: {
      fontSize: '1.33vw',
      p: '0 24px 24px 24px',
    },
    [down('md')]: {
      fontSize: '1.63vw',
      height: '40vw',
      width: '30vw',
      m: '0 auto 20px auto',
      p: '2.1vw',
    },
    [down('sm')]: {
      fontSize: '3vw',
      borderRadius: '20px',
      height: '76vw',
      width: 1,
      p: '3vw',
    },
  }),
  img: ({ breakpoints: { down } }: Theme) => ({
    mb: '15px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
    [down('md')]: {
      mb: '2.6vw',
    },
    [down('sm')]: {
      m: '0 auto 5vw',
      height: 'auto',
      maxWidth: '60vw',
    },
  }),
  titleCard: ({ breakpoints: { down }, palette }: Theme) => ({
    width: 1,
    fontSize: 18,
    fontWeight: 800,
    m: '0 auto 15px',
    textAlign: 'left',
    color: palette.primary.dark,
    boxSizing: 'border-box',
    [down(1350)]: {
      fontSize: '1.33vw',
    },
    [down('md')]: {
      fontSize: '1.63vw',
    },
    [down('sm')]: {
      fontSize: '3vw',
    },
  }),
  textCard: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    width: '100%',
    fontWeight: 500,
    textAlign: 'left',
    color: palette.primary.dark,
    boxSizing: 'border-box',
    [down(1350)]: {
      fontSize: '1.18vw',
    },
    [down('md')]: {
      fontSize: '1.45vw',
    },
    [down('sm')]: {
      fontSize: '2.66vw',
    },
  }),
  more: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    color: palette.secondary.contrastText,
    fontWeight: 700,
    mt: '20px',
    textDecoration: 'none',
    boxSizing: 'border-box',
    [down(1350)]: {
      fontSize: '1.48vw',
      mt: '1vw',
    },
    [down('md')]: {
      fontSize: '1.81vw',
    },
    [down('sm')]: {
      fontSize: '3.33vw',
    },
  }),
}
