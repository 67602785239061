import { FC, JSX, ReactNode } from 'react'
import { Box, Typography } from '@mui/material'

interface TabPanelProps {
  index: number
  value: number
  children?: ReactNode
}

export const TabPanel: FC<TabPanelProps> = ({ index, value, children, ...other }): JSX.Element => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ py: 2 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </Box>
  )
}
