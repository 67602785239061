import { createTheme, Theme } from '@mui/material/styles'
import palette from './palette'
import font from './font'
import { img } from '../imgs'

const {
  breakpoints: { down, up },
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: { values: { xs: 0, sm: 600, md: 1100, lg: 1440, xl: 1920 } },
  palette,
  font,
  typography: {
    fontFamily: font.primary,
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          backgroundImage: 'none',
          backgroundColor: 'transparent',
        },
      },
    },
    // start - стили контейнера
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: '0 20px!important',
          [down(350)]: {
            padding: '0 15px!important',
          },
        },
      },
    },
    // end - стили контейнера
    // start - стили бокового меню
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: '100%',
          maxWidth: 300,
        },
        paper: {
          width: '100%',
          maxWidth: 300,
          backgroundColor: `${palette.primary.main}!important`,
          backgroundImage: 'none',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(0, 0, 0, .7)',
        },
      },
    },
    // end - стили бокового меню
    // start - стили модального окна
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: 0,
          width: '100%',
          borderRadius: '30px',
          padding: '60px 0 30px',
          color: palette.text.primary,
          maxWidth: '500px!important',
          [down('sm')]: {
            borderRadius: '20px',
            padding: '55px 0 30px',
          },
        },
        paperFullScreen: { height: 'auto' },
        paperWidthXl: { maxWidth: '800px!important' },
        paperWidthLg: { maxWidth: '720px!important' },
        paperWidthMd: { maxWidth: '600px!important' },
        paperWidthSm: { maxWidth: '550px!important' },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: 28,
          lineHeight: 1.3,
          fontWeight: 800,
          display: 'flex',
          textAlign: 'center',
          position: 'relative',
          justifyContent: 'center',
          padding: '0 35px!important',
          color: palette.primary.dark,
          backgroundColor: 'transparent',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontSize: 18,
          fontWeight: 500,
          overflow: 'hidden',
          padding: '20px!important',
          color: palette.primary.dark,
          '& ul': {
            textAlign: 'left',
            lineHeight: 1.4,
          },
          '& a': {
            color: '#E51523',
            transition: '0.3s',
            '&:hover': {
              color: '#E72AB0',
            },
          },
          [down('sm')]: {
            fontSize: 17,
          },
        },
      },
    },
    // end - стили модального окна
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: 13,
          marginTop: -1,
          marginLeft: 10,
          fontWeight: 400,
          color: palette.error.main,
        },
      },
    },
    // start - стили линейной загрузки
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#000000',
        },
      },
    },
    // end - стили линейной загрузки
    // start - стили поля ввода
    MuiInputBase: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          fontSize: 13,
          minHeight: 45,
          '&.MuiIconButton-root': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
        input: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 18,
          minHeight: 50,
          paddingRight: 0,
          borderRadius: 42,
          fontFamily: font.primary,
          transition: '0.3s!important',
          backgroundColor: '#ffffff',
          '& svg': {
            transition: '.3s',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px!important',
            borderColor: '#AB0F7E!important',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E72AB0',
          },
        },
        notchedOutline: {
          borderColor: '#CBE1ED',
          padding: '0px 20px',
        },
        input: {
          fontSize: 18,
          fontWeight: 400,
          color: '#000000',
          padding: '12.5px 20px',
          '&:focus': {
            color: '#2D482C',
            '&:-internal-autofill-selected': { '-webkit-text-fill-color': '#2D482C!important' },
            '&:-webkit-autofill': { '-webkit-text-fill-color': '#2D482C!important' },
          },
          '&:-internal-autofill-selected': {
            background: 'transparent',
            '-webkit-text-fill-color': '#2D482C!important',
            '-webkit-box-shadow': 'inset 0 0 0 50px #ffffff!important',
          },
          '&:-webkit-autofill': {
            background: 'transparent',
            '-webkit-text-fill-color': '#2D482C!important',
            '-webkit-box-shadow': 'inset 0 0 0 50px #ffffff!important',
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          minHeight: 45,
          minWidth: 275,
          fontFamily: font.secondary,
          color: palette.primary.dark,
          paddingRight: 8,
          backgroundColor: palette.primary.main,
          '&:hover': {
            backgroundColor: palette.primary.main,
          },
          '&:before': {
            height: 0,
          },
          '&.Mui-focused': {
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.primary.main,
          },
        },
        input: {
          fontWeight: 400,
          fontSize: 13,
          color: palette.primary.dark,
          transform: 'translate(0px, 45%)',
          padding: '0 12px!important',
          '&:-internal-autofill-selected': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
          '&:-webkit-autofill': {
            background: 'transparent',
            '-webkit-box-shadow': 'inset 0 0 0 50px #FFFFFF!important',
            '-webkit-text-fill-color': '#000000!important',
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 18,
          [down('sm')]: {
            fontSize: 16,
          },
        },
        filled: {
          color: palette.primary.dark,
          fontWeight: 400,
          '&.Mui-focused': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
          '&.MuiInputLabel-shrink': {
            color: 'rgba(0, 0, 0, 0.6)',
          },
        },
        outlined: {
          fontWeight: 400,
          color: '#2D482C',
          top: '-4px',
          left: '10px',
          transition: '0.2s',
          // transform: 'translate(17px, 70%)',
          padding: '0!important',
          '&.Mui-focused': {
            color: '#2D482C',
            top: 0,
            left: '12px',
          },
          '&.MuiInputLabel-shrink': {
            // fontSize: '12px',
            // transform: 'translate(17px, 10%)',
          },
          '&.MuiFormLabel-filled': {
            top: 0,
            left: '12px',
          },
        },
      },
    },
    // end - стили поля ввода
    // start - стили поля со списком
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: 20,
          fontWeight: 600,
        },
        icon: {
          right: 13,
          color: 'rgba(0, 0, 0, 0.6)',
          transform: 'scale(1.4)',
        },
      },
    },
    // end - стили поля со списком
    // start - стили поля с автодополнением
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: '#000000!important',
          '& .MuiFilledInput-input': {
            transform: 'translate(0px, 20%)',
            paddingLeft: '5px!important',
          },
        },
        listbox: {
          backgroundColor: palette.primary.main,
        },
        endAdornment: {
          marginTop: 4,
          marginRight: 4,
          transition: '0.3s',
          '& .MuiSvgIcon-root': {
            fontSize: 18,
            fill: 'rgba(0, 0, 0, 0.6)!important',
          },
        },
        inputRoot: {
          paddingTop: 10,
          paddingRight: 30,
        },
        paper: {
          marginLeft: -0.5,
          width: 275,
          backgroundColor: palette.primary.main,
        },
        loading: {
          fontSize: 12,
          fontWeight: 500,
          color: palette.primary.dark,
        },
        noOptions: {
          fontSize: 12,
          fontWeight: 500,
          color: palette.primary.dark,
        },
      },
    },
    // end - стили поля с автодополнением
    // start - стили кнопки
    MuiButtonBase: {
      styleOverrides: {
        root: {},
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
        },
        containedPrimary: {
          minHeight: 50,
          minWidth: 240,
          fontWeight: 500,
          borderRadius: 100,
          color: '#ffffff',
          backgroundColor: palette.primary.main,
          '&:hover': {
            color: palette.primary.dark,
            backgroundColor: palette.primary.main,
          },
          '&.Mui-disabled': {
            color: 'rgba(255, 255, 255, 0.5)!important',
            backgroundColor: `${palette.primary.main}!important`,
          },
          [down('sm')]: {
            minWidth: 250,
          },
        },
        containedSecondary: {
          minHeight: 50,
          minWidth: 240,
          fontWeight: 700,
          borderRadius: 40,
          lineHeight: '100%!important',
          transition: '0.3s!important',
          color: '#ffffff',
          boxShadow: 'none!important',
          backgroundColor: palette.secondary.main,
          '&:hover': {
            color: palette.secondary.main,
            backgroundColor: '#E72AB0',
            [down('sm')]: {
              minHeight: 46,
              border: `none`,
              color: palette.secondary.dark,
              backgroundColor: palette.secondary.main,
            },
          },
          '&.Mui-disabled': {
            backgroundColor: `#7B0E5C!important`,
            color: `rgba(255, 255, 255, 0.5)!important`,
          },
          [down('sm')]: {
            border: 'none',
          },
        },
      },
    },
    // end - стили кнопки
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginRight: 5,
          marginLeft: -9,
          '& .MuiTypography-root': {
            fontWeight: 400,
            lineHeight: 1.3,
            color: palette.secondary.main,
            '&.Mui-disabled': {
              color: 'rgba(74, 89, 103, 0.8)!important',
            },
            '& a': {
              transition: '0.3s',
              fontWeight: '400!important',
              textDecoration: 'uppercase!important',
              color: `${palette.secondary.contrastText}!important`,
              '&:hover': {
                color: `${palette.secondary.contrastText}!important`,
              },
            },
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {},
        paper: {
          fontSize: 13,
          fontWeight: 500,
          lineHeight: 1.4,
          borderRadius: '10px',
          backgroundImage: 'none',
          color: palette.primary.contrastText,
        },
      },
    },
    MuiSnackbarContent: {
      styleOverrides: {
        root: {
          fontSize: 15,
          fontWeight: 400,
          lineHeight: 1.25,
          boxShadow: 'none',
          flexWrap: 'nowrap',
          textAlign: 'center',
          borderRadius: '0px',
          alignItems: 'center',
          backgroundColor: '#FAFAFA',
          margin: '0 5px',
          [down(350)]: {
            minWidth: 240,
          },
        },
        message: {
          padding: 0,
          fontWeight: 400,
          flex: '0 0 100%',
        },
        action: {},
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 0,
          marginLeft: 3,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          borderRadius: 0,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontWeight: 400,
          transition: '0.1s',
          fontSize: '11px!important',
          fontFamily: `${font.secondary}!important`,
          color: `${palette.primary.dark}!important`,
          backgroundColor: 'transparent!important',
          '&:hover': {
            backgroundColor: 'rgb(0, 0, 0, 0.1)!important',
          },
          '&.Mui-selected': {
            backgroundColor: 'rgb(0, 0, 0, 0.2)!important',
          },
        },
      },
    },
    // start - стили аккордион
    MuiAccordion: {
      styleOverrides: {
        root: {
          transition: '.3s',
          boxShadow: 'none',
          margin: '0 0 10px',
          backgroundImage: 'none',
          backgroundColor: palette.primary.main,
          '&.Mui-expanded': {
            margin: '3px 0',
            boxShadow: 'none',
          },
          '&:before': {
            display: 'none',
          },
        },
        rounded: {
          borderRadius: 40,
          [down('md')]: {
            borderRadius: 20,
          },
          '&:last-child': {
            borderBottomLeftRadius: 40,
            borderBottomRightRadius: 40,
            [down('md')]: {
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            },
          },
          '&:first-of-type': {
            borderTopLeftRadius: 40,
            borderTopRightRadius: 40,
            [down('md')]: {
              borderTopLeftRadius: 20,
              borderTopRightRadius: 20,
            },
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          minHeight: 110,
          paddingLeft: 30,
          paddingRight: 15,
          [down('lg')]: {
            minHeight: 90,
          },
          [down('md')]: {
            minHeight: 80,
            paddingLeft: 25,
            paddingRight: 10,
          },
          [down('sm')]: {
            minHeight: 60,
            paddingLeft: 15,
            paddingRight: 0,
          },
        },
        content: {
          fontSize: 25,
          fontWeight: 500,
          color: '#000000',
          [down('lg')]: {
            fontSize: 22,
          },
          [down('md')]: {
            fontSize: 20,
          },
          [down('sm')]: {
            fontSize: 16,
          },
        },
        expandIconWrapper: {
          padding: 10,
          transition: '.2s',
          borderRadius: '50%',
          // color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          '& .MuiSvgIcon-root': {
            width: 40,
            height: 40,
            [down('md')]: {
              width: 36,
              height: 36,
            },
            [down('sm')]: {
              width: 18,
              height: 18,
            },
          },
          '&:hover': {
            backgroundColor: '#F0F0F0',
            color: palette.primary.main,
          },
          '&$expanded': {
            backgroundColor: '#F0F0F0',
            color: palette.primary.main,
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          fontSize: 18,
          lineHeight: 1.3,
          fontWeight: 400,
          marginBottom: 0,
          display: 'block',
          transition: '.3s',
          padding: '0 30px 30px',
          borderBottomLeftRadius: 40,
          borderBottomRightRadius: 40,
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.main,
          [down('md')]: {
            fontSize: 16,
          },
          [down('sm')]: {
            fontSize: 14,
            padding: '0 15px 15px',
          },
          '& a': { color: palette.primary.contrastText },
        },
      },
    },
    // end - стили аккордион
    // start - стили табов
    MuiTabs: {
      styleOverrides: {
        root: {
          width: '100%',
          [down('sm')]: {
            minHeight: 45,
          },
        },
        indicator: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 30,
          width: '50%',
          minHeight: 60,
          minWidth: '50%',
          fontWeight: 700,
          lineHeight: 1.3,
          boxShadow: 'none',
          whiteSpace: 'nowrap',
          padding: '18px 30px 12px',
          textTransform: 'none',
          color: palette.primary.dark,
          opacity: 0.5,
          "&[aria-selected^='true']": { borderRadius: 70, color: palette.primary.dark, opacity: 1 },
          [down('md')]: { fontSize: 28, minHeight: 50 },
          [down('sm')]: { fontSize: 25, minHeight: 40, padding: '16px 10px 15px' },
          [down(350)]: { fontSize: 22 },
        },
      },
    },
    // end - стили табов
    // start - стили таблицы
    MuiTable: { styleOverrides: { root: {} } },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '&>tr:nth-of-type(odd)': {
            borderRadius: 8,
            '&>th': {
              '&:first-of-type': {
                borderTopLeftRadius: 8,
                borderBottomLeftRadius: 8,
              },
              '&:last-child': {
                borderTopRightRadius: 8,
                borderBottomRightRadius: 8,
              },
            },
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '&>tr:nth-of-type(odd)': {},
          '&>tr:nth-of-type(even)': {},
          '&>tr': {
            '&>td': {
              '&:first-of-type': {},
              '&:last-child': {},
            },
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: '0 none',
          textAlign: 'center',
        },
        head: {
          fontSize: 18,
          padding: '15px',
          fontWeight: 800,
          textTransform: 'uppercase',
          color: palette.primary.dark,
          borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          [down('md')]: {
            fontSize: 24,
          },
        },
        body: {
          fontSize: 18,
          fontWeight: 500,
          color: '#224A5B',
          padding: '15px',
          '& a': {
            fontSize: 18,
            color: `${palette.primary.dark}!important`,
            [down(350)]: {
              fontSize: 16,
            },
          },
          [down('md')]: {
            fontSize: 18,
          },
          [down('sm')]: {
            padding: '12px 10px 10px',
            borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
          },
          [down(350)]: {
            fontSize: 16,
          },
        },
      },
    },
    // end - стили таблицы
    // start - пагинация
    MuiPagination: {
      styleOverrides: {
        root: {
          height: '40px',
          backgroundColor: '#FFFFFF',
          borderRadius: '20px',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          fontSize: 20,
          fontWeight: 600,
          fontFamily: font.secondary,
          color: '#527C94',
          height: '40px',
          width: '40px',
          '&.Mui-selected': {
            fontSize: 20,
            fontWeight: 700,
            borderRadius: '50%',
            backgroundColor: '#FFFFFF',
            color: `#AB0F7E`,
            '&:hover': {
              backgroundColor: '#FFFFFF',
              color: `#AB0F7E`,
            },
            [down('sm')]: {
              fontSize: 20,
            },
            [down(350)]: {
              fontSize: 16,
            },
          },
          '&:hover': {
            backgroundColor: 'transparent',
            // color: `#FFFFFF`,
          },
          [down('sm')]: {
            fontSize: 20,
          },
          [down(350)]: {
            fontSize: 16,
          },
        },
        icon: {
          fontSize: 22,
          position: 'relative',
          height: '30px',
          fill: '#527C94',
          [down('sm')]: {
            fontSize: 20,
          },
        },
      },
    },
    // end - пагинация
  },
})
