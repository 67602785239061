import * as ls from 'local-storage'
import { RegisterUser, User, Token } from '../types'
import { fetchPrivateAPIToken, withPrivateAPIToken, withPublicAPIToken } from './service'

export const getConstants = async () =>
  withPublicAPIToken({
    url: '/const',
    method: 'get',
  })

export const getUser = async () =>
  await withPrivateAPIToken({
    url: '/private/user',
    method: 'get',
  })

export const getWinner = async (data: any, page: number, type: string) =>
  await withPublicAPIToken({
    url: `/winners?page=${page}&type=${type}`,
    method: 'post',
    data: { ...data },
  })
    .then((res) => {
      return res
    })
    .catch(() => {
      return { data: [] }
    })

export const signin = async (email: string, password: string) => fetchPrivateAPIToken(email, password)

export const logout = async () =>
  withPrivateAPIToken({
    url: '/private/user/logout',
    method: 'post',
  }).then((_r) => {
    ls.remove(Token.PRIVATE)
  })

export const forgotPassword = async (data: { email: string; recaptcha: string }) =>
  withPublicAPIToken({
    url: '/user/forgot/password/email',
    method: 'post',
    data,
  })

export const signup = async (data: RegisterUser) =>
  await withPublicAPIToken({
    url: '/register/create',
    method: 'post',
    data: { ...data },
  })

export const getSocialInfo = async (token: string) =>
  await withPrivateAPIToken({
    url: `/social/info/${token}`,
    method: 'get',
  })

export const socialAuth = async (data: { token: string }) =>
  await withPublicAPIToken({
    url: '/oauth/token/social',
    method: 'post',
    data: { ...data },
  })
    .then((res) => {
      ls.set(Token.PRIVATE, res.data)
      return res
    })
    .catch((e) => {
      throw e
    })

export const userUpdate = async (data: Partial<User>) =>
  await withPrivateAPIToken({
    url: '/private/user/update',
    method: 'post',
    data,
  })

export const checkRegister = async (data: any) =>
  withPrivateAPIToken({
    url: '/private/receipt/register',
    method: 'post',
    data: { ...data },
  })

export const getCheckBalance = async () =>
  withPrivateAPIToken({
    url: '/private/receipt/total',
    method: 'get',
  })

export const getCheckList = async () =>
  withPrivateAPIToken({
    url: '/private/receipt/history',
    method: 'get',
  })

export const subscribeChange = async (data: any) =>
  withPrivateAPIToken({
    url: '/private/user/subscribe/change',
    method: 'post',
    data: { ...data },
  })

export const getUserMessage = async () =>
  await withPrivateAPIToken({
    url: `/private/message`,
    method: 'get',
  })

export const getUserMessageRead = async (data: any) =>
  await withPrivateAPIToken({
    url: `/private/message/read`,
    method: 'post',
    data: { ...data },
  })

export const getPersonal = async (personNeed: string) =>
  await withPrivateAPIToken({
    url: `/private/persona/psession/${personNeed}`,
    method: 'get',
  })

export const prizeChange = async (data: { user_prize_id: number; prize_type_id: number }) =>
  withPrivateAPIToken({
    url: '/private/prize/type/change',
    method: 'post',
    data: { ...data },
  })

export const registerCode = async (data: { code: string }) =>
  await withPrivateAPIToken({
    url: `/private/code/create`,
    method: 'post',
    data: { ...data },
  })

export const getPrizeList = async () =>
  await withPrivateAPIToken({
    url: `/private/prize/list`,
    method: 'get',
  })

export const getShopList = async () =>
  await withPrivateAPIToken({
    url: `/private/prize/shop`,
    method: 'get',
  })

export const shopOrder = async (data: { id: number; recaptcha: string }) =>
  await withPrivateAPIToken({
    url: `/private/prize/shop/order`,
    method: 'post',
    data: { ...data },
  })

export const getRequestList = async () =>
  await withPrivateAPIToken({
    url: `/private/request/list`,
    method: 'get',
  })

export const requestOrder = async () =>
  await withPrivateAPIToken({
    url: `/private/request/order`,
    method: 'post',
  })

export const requestShare = async () =>
  await withPrivateAPIToken({
    url: `/private/request/share`,
    method: 'post',
  })

export const getRequestCalendar = async () =>
  await withPrivateAPIToken({
    url: `/private/request/calendar`,
    method: 'get',
  })

export const getBallList = async () =>
  await withPrivateAPIToken({
    url: `/private/ball/list`,
    method: 'get',
  })

export const getBallBalance = async () =>
  await withPrivateAPIToken({
    url: `/private/ball/balance`,
    method: 'get',
  })

export const getRequestBalance = async () =>
  await withPrivateAPIToken({
    url: `/private/request/balance`,
    method: 'get',
  })

export const getPrizeBalance = async () =>
  await withPrivateAPIToken({
    url: `/private/prize/balance`,
    method: 'get',
  })

export const getGame = async () =>
  await withPrivateAPIToken({
    url: `/private/game`,
    method: 'get',
  })

export const gameOpen = async (data: { id: number }) =>
  await withPrivateAPIToken({
    url: `/private/game/open`,
    method: 'post',
    data: { ...data },
  })

export const updateTokenFirebase = async (data: { token: string }) =>
  await withPublicAPIToken({
    url: `/firebase/update`,
    method: 'post',
    data: { ...data },
  })

export const updateTokenFirebasePrivate = async (data: { token: string }) =>
  await withPrivateAPIToken({
    url: `/private/firebase/update`,
    method: 'post',
    data: { ...data },
  })

export const feedbackTheme = async () => await withPublicAPIToken({ url: '/feedback/theme', method: 'get' })

export const faqList = (data?: any) =>
  withPublicAPIToken({
    url: '/faq',
    method: 'get',
    data,
  })

export const feedbackStore = (data: any) =>
  withPublicAPIToken({
    url: '/feedback/send',
    method: 'post',
    data,
  })

export const fivepostSave = (data: string) =>
  withPrivateAPIToken({
    url: '/private/fivepost/save',
    method: 'post',
    data,
  })

export const checkUniqEmailAuth = (data: any) =>
  withPrivateAPIToken({
    url: '/user/exists/auth',
    method: 'post',
    data,
  })

export const checkUniqRegister = (data: any) =>
  withPublicAPIToken({
    url: '/register/exists',
    method: 'post',
    data,
  })

export const post = async (url: string, data: any) =>
  withPrivateAPIToken({
    url,
    method: 'post',
    data,
  })

export const get = async (url: string, params?: any) =>
  withPublicAPIToken({
    url,
    method: 'get',
    params,
  })
