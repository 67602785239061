import { FC, JSX, MutableRefObject, useState, useEffect, useCallback } from 'react'
import { Box, Button, Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import HtmlReactParser from 'html-react-parser'
import QRCode from 'react-qr-code'
import Slider from 'react-slick'
import { loginStyles } from './login.styles'
import { img, SloganSvg } from '../../../../imgs'
import { useDetectDevice, useReduxSelector } from '../../../../hooks'
import { DetectDevice } from '../../../../types'
import { SigninForm } from '../../../../modals/signin/signin.form'
import { SignupForm } from '../../../../modals/signup/signup.form'
import { getSocialInfo } from '../../../../api'

const custom_pag = (i: any) => {
  return (
    <div className="ts-slick__dots--custom">
      <div className="loading" />
    </div>
  )
}
const custom_dots = (dots: any) => {
  return <ul>{dots}</ul>
}

export const Login: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const detectDevice = useDetectDevice()
  const [searchParams, setSearchParams] = useSearchParams()
  const [dataSocial, setDataSocial] = useState<any>(undefined)
  const [adaptiveOpacity, setAdaptiveOpacity] = useState<boolean>(true)
  const [loginRegistrationSwitch, setLoginRegistrationSwitch] = useState(false)
  const [loginRegistrationSection, setLoginRegistrationSection] = useState(false)

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const [token, setToken] = useState<string | null>(searchParams.get('token'))

  const loadSocialInfo = useCallback((tokenSocial: string) => {
    getSocialInfo(tokenSocial).then((res) => {
      if (res.status === 1) {
        setDataSocial(res.data.info)
      }
    })
  }, [])

  const onChangeStatus = (e: any) => {
    const button_id = e.target.id
    if (button_id === '1' && loginRegistrationSwitch === true) {
      setLoginRegistrationSwitch(false)
      setAdaptiveOpacity(false)
      setTimeout(() => {
        setAdaptiveOpacity(true)
        setLoginRegistrationSection(false)
      }, 200)
    } else if (button_id === '2' && loginRegistrationSwitch === false) {
      setLoginRegistrationSwitch(true)
      setAdaptiveOpacity(false)
      setTimeout(() => {
        setAdaptiveOpacity(true)
        setLoginRegistrationSection(true)
      }, 200)
    }
  }

  const settings = {
    customPaging: custom_pag,
    speed: 300,
    dots: true,
    appendDots: custom_dots,
    infinite: true,
    easing: 'cubic',
    slidesToShow: 1,
    initialSlide: 0,
    slidesToScroll: 1,
    className: 'custom-slider-white',
    arrows: false,
  }

  return (
    <Box id="login" sx={loginStyles.section}>
      <Box sx={loginStyles.sectionLogin}>
        <Box sx={loginStyles.loginToggleHolder}>
          <Box sx={loginStyles.loginToggleButton} style={loginRegistrationSwitch ? { left: '50%' } : { left: 0 }} />
          <Box
            sx={loginStyles.loginButton}
            style={loginRegistrationSwitch ? { color: '#051F4A' } : { color: '#FFFFFF' }}
          >
            <Box id="1" onClick={onChangeStatus}>
              Авторизация
            </Box>
          </Box>
          <Box
            sx={loginStyles.loginButton}
            style={!loginRegistrationSwitch ? { color: '#051F4A' } : { color: '#FFFFFF' }}
          >
            <Box id="2" onClick={onChangeStatus}>
              Регистрация
            </Box>
          </Box>
        </Box>
        <Box
          style={
            adaptiveOpacity ? { transition: '0.2s linear', opacity: 1 } : { transition: '0.2s linear', opacity: 0 }
          }
        >
          {loginRegistrationSection && <SignupForm email={dataSocial?.email} token={token} />}
          {!loginRegistrationSection && <SigninForm clearToken={() => setToken(null)} />}
        </Box>
      </Box>
      <Box sx={loginStyles.sectionRules}>
        <Box sx={loginStyles.sectionRulesHead}>Три простых шага для участия</Box>
        <Box sx={loginStyles.sectionRulesOption}>
          <Box>1</Box>
          <Box>Авторизуйтесь или зарегистрируйтесь</Box>
        </Box>
        <Box
          component="img"
          src={md ? img.bannerSeparator : img.bannerSeparator_2}
          sx={loginStyles.sectionRulesSeparator}
          alt=""
          style={md ? { marginTop: '10px', marginBottom: '10px' } : { marginTop: '14px', marginBottom: '4px' }}
        />
        <Box sx={loginStyles.sectionRulesOption}>
          <Box>2</Box>
          <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <span>
              Зарегистрируйте чек{md ? ' ' : <br />}о покупке линз ACUVUE
              <Box
                component="span"
                fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
                style={{ verticalAlign: 'super' }}
              >
                ®
              </Box>
            </span>
            <span
              style={{
                fontSize: md ? '3vw' : 20,
                fontWeight: 600,
                color: md ? '#737C8A' : '#79A8DF',
                textTransform: 'none',
              }}
            >
              с 1 октября по 31 декабря 2024 года
            </span>
          </Box>
        </Box>
        <Box
          component="img"
          src={md ? img.bannerSeparator : img.bannerSeparator_2}
          sx={loginStyles.sectionRulesSeparator}
          alt=""
          style={md ? { marginTop: '6px', marginBottom: '10px' } : { marginTop: '4px', marginBottom: '14px' }}
        />
        <Box sx={loginStyles.sectionRulesOption}>
          <Box>3</Box>
          <Box>Получите возможность выиграть приз</Box>
        </Box>
      </Box>
    </Box>
  )
}
