import { Box } from '@mui/material'

const { REACT_APP_RULES_URL: RULES, REACT_APP_AGREEMENTS_URL: AGREEMENTS, REACT_APP_PDN_URL: PDN_URL } = process.env

export const formLocale = {
  success: {},

  badRequest: {},

  serverError: 'Сервер временно недоступен. Пожалуйста, попробуйте позже',

  notValid: `Внимание! Одно или несколько полей не заполнены или заполнены неверно.
		 	<br/>Проверьте корректность заполнения полей.`,

  signin: {
    invalidCredentials: 'Неверный логин или пароль.',
  },

  rulesCheckbox: (
    <Box fontSize={14} fontWeight={500}>
      Я прочитал и согласен с{' '}
      <a href={RULES} style={{ whiteSpace: 'nowrap', fontSize: 16 }} target="_blank" rel="noreferrer">
        Правилами Акции
      </a>{' '}
      и{' '}
      <a href={AGREEMENTS} style={{ whiteSpace: 'nowrap', fontSize: 16 }} target="_blank" rel="noreferrer">
        Пользовательским соглашением,
      </a>{' '}
      согласен на обработку персональных данных
    </Box>
  ),

  subscribeEmailCheckbox: (
    <Box fontSize={14} fontWeight={500}>
      Я согласен на получение <span style={{ whiteSpace: 'nowrap' }}>E-mail</span> рассылки от ООО «Джонсон & Джонсон»
    </Box>
  ),

  subscribeSmsCheckbox: (
    <Box fontSize={14} fontWeight={500}>
      Я согласен получать информацию об акции через <span style={{ whiteSpace: 'nowrap' }}>SMS</span>
    </Box>
  ),

  age: (
    <Box fontSize={14} fontWeight={500}>
      Я подтверждаю, что мне уже исполнилось 18 лет и я являюсь гражданином РФ
    </Box>
  ),
}
