import { FC, JSX } from 'react'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { emailInputProps } from './props-autocomplete'
import { GenericTextInput } from './generic-text-input'

type EmailProps = {
  name: string
  InputProps?: any
  disabled?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  options?: RegisterOptions
  variant?: 'standard' | 'filled' | 'outlined'
}

export const EmailInput: FC<EmailProps> = ({
  name,
  form,
  options,
  InputProps,
  disabled = false,
  placeholder = 'Введи E-mail',
  variant = 'outlined',
}): JSX.Element => {
  const { register, formState } = form

  return (
    <GenericTextInput
      register={register(name, { required: true, ...options })}
      type="email"
      label="E-mail"
      variant={variant}
      disabled={disabled}
      placeholder={placeholder}
      helperText={formState.errors[name]?.message}
      InputProps={{ ...InputProps, inputProps: { ...(emailInputProps as any) } }}
    />
  )
}
