import { FC, JSX, ChangeEvent, ReactNode } from 'react'
import {
  SelectProps as SelectPropsType,
  TextField,
  InputLabelProps as InputLabelPropsType,
  OutlinedInputProps,
  FilledInputProps,
  InputBaseComponentProps,
} from '@mui/material'
import { InputType } from '../../types'

type GenericTextProps = {
  id?: string
  register: any
  rows?: number
  label?: string
  helperText: any
  type?: InputType
  select?: boolean
  focused?: boolean
  disabled?: boolean
  multiline?: boolean
  fullWidth?: boolean
  children?: ReactNode
  placeholder?: string
  autoComplete?: string
  SelectProps?: SelectPropsType
  color?: 'primary' | 'secondary'
  inputProps?: InputBaseComponentProps
  onChange?(e: ChangeEvent<any>): void
  InputLabelProps?: InputLabelPropsType
  variant?: 'standard' | 'filled' | 'outlined'
  InputProps?: OutlinedInputProps | FilledInputProps
}

export const GenericTextInput: FC<GenericTextProps> = (props): JSX.Element => {
  const {
    register,
    rows = 1,
    children,
    select = false,
    type = 'text',
    disabled = false,
    fullWidth = true,
    multiline = false,
    focused,
    autoComplete = 'off',
    color = 'primary',
    InputLabelProps,
    variant = 'outlined',
  } = props

  return (
    <TextField
      {...register}
      // InputLabelProps={{ shrink: true, ...InputLabelProps }}
      InputLabelProps={{ ...InputLabelProps }}
      autoComplete={autoComplete}
      fullWidth={fullWidth}
      multiline={multiline}
      disabled={disabled}
      focused={focused}
      variant={variant}
      select={select}
      color={color}
      rows={rows}
      type={type}
      {...props}
    >
      {children}
    </TextField>
  )
}
