import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GreetingsAction } from './greetings.types'

const initialState: GreetingsAction = {
  show: false,
}

export const greetingsSlice = createSlice({
  name: 'greetings',
  initialState,
  reducers: {
    setGreetings(state: GreetingsAction, action: PayloadAction<GreetingsAction>) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: () => {},
})

export const greetingsActions = greetingsSlice.actions
export const greetingsReducer = greetingsSlice.reducer
