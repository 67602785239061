import { FC, JSX } from 'react'
import Moment from 'react-moment'
import { Box } from '@mui/material'
import * as ls from 'local-storage'
import { Modal } from '../modal'
import { useReduxActions, useReduxSelector } from '../../hooks'

export const Greetings: FC = (): JSX.Element => {
  const { setGreetings } = useReduxActions()

  const { show } = useReduxSelector((state) => state.greetings)

  const { data: constants } = useReduxSelector((state) => state.constants)

  const { NOW = 0 } = constants || {}

  const onClose = () => {
    ls.set('_info_modal_main', NOW + 86400 * 1000)
    setGreetings({ show: !show })
  }

  return (
    <Modal open={show} maxWidth="sm" title="Внимание!" onClose={onClose}>
      {constants?.IS_PROJECT_STOP_ACTION ? (
        <Box textAlign="center" my={2} lineHeight={1.3}>
          Уважаемые участники!
          <br />
          Регистрация в&nbsp;акции завершена
          <br />
          <Moment format="DD MMMM YYYY года.">{constants?.PROJECT_STOP_ACTION}</Moment>
        </Box>
      ) : (
        <Box textAlign="center" m={2} lineHeight={1.3}>
          <Box fontSize="110%" mb="7px">
            Друзья!
          </Box>
          <Box mb="7px">Спасибо вам за активное участие в акции!</Box>
          Нам очень приятно видеть ваш отклик, и мы рады сообщить о скором обновлении Витрины призов. Уже с 26 июня в
          14:00 будут доступны для заказа новые крутые призы!
        </Box>
      )}
    </Modal>
  )
}
