import { FC, JSX, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Box, Collapse, SxProps, Theme } from '@mui/material'
import { Modal, TrackEvent } from '../../components'
import { useReduxSelector } from '../../hooks'
import { ForgotForm } from './forgot.form'

export const forgotModalName = 'forgotModal'

export const ForgotModal: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [collapse, setCollapse] = useState<boolean>(true)

  const { modal } = useReduxSelector((state) => state.modals)
  const { show = false } = modal[forgotModalName] || {}

  const onClose = () => {
    setCollapse(false)
    setSearchParams({})
  }

  useEffect(() => {
    if (!collapse) setTimeout(() => setCollapse(true), 200)
  }, [collapse])

  useEffect(() => {
    if (show && collapse) {
      TrackEvent({ eventCategory: 'Forgot', eventAction: 'open_f_form' })
    }
  }, [show])

  return (
    <Modal
      sx={{ '& .MuiPaper-root': { maxWidth: '556px!important' } }}
      open={show}
      title="Восстановление пароля"
      name={forgotModalName}
      onClose={onClose}
    >
      <Collapse in={collapse}>
        <Box width="100%" textAlign="center" sx={formStyles.text}>
          <Box mb="20px">Укажите E-mail, с которым вы зарегистрированы в Акции.</Box>
          <Box>Пароль к Личному кабинету будет отправлен на указанный E-mail.</Box>
        </Box>
        <ForgotForm rootModal={forgotModalName} />
      </Collapse>
    </Modal>
  )
}

type ModalFormStylesProps = 'text'

const formStyles: Record<ModalFormStylesProps, SxProps<Theme> | undefined> = {
  text: ({ breakpoints: { down }, font }: Theme) => ({
    maxWidth: 500,
    m: '5px auto 25px',
    '& div': {
      fontSize: 16,
      fontWeight: 500,
    },
    [down('sm')]: {
      width: 280,
      m: '0 auto 15px',
    },
  }),
}
