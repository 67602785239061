import {
  FC,
  Ref,
  JSX,
  useState,
  ReactNode,
  useEffect,
  forwardRef,
  ReactElement,
  ForwardRefExoticComponent,
} from 'react'
import {
  Box,
  Slide,
  Theme,
  Grid,
  Button,
  Dialog,
  SxProps,
  Collapse,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { useDetectDevice, useReduxActions } from '../../hooks'
import { AlertProps, DetectDevice } from '../../types'
import { str } from '../../utils'

const Transition: ForwardRefExoticComponent<any> = forwardRef(
  (props: any & { children: ReactElement<any, any> }, ref: Ref<unknown>): JSX.Element => (
    <Slide {...props} ref={ref} direction="up" easing="ease-out" timeout={{ enter: 300, exit: 300 }}>
      {props.children}
    </Slide>
  )
)

export const Alert: FC<AlertProps> = ({
  cancelBtnText = 'Отмена',
  rootModal,
  title = 'Информация',
  fullScreen = false,
  closeButton = false,
  emptyMessage = false,
  actionButtonText = 'OK',
  onCloseConfirm,
  confirmBtnText = 'Подтвердить',
  onConfirm,
  onClose,
  onAction,
  message = undefined,
  ...props
}): JSX.Element => {
  const device = useDetectDevice()
  const { setModal, setAlert } = useReduxActions()
  const [text, setText] = useState<ReactNode | string>('')

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const handleClose = () => {
    if (onClose) onClose()
    setAlert({ alert: {} })
    if (device === DetectDevice.IOS) document.body.style.position = 'relative'
  }

  useEffect(() => {
    if (message) {
      setText(message)
      if (rootModal) setModal({ modal: { [rootModal]: { show: false, params: undefined } } })
      if (device === DetectDevice.IOS) document.body.style.position = 'fixed'
    } else handleClose()
  }, [message])

  return (
    <Dialog
      {...props}
      scroll="body"
      maxWidth="sm"
      open={!!message}
      title={undefined}
      fullScreen={fullScreen || sm}
      aria-labelledby="global-alert"
      TransitionComponent={Transition}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') handleClose()
      }}
    >
      {title && (
        <DialogTitle id="alert-title" textTransform="uppercase">
          {str.normalize(title)}
        </DialogTitle>
      )}

      {closeButton && (
        <Box position="absolute" top={0} right={0} style={{ zIndex: 2 }}>
          <IconButton color="primary" disabled={false} onClick={handleClose} style={{ padding: 0 }}>
            <CloseIcon sx={{ color: '#FFFFFF', width: { xs: 30, sm: 38 }, height: { xs: 30, sm: 38 } }} />
          </IconButton>
        </Box>
      )}

      {!emptyMessage && (
        <DialogContent id="alert-content">
          <Collapse in={!!message}>
            <Box textAlign="center" px={{ xs: 0, sm: 3 }} py={{ xs: 0, sm: 3 }} lineHeight={1.3} boxSizing="border-box">
              {typeof message === 'string' ? <>{str.normalize(message)}</> : message}
            </Box>
          </Collapse>
        </DialogContent>
      )}

      {actionButtonText.length > 0 && (
        <DialogActions id="alert-actions">
          <Box mx="auto" maxWidth={220} width="100%" mb={{ xs: '15px', sm: '20px' }}>
            <Button
              fullWidth
              color="secondary"
              variant="contained"
              sx={alertStyles.btnSecondary}
              onClick={() => {
                if (onAction) onAction()
                else handleClose()
              }}
            >
              {actionButtonText}
            </Button>
          </Box>
        </DialogActions>
      )}

      {onConfirm && (
        <DialogActions id="alert-actions">
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
            mb={{ xs: '15px', sm: '20px' }}
          >
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                sx={alertStyles.btnSecondary}
                onClick={() => {
                  if (onConfirm) onConfirm()
                  handleClose()
                }}
              >
                {confirmBtnText}
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                sx={alertStyles.btnPrimary}
                onClick={() => {
                  if (onCloseConfirm) onCloseConfirm()
                  handleClose()
                }}
              >
                {cancelBtnText}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </Dialog>
  )
}

type ProfileStylesProps = 'btnPrimary' | 'btnSecondary'

export const alertStyles: Record<ProfileStylesProps, SxProps<Theme> | undefined> = {
  btnPrimary: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 18,
    minHeight: 60,
    minWidth: 300,
    border: `2px solid ${palette.primary.dark}`,
    [down('sm')]: {
      fontSize: 17,
      minHeight: 51,
      minWidth: 250,
    },
  }),
  btnSecondary: ({ breakpoints: { down }, palette }: Theme) => ({
    minWidth: 220,
  }),
}
