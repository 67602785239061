import { ForwardRefExoticComponent, FC, JSX, forwardRef, ReactElement, Ref, useEffect, useState } from 'react'
import { Box, Button, Collapse, SxProps, Theme, Container } from '@mui/material'
import Cookies from 'js-cookie'
import { Modal } from '..'

export const AgeCheck: FC = (): JSX.Element => {
  const [show, setshow] = useState<boolean>(false)
  const [collapse, setCollapse] = useState<boolean>(true)
  const [status, setStatus] = useState<boolean>(true)

  const onClose = () => {
    setCollapse(false)
    Cookies.set('agecheck', '1')
  }

  useEffect(() => {
    if (!collapse) {
      setTimeout(() => setCollapse(true), 200)
      setshow(false)
    }
  }, [collapse])

  useEffect(() => {
    if (Cookies.get('agecheck') !== '1') {
      setshow(true)
    }
  }, [])

  return (
    <Modal
      open={show}
      title="Предупреждение"
      onClose={onClose}
      closeButton={false}
      slotProps={{
        backdrop: {
          style: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            backdropFilter: 'blur(10px)',
            WebkitBackdropFilter: 'blur(10px)',
          },
        },
        root: {
          style: {
            zIndex: 1302,
          },
        },
      }}
      PaperProps={{
        style: {
          width: 'calc(100% - 17px)',
          margin: 'auto 8.5px',
          boxSizing: 'border-box',
        },
      }}
    >
      <Collapse in={collapse}>
        {status && (
          <>
            <Box sx={AgeCheckStyles.box1}>
              Данный сайт содержит информацию, не предназначенную для просмотра лицами, не достигшими возраста
              18&nbsp;лет.
            </Box>
            <Box sx={AgeCheckStyles.box2}>Тебе уже исполнилось 18&nbsp;лет?</Box>
            <Container sx={AgeCheckStyles.button_container}>
              <Button sx={AgeCheckStyles.button} onClick={() => setStatus(false)}>
                Нет
              </Button>
              <Button sx={AgeCheckStyles.button} onClick={onClose}>
                Да
              </Button>
            </Container>
          </>
        )}
        {!status && <Box sx={AgeCheckStyles.box2}>Доступ на сайт разрешен только совершеннолетним</Box>}
      </Collapse>
    </Modal>
  )
}

type AgeCheckStylesProps = 'box1' | 'box2' | 'button' | 'button_container'

const AgeCheckStyles: Record<AgeCheckStylesProps, SxProps<Theme> | undefined> = {
  box1: ({ breakpoints: { down }, palette }: Theme) => ({
    textAlign: 'center',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20.8px',
  }),
  box2: ({ breakpoints: { down }, palette }: Theme) => ({
    textAlign: 'center',
    fontWeight: 700,
    fontSize: '16px',
    lineHeight: '20px',
    mt: '20px',
  }),
  button_container: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    mt: '30px',
    width: '80%',
    justifyContent: 'space-around',
  }),
  button: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    textAlign: 'center',
    fontWeight: 600,
    background: '#E51523',
    color: '#fff',
    padding: '10px',
    width: '30%',
  }),
}
