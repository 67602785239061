import { FC, JSX } from 'react'
import { Box, Grid, SxProps, Theme } from '@mui/material'

export const Preloader: FC = (): JSX.Element => {
  return (
    <Grid container justifyContent="center" alignItems="center" height="100vh">
      <Grid item>
        <Box width="6rem" height="6rem">
          <span className="preloader" />
        </Box>
      </Grid>
    </Grid>
  )
}

type preloaderStylesProps = 'preloader'

export const preloaderStyles: Record<preloaderStylesProps, SxProps<Theme> | undefined> = {
  preloader: () => ({}),
}
