import { FC, JSX } from 'react'
import { Box, Grid, Container, Theme, SxProps } from '@mui/material'
import Moment from 'react-moment'
import * as process from 'process'
import { useDetectDevice, useReduxSelector } from '../../../hooks'
import { DetectDevice } from '../../../types'
import { TrackEvent } from '../../GA'
import { img } from '../../../imgs'

export const Footer: FC = (): JSX.Element => {
  const detectDevice = useDetectDevice()

  const {
    REACT_APP_PHONE: PHONE,
    REACT_APP_EMAIL: EMAIL,
    REACT_APP_RULES_URL: RULES,
    REACT_APP_AGREEMENTS_URL: AGREEMENTS,
  } = process.env

  const { data: constants } = useReduxSelector((state) => state.constants)

  const {
    PROJECT_STOP_ACTION = 0,
    PROJECT_START_ACTION = 0,
    PROJECT_START_DOC_REGISTER = 0,
    PROJECT_STOP_DOC_REGISTER = 0,
  } = constants || {}

  const onClickRules = () => {
    TrackEvent({ eventCategory: 'InteractionDoc', eventAction: 'rules' })
  }

  return (
    <Box sx={footerStyles.footer}>
      <Container sx={footerStyles.container}>
        <Box component="img" src={img.logo_letters} alt="" sx={footerStyles.containerLeft} />
        <Box sx={footerStyles.containerRight}>
          <Box sx={footerStyles.link}>
            <a href={`${RULES}`} target="_blank" rel="noopener noreferrer" onClick={onClickRules}>
              Правила акции
            </a>
          </Box>
          <Box sx={footerStyles.link}>
            <a href={`${AGREEMENTS}`} target="_blank" rel="noopener noreferrer">
              Пользовательское соглашение
            </a>
          </Box>
          <Box sx={footerStyles.link} ml={{ xs: 0, sm: '50px', md: '120px' }}>
            Пишите нам на
            <br />
            <a href={`mailto:${EMAIL}`} style={{ fontWeight: 700 }}>
              {EMAIL}
            </a>
          </Box>
        </Box>
      </Container>
      <Container>
        <Box sx={footerStyles.text}>Изображения призов приведены исключительно с целью демонстрации.</Box>
        <Box sx={footerStyles.text}>
          *По результатам опроса 1001 потребителя, носящих контактные линзы в России, который был проведен в октябре
          2023 года, 69% носителей контактных линз, принявших участие в опросе, считают, что бренд ACUVUE® является
          брендом № 1 в категории контактных линз в России. Срок действия акции: с 01.10.2024 по 31.12.2024. Полные
          правила Акции доступны на{' '}
          <a
            style={{ color: '#7083A1', textDecorationColor: '#7083A1' }}
            href="https://www.acuvue.com/ru-ru/"
            target="_blank"
            rel="noreferrer"
          >
            www.acuvue.com/ru-ru
          </a>
          ,{' '}
          <a
            style={{ color: '#7083A1', textDecorationColor: '#7083A1' }}
            href="https://xn--80aaajdvlhlxdbjrls1o.xn--p1ai/"
            target="_blank"
            rel="noreferrer"
          >
            откройглазанагоры.рф
          </a>
          . Для получения дополнительной информации о правильном ношении контактных линз, уходе и безопасности
          обращайтесь к врачу-офтальмологу и прочитайте инструкцию по применению контактных линз, доступную на сайте
          www.acuvue.com/ru-ru. © 2024, ООО «Джонсон & Джонсон». Рег. Уд № РЗН 2016/4406 от 09.02.2023, № ФСЗ 2008/01309
          от 09.02.2023, № ФСЗ 2010/07338 от 10.02.2023, № ФСЗ 2010/07713 от 10.02.2023, № ФСЗ 2010/07713 от 10.02.2023,
          № ФСЗ 2010/07714 от 09.02.2023, № ФСЗ 2010/07714 от 09.02.2023, № РЗН 2016/4442 от 03.02.2023, № РЗН
          2022/16913 от 15.04.2022, № РЗН 2023/19364 от 18.01.2023, № РЗН 2023/19366 от 18.01.2023. Срок действия
          Программы MyAcuvue®: с 01.11.2018 по 31.12.2025. Полные правила Программы доступны на{' '}
          <a
            style={{ color: '#7083A1', textDecorationColor: '#7083A1' }}
            href="https://www.acuvue.com/ru-ru/"
            target="_blank"
            rel="noreferrer"
          >
            https://www.acuvue.com/ru-ru/
          </a>
        </Box>
        <Box sx={footerStyles.text}>
          <strong>**Оазис максимальный однодневный. ****Оазис однодневный с технологией.</strong>{' '}
          <strong>*****Оазис с технологией HYDRACLEAR® плюс.</strong>
          ******Семейства контактных линз бренда ACUVUE® с утверждениями о непревзойденном комфорте: ACUVUE® OASYS
          (Оазис) (включая семейства линз ежедневной замены), 1-DAY (однодневный) ACUVUE® MOIST (Моист) (в категории
          гидрогелевых линз ежедневной замены) и ACUVUE® VITA® (Вита).
          <span style={{ fontSize: '6px', verticalAlign: 'super' }}>9,10,11</span>
          1. Данные в файлах компании JJV, 2022. Непревзойденный комфорт семейства контактных линз ACUVUE® OASYS
          (оазис). Клинические исследования размещены на сайте ClinicalTrials.gov (31 октября 2022 г.). Семейство
          контактных линз ACUVUE® с непревзойденным комфортом: ACUVUE® OASYS (оазис) (включая семейство однодневных
          линз), 1-DAY ACUVUE® MOIST (увлажняющие) with (с технологией) LACREON® (в категории гидрогелевых однодневных
          линз) и ACUVUE® VITA® (Вита). 2. Данные в файлах компании JJV, 2016. Оценка в течение 1 недели в дневном
          режиме ношения, n = 119 пользователей мягких контактных линз в США. 3. Данные в файлах JJV, 2023 г.
          Утверждения о непревзойденном комфорте для семейств контактных линз бренда ACUVUE® OASYS (Оазис) на основании
          клинических испытаний, опубликованных на ClinicalTrials.gov. (31 октября 2023 г.). 4. Данные в файлах JJV,
          2023 г. Утверждения о непревзойденном комфорте для семейства контактных линз бренда ACUVUE® 1-DAY
          (однодневный) MOIST (Моист) на основании клинических испытаний, опубликованных на ClinicalTrials.gov. (31
          октября 2023 г.). 5. Данные в файлах JJV, 2023 г. Утверждения о непревзойденном комфорте для семейства
          контактных линз бренда ACUVUE® VITA (Вита) на основании клинических испытаний, опубликованных на
          ClinicalTrials.gov. (31 октября 2023 г.). 6. Данные в файлах JJV, 2021. Характеристики контактных линз
          семейства OASYS (Оазис) при использовании гаджетов (ACUVUE® OASYS (оазис) 1–DAY (однодневный) with (с
          технологией) HydraLuxe®, ACUVUE® OASYS (оазис) with (с технологией) Transitions (переходов)). 7 — оазис 8 —
          увлажняющий. 9. Данные в файлах JJV, 2023 г. Утверждения о непревзойденном комфорте для семейств контактных
          линз бренда ACUVUE® OASYS (Оазис) на основании клинических испытаний, опубликованных на ClinicalTrials.gov.
          (31 октября 2023 г.). 10. Данные в файлах JJV, 2023 г. Утверждения о непревзойденном комфорте для семейства
          контактных линз бренда ACUVUE® 1-DAY (Однодневные) MOIST (Моист) на основании клинических испытаний,
          опубликованных на ClinicalTrials.gov. (31 октября 2023 г.). 11. Данные в файлах JJV, 2023 г. Утверждения о
          непревзойденном комфорте для семейства контактных линз бренда ACUVUE® VITA (Вита) на основании клинических
          испытаний, опубликованных на ClinicalTrials.gov. (31 октября 2023 г.).
        </Box>
        <Box sx={footerStyles.text}>2024PP15936 2024, ООО «Джонсон & Джонсон»</Box>
      </Container>
    </Box>
  )
}

type FooterStylesProps = 'footer' | 'container' | 'link' | 'containerLeft' | 'containerRight' | 'text'

const footerStyles: Record<FooterStylesProps, SxProps<Theme> | undefined> = {
  footer: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 'fit-content',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: palette.primary.dark,
    color: palette.primary.main,
    p: '20px 0',
    '& a': {
      transition: '0.2s',
      color: palette.primary.main,
      textDecorationColor: 'rgba(255, 255, 255, 0.6)',
      '&:hover': { textDecorationColor: palette.primary.main },
    },
    [down(850)]: {
      p: '40px 0',
      height: 'auto',
    },
  }),
  container: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    mb: '20px',
    [down(850)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
  containerLeft: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    maxHeight: 32,
    [down(850)]: {
      mb: '30px',
    },
  }),
  containerRight: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    width: 'min-content',
    ml: 'auto',
    [down(850)]: {
      flexDirection: 'column',
      ml: '0',
    },
  }),
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 15,
    fontWeight: 600,
    alignContent: 'center',
    whiteSpace: 'nowrap',
    m: '0 10px',
    '& a': {
      fontSize: 15,
      fontWeight: 600,
      transition: '0.2s',
      color: `${palette.primary.main}!important`,
      '&:hover': { textDecorationColor: `${palette.secondary.main}!important` },
      [down('sm')]: {
        fontSize: 16,
      },
    },
    [down('md')]: {
      textAlign: 'center',
    },
    [down(850)]: {
      m: '10px 0',
    },
    [down('sm')]: {
      fontSize: 16,
    },
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 13,
    fontWeight: 400,
    color: '#7083A1',
    padding: '7px 0',
  }),
}
