import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PropsAction } from './props.types'

const initialState: PropsAction = {}

export const propsSlice = createSlice({
  name: 'props',
  initialState,
  reducers: {
    setProps(state: PropsAction, action: PayloadAction<PropsAction>) {
      return { ...state, ...action }
    },
  },
  extraReducers: () => {},
})

export const propsActions = propsSlice.actions
export const propsReducer = propsSlice.reducer
