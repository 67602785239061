import { FC, JSX, useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Collapse, Grid } from '@mui/material'
import { Modal, TrackEvent, Loader } from '../../components'
import { useReduxSelector } from '../../hooks'
import { SignupForm } from './signup.form'
import { getSocialInfo } from '../../api'

export const signUpModalName = 'signupModal'

export const SignUpModal: FC = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [collapse, setCollapse] = useState<boolean>(true)

  const [token, setToken] = useState<string | null>(searchParams.get('token'))
  const [dataSocial, setDataSocial] = useState<any>(undefined)

  const { modal } = useReduxSelector((state) => state.modals)

  const { show = false } = modal[signUpModalName] || {}

  const onClose = () => {
    setCollapse(false)
    setSearchParams({})
  }
  const loadSocialInfo = useCallback((tokenSocial: string) => {
    getSocialInfo(tokenSocial).then((res) => {
      if (res.status === 1) {
        setDataSocial(res.data.info)
      }
    })
  }, [])

  useEffect(() => {
    if (!collapse) {
      setTimeout(() => setCollapse(true), 200)
    }
  }, [collapse])

  useEffect(() => {
    if (show && collapse) {
      if (token) loadSocialInfo(token)

      TrackEvent({
        eventCategory: 'Registration',
        eventAction: 'open_r_form',
      })
    }
  }, [show])

  return (
    <Modal open={show} title="Регистрация" name={signUpModalName} onClose={onClose}>
      <Collapse in={collapse}>
        {!token || (token && dataSocial) ? (
          <SignupForm email={dataSocial?.email} token={token} />
        ) : (
          <Grid container justifyContent="center" alignItems="center" height={300}>
            <Grid item>
              <Loader />
            </Grid>
          </Grid>
        )}
      </Collapse>
    </Modal>
  )
}
