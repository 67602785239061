import { FC, JSX } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup/dist/yup'
import { TextField, IconButton, InputAdornment } from '@mui/material'
import { Search } from '@mui/icons-material'
import { WinnerFormProps, FormData } from '../../../../types'
import { emailInputProps, validation } from '../../../../components'

const schema = validation({
  email: yup
    .string()
    .required()
    .transform((value) => value.replace(/\s|\(|\)|\-|\+7/g, ''))
    .matches(/^(9)\d{9}$/, 'Указан некорректный номер телефона'),
})

export const WinnersSearchForm: FC<WinnerFormProps> = ({
  resetEmail,
  onSubmitForm,
  onChangeReset,
  initListWinner,
}): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  })

  const onSubmit = handleSubmit(({ email }) => {
    if (resetEmail && initListWinner) onSubmitForm(email)
  })

  return (
    <form onSubmit={onSubmit} autoComplete="off" noValidate>
      <Controller
        name="email"
        defaultValue=""
        control={control}
        render={({ field: { onChange, value } }) => (
          <TextField
            fullWidth
            type="email"
            value={value}
            color="primary"
            variant="outlined"
            label="Поиск по номеру телефона"
            placeholder="Введите номер телефона"
            onChange={(event) => {
              onChange(event)
              onChangeReset(event)
            }}
            disabled={!initListWinner}
            helperText={<>{errors.email?.message}</>}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton style={{ width: 40, height: 40 }} type="submit">
                    <Search style={{ fontSize: 28, color: '#FFFFFF' }} />
                  </IconButton>
                </InputAdornment>
              ),
              inputProps: { ...(emailInputProps as any) },
            }}
          />
        )}
      />
    </form>
  )
}
