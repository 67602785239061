import { SxProps, Theme } from '@mui/material'
import { img } from '../../imgs'

type notFoundStylesProps = 'container' | 'content' | 'error' | 'text'

export const notFoundStyles: Record<notFoundStylesProps, SxProps<Theme> | undefined> = {
  container: ({ breakpoints: { down } }: Theme) => ({
    zIndex: 1,
    height: 'calc(100vh - 85px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
  }),
  content: ({ breakpoints: { down } }: Theme) => ({
    height: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    [down('md')]: {
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
  error: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 'fit-content',
    width: 'fit-content',
    color: palette.secondary.contrastText,
    fontSize: 100,
    fontWeight: 700,
    mr: 8,
    [down('md')]: {
      fontSize: '10vw',
      mb: 4,
      mr: 0,
    },
    [down('sm')]: {
      fontSize: '16.66vw',
      mb: 4,
    },
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    color: palette.primary.dark,
    [down('md')]: {
      alignItems: 'center',
      '& *': {
        textAlign: 'center',
      },
    },
  }),
}
