import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as ls from 'local-storage'
import { getInfoUser } from './auth.service'
import { IAuthState, PayloadInfoUser } from './auth.types'
import { Token } from '../../types'

const initialState: IAuthState = {
  data: undefined,
  auth: false,
  loading: true,
  error: undefined,
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout(state: IAuthState) {
      ls.remove(Token.PRIVATE)
      state.data = undefined
      state.auth = false
    },
    updateUser(state: IAuthState, action: PayloadAction<any>) {
      return { ...state, data: { ...state.data, ...action.payload } }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoUser.pending, (state: IAuthState) => {})
    builder.addCase(getInfoUser.fulfilled, (state: IAuthState, action: PayloadAction<{ data: PayloadInfoUser }>) => {
      state.data = {
        ...action.payload.data.user,
        address: action.payload.data.address,
        fivepost: action.payload.data.fivepost,
        person_need: action.payload.data.person_need,
      }
      state.auth = true
      state.loading = false
    })
    builder.addCase(getInfoUser.rejected, (state: IAuthState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.auth = false
      state.loading = false
    })
  },
})

export const authActions = authSlice.actions
export const authReducer = authSlice.reducer
