import { FC, JSX, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { logout as logoutService } from '../../api'
import { useReduxActions, useReduxSelector } from '../../hooks'

export const LogoutPage: FC = (): JSX.Element => {
  const { logout, setResetGame, setResetProfile } = useReduxActions()
  const { auth } = useReduxSelector((state) => state.auth)

  const navigate = useNavigate()

  useEffect(() => {
    if (auth) {
      logoutService().then(() => {
        logout()
        setResetGame()
        setResetProfile()
        navigate('../', { replace: true })
      })
    } else {
      navigate('../', { replace: true })
    }
  }, [auth])

  return <></>
}
