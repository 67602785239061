import { FC, JSX, useState, SyntheticEvent, useLayoutEffect } from 'react'
import {
  Box,
  Grid,
  Container,
  Accordion,
  useMediaQuery,
  AccordionSummary,
  AccordionDetails,
  Theme,
} from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import * as process from 'process'
import { Loader, PaginationCustom } from '../../../../components'
import { faqList } from '../../../../api'
import { FeedbackForm } from './feeback-form'
import { useReduxSelector } from '../../../../hooks'
import { faqStyles } from './faq.styles'

const ArrowFaq: FC = (): JSX.Element => {
  return (
    <Box sx={faqStyles.icon}>
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="16" viewBox="0 0 28 16" fill="none">
        <path d="M2 2L14 14L26 2" stroke="#000000" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Box>
  )
}

export const FaqSection: FC = (): JSX.Element => {
  const { REACT_APP_EMAIL: EMAIL } = process.env

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const { auth } = useReduxSelector((state) => state.auth)

  const [faq, setFaq] = useState<any[]>([])
  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(7)
  const [expanded, setExpanded] = useState<string | false>(false)
  const [loadedFaq, setLoadedFaq] = useState<boolean>(false)

  const handleChange = (event: any, value: number) => {
    setPage(value)
    handleChangeEx('')
  }

  const handleChangeEx = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  useLayoutEffect(() => {
    faqList().then((res) => {
      setFaq(res.data.faq)
      if (res.data.faq.length > 0) setLoadedFaq(true)
    })
  }, [])

  return (
    <Box sx={faqStyles.section}>
      <Container maxWidth="md" sx={{ p: { xs: '0px!important', sm: '0 20px!important' } }}>
        <Box sx={faqStyles.title}>Вопрос-ответ</Box>

        <Grid container alignItems="flex-start" justifyContent={md ? 'center' : 'space-around'}>
          <Grid item>
            <Box sx={faqStyles.faq}>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Box sx={faqStyles.accordion}>
                    {loadedFaq ? (
                      faq.slice(perPage * (page - 1), page * perPage).map((item, index) => {
                        const { title = '', text = '', id = '' } = item
                        return (
                          <Accordion
                            key={index}
                            square={false}
                            expanded={expanded === id}
                            onChange={handleChangeEx(id)}
                          >
                            <AccordionSummary expandIcon={<ArrowFaq />}>{title}</AccordionSummary>
                            <AccordionDetails>{HtmlReactParser(text.replace(/[\r\n]+/g, '<br/>'))}</AccordionDetails>
                          </Accordion>
                        )
                      })
                    ) : (
                      <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '55vh' }}>
                        <Grid item xs="auto">
                          <Loader />
                        </Grid>
                      </Grid>
                    )}
                  </Box>
                  {loadedFaq && (
                    <Box sx={faqStyles.pagination}>
                      <PaginationCustom count={Math.ceil(faq.length / perPage)} page={page} onChange={handleChange} />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
