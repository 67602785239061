import { FC, JSX } from 'react'
import { Box } from '@mui/material'

export const Loader: FC = (): JSX.Element => {
  return (
    <Box position="relative" display="flex" justifyContent="center" alignItems="center" width={60} height={60}>
      <Box className="loader" />
    </Box>
  )
}
