import logo from './new/logo.png'
import check from './profile/check.png'
import iconNotfound from './icon/icon-notfound.png'
import iconNotification from './icon/icon-notification.png'
import bannerBG from './banner/bg.png'
import bannerBG2 from './banner/bg2.png'
import bannerBG3 from './banner/bg3.png'
import bannerBGSm from './banner/bg-sm.png'
import bannerBGSm2 from './banner/bg-sm2.png'
import bannerTitle from './banner/banner_title.png'
import banner from './banner/banner.png'
import banner_sm from './banner/banner_sm.png'
import bannerSeparator from './banner/separator.png'
import bannerSeparator_2 from './banner/separator_2.png'
import sprinters from './banner/sprinters.png'
import rules_gifts from './banner/rules_gifts.png'
import bg_bg from './banner/bg_bg.png'
import prizesStage1 from './prizes/stage1.png'
import prizesStage2 from './prizes/stage2.png'
import prizesStage3 from './prizes/stage3.png'
import main_prize from './prizes/main_prize.png'
import prizesBG from './prizes/bg.png'
import prizes_top1 from './prizes/top1.png'
import prizes_top2 from './prizes/top2.png'
import prizes_top3 from './prizes/top3.png'
import prizes_qr from './prizes/qr.png'
import prizes_check_mark from './prizes/check.png'
import line_separate from './banner/line_separate.png'
import icon_forward from './winners/forward.png'
import icon_noalco1 from './winners/icon_noalco1.png'
import icon_noalco2 from './winners/icon_noalco2.png'
import winners_bg from './winners/bg.png'
import bottle1 from './winners/bottle1.png'
import bottle2 from './winners/bottle2.png'
import winners_ice from './winners/ice.png'
import profileGameBG from './profile/game/bg.png'
import profileGameRightBG from './profile/game/bg-right.png'
import rules_buy from './banner/rules_buy.png'
import rules_gain from './banner/rules_gain.png'
import rules_participate from './banner/rules_participate.png'
import zarya from './profile/game/zary.png'
import zaryaDoor from './profile/game/zary.door.png'
import zaryaOpenDoor from './profile/game/zary-open.door.png'
import leshij from './profile/game/leshy.png'
import leshijDoor from './profile/game/leshy.door.png'
import leshijOpenDoor from './profile/game/leshy-open.door.png'
import konek from './profile/game/konurk.png'
import konekDoor from './profile/game/konurk.door.png'
import konekOpenDoor from './profile/game/konurk-open.door.png'
import jaga from './profile/game/yaga.png'
import jagaDoor from './profile/game/yaga.door.png'
import jagaOpenDoor from './profile/game/yaga-open.door.png'
import ermak from './profile/game/ermak.png'
import ermakDoor from './profile/game/ermak.door.png'
import ermakOpenDoor from './profile/game/ermak-open.door.png'
import peresvet from './profile/game/peresvet.png'
import peresvetDoor from './profile/game/peresvet.door.png'
import peresvetOpenDoor from './profile/game/peresvet-open.door.png'
import topLeftStar from './modal/top-left.star.svg'
import topCenterStar from './modal/top-center.star.svg'
import topRightStar from './modal/top-right.star.svg'
import centerLeftStar from './modal/center-left.star.svg'
import centerRightStar from './modal/center-right.star.svg'
import bottomLeftStar from './modal/bottom-left.star.svg'
import bottomRightStar from './modal/bottom-right.star.svg'
import wa from './profile/info/wa.png'
import tg from './profile/info/tg.png'
import profile_bg from './profile/info/bg.png'
import checkpoint_1_off from './profile/info/checkpoint_1_off.png'
import checkpoint_1_on from './profile/info/checkpoint_1_on.png'
import checkpoint_2_off from './profile/info/checkpoint_2_off.png'
import checkpoint_2_on from './profile/info/checkpoint_2_on.png'
import checkpoint_3_off from './profile/info/checkpoint_3_off.png'
import checkpoint_3_on from './profile/info/checkpoint_3_on.png'
import checkpoint_4_off from './profile/info/main_off.png'
import checkpoint_4_on from './profile/info/main_on.png'
import checkpoint_question from './profile/info/question.png'
import qr from './banner/qr.png'
import product_1 from './prizes/product_1.png'
import product_2 from './prizes/product_2.png'
import product_3 from './prizes/product_3.png'
import product_4 from './prizes/product_4.png'
import product_card_1 from './prizes/product_card_1.png'
import product_card_2 from './prizes/product_card_2.png'
import product_card_2_2 from './prizes/product_card_2_2.png'
import product_card_3 from './prizes/product_card_3.png'
import logo_letters from './footer/logo.png'
import warning from './footer/warning.png'

export * from './svg'

export const img = {
  qr,
  wa,
  tg,
  zarya,
  logo,
  jaga,
  leshij,
  check,
  ermak,
  konek,
  peresvet,
  jagaDoor,
  bannerBG,
  bannerBG2,
  bannerBG3,
  rules_gifts,
  sprinters,
  bannerTitle,
  prizesBG,
  zaryaDoor,
  ermakDoor,
  leshijDoor,
  konekDoor,
  bannerBGSm,
  bannerBGSm2,
  topLeftStar,
  prizesStage1,
  peresvetDoor,
  prizesStage2,
  prizesStage3,
  main_prize,
  topRightStar,
  iconNotfound,
  jagaOpenDoor,
  profileGameBG,
  ermakOpenDoor,
  konekOpenDoor,
  topCenterStar,
  zaryaOpenDoor,
  leshijOpenDoor,
  centerLeftStar,
  bottomLeftStar,
  bottomRightStar,
  centerRightStar,
  iconNotification,
  peresvetOpenDoor,
  profileGameRightBG,
  rules_buy,
  rules_gain,
  rules_participate,
  prizes_top1,
  prizes_top2,
  prizes_top3,
  line_separate,
  icon_forward,
  icon_noalco1,
  icon_noalco2,
  winners_bg,
  bottle1,
  bottle2,
  winners_ice,
  checkpoint: {
    img1: { on: checkpoint_1_on, off: checkpoint_1_off },
    img2: { on: checkpoint_2_on, off: checkpoint_2_off },
    img3: { on: checkpoint_3_on, off: checkpoint_3_off },
    img4: { on: checkpoint_4_on, off: checkpoint_4_off },
  },
  checkpoint_question,
  banner,
  banner_sm,
  bannerSeparator,
  bannerSeparator_2,
  bg_bg,
  prizes_qr,
  prizes_check_mark,
  profile_bg,
  product_1,
  product_2,
  product_3,
  product_4,
  product_card_1,
  product_card_2,
  product_card_2_2,
  product_card_3,
  logo_letters,
  warning,
}
