import { FC, JSX } from 'react'
import { Box, Grid } from '@mui/material'
import { errorFallbackStyles } from './error-fallback.styles'
import { img } from '../../imgs'

export const ErrorFallbackPage: FC = (): JSX.Element => (
  <Box sx={errorFallbackStyles.page}>
    <Grid container justifyContent="center" alignItems="center" height="100%">
      <Grid item>
        <Grid container justifyContent="center">
          <Grid item>
            <Box>
              <img src={img.logo} alt="" />
            </Box>
          </Grid>
        </Grid>
        <Box width="100%" textAlign="center" mt={4} mb={2}>
          Что-то пошло не так...
        </Box>
        <Box width="100%" textAlign="center">
          На сайте проводятся технические работы.
          <br />В ближайшее время мы восстановим работу сайта.
        </Box>
      </Grid>
    </Grid>
  </Box>
)
