import { FC, JSX } from 'react'
import { Pagination, PaginationItem, PaginationRenderItemParams } from '@mui/material'
import { KeyboardArrowLeft as ArrowLeftIcon, KeyboardArrowRight as ArrowRightIcon } from '@mui/icons-material'
import { img } from '../../imgs'

interface IPagination {
  page: number
  count: number
  onChange: (event: any, value: number) => void
}

const RenderItem = (params: PaginationRenderItemParams) => (
  <PaginationItem components={{ previous: ArrowLeftIcon, next: ArrowRightIcon }} {...params} />
)

export const PaginationCustom: FC<IPagination> = ({ count = 1, page = 1, onChange = () => {} }): JSX.Element => (
  <Pagination page={page} count={count} variant="text" onChange={onChange} renderItem={RenderItem} />
)
