import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  getShopList,
  getBallList,
  getCheckList,
  getPrizeList,
  getRequestList,
  getBallBalance,
  getCheckBalance,
  getPrizeBalance,
  getRequestBalance,
} from '../../api'

export const getInfoBallBalance = createAsyncThunk('profile/ball/balance/get', async () => {
  return await getBallBalance()
})

export const getInfoBall = createAsyncThunk('profile/ball/list/get', async () => {
  return await getBallList()
})

export const getInfoRequestBalance = createAsyncThunk('profile/request/balance/get', async () => {
  return await getRequestBalance()
})

export const getInfoRequest = createAsyncThunk('profile/request/list/get', async () => {
  return await getRequestList()
})

export const getInfoDocsBalance = createAsyncThunk('profile/doc/balance/get', async () => {
  return await getCheckBalance()
})

export const getInfoDocs = createAsyncThunk('profile/doc/list/get', async () => {
  return await getCheckList()
})

export const getInfoPrizesBalance = createAsyncThunk('profile/prize/balance/get', async () => {
  return await getPrizeBalance()
})

export const getInfoPrizes = createAsyncThunk('profile/prize/list/get', async () => {
  return await getPrizeList()
})

export const getInfoShop = createAsyncThunk('profile/shop/list/get', async () => {
  return await getShopList()
})
