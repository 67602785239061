import { FC, JSX, useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Grid } from '@mui/material'
import moment from 'moment'
import { DateInput, decimalInputProps, NumberInput, regexp, TimeInput, validation } from '../form-control'
import { checkRegister } from '../../api'
import { TrackEvent } from '../GA'
import { Snackbar } from '../snackbar'
import { SubmitButton } from '../submit-button'
import { FormProps } from '../../types'
import { useReduxActions } from '../../hooks'

interface RegisterCheckFormProps {
  scan?: boolean
  autoSave: boolean
  uploadAnswer: any
  onSuccess(): void
  prizeId?: number | null
}

const schema = validation({
  check_date: yup
    .string()
    .required()
    .transform((value) => moment(value).format('DD.MM.YYYY'))
    .matches(regexp.date, { message: 'Неверный формат даты', excludeEmptyString: true }),
  check_time: yup
    .string()
    .required()
    .transform((value) => moment(value).format('HH:mm'))
    .matches(regexp.time, { message: 'Неверный формат времени', excludeEmptyString: true }),
  check_summa: yup
    .string()
    .matches(/^\d+[,.]\d{2}$/i, { message: 'Введите сумму с копейками', excludeEmptyString: true })
    .required(),
  check_fn: yup
    .string()
    .required()
    .matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true })
    .length(16, 'ФН содержит 16 символов'),
  check_fd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
  check_fpd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
})

export const RegisterCheckForm: FC<RegisterCheckFormProps> = ({
  autoSave,
  onSuccess,
  uploadAnswer,
  scan = false,
  prizeId = null,
}): JSX.Element => {
  const { setAlert } = useReduxActions()

  const { upload_file, qr } = uploadAnswer || {}
  const { i: extFd, fp: extFpd, fn: extFn, t, s: extSumma } = qr || {}

  const extDateLoad = t ? moment(t).toDate() : null
  const extTimeLoad = t ? moment(t).toDate() : null

  const extDateOnline = t ? moment(t).toDate() : null
  const extTimeOnline = t ? moment(t).toDate() : null

  const defaultValues = {
    check_date: scan ? extDateOnline : extDateLoad,
    check_time: scan ? extTimeOnline : extTimeLoad,
    check_summa: extSumma,
    check_fd: extFd,
    check_fn: extFn,
    check_fpd: extFpd,
  }

  const hookForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: undefined,
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const onSubmitForm = async (data: any) => {
    const extendData = {
      check_date: moment(data.check_date, moment.defaultFormat).format('YYYY-MM-DD'),
      upload_file,
    }

    setFormProps({ ...formProps, processed: true })

    checkRegister({ ...data, ...extendData })
      .then(({ status = 0, message: messages }) => {
        setFormProps({ ...formProps, processed: false })
        if (status === 1) onSuccess()
        else {
          onSuccess()
        }
        setAlert({ alert: { message: messages, onClose: () => {} } })
        TrackEvent({
          eventCategory: 'Check',
          eventAction: 'register_check',
          eventLabel: 'success',
        })
      })
      .catch(() => {
        setFormProps({
          ...formProps,
          processed: false,
          snackbar: { ...formProps.snackbar, message: 'Ошибка сервера. Попробуйте позже' },
        })

        TrackEvent({
          eventCategory: 'Check',
          eventAction: 'register_check',
          eventLabel: 'unsuccess',
          eventContext: 'Ошибка сервера. Попробуйте позже',
        })
      })
      .finally(() => {
        setFormProps({ ...formProps, processed: false })
      })
  }

  const autoSubmit = useCallback(() => {
    hookForm.handleSubmit(onSubmitForm)()
  }, [])

  useEffect(() => {
    if (autoSave) {
      autoSubmit()
    }
  }, [autoSave])

  return (
    <Grid hidden={autoSave}>
      <form onSubmit={hookForm.handleSubmit(onSubmitForm)} noValidate>
        <Box py={2}>
          <Grid container direction="row" justifyContent="center" spacing={1}>
            <Grid item xs={11}>
              <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <DateInput
                      form={hookForm}
                      name="check_date"
                      label="Дата чека"
                      variant="outlined"
                      disabled={formProps.processed}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <TimeInput
                      form={hookForm}
                      name="check_time"
                      variant="outlined"
                      label="Время чека"
                      disabled={formProps.processed}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <NumberInput
                      form={hookForm}
                      variant="outlined"
                      name="check_summa"
                      label="Сумма чека"
                      placeholder="Введите сумму"
                      disabled={formProps.processed}
                      inputProps={decimalInputProps}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <NumberInput
                      label="ФН"
                      form={hookForm}
                      name="check_fn"
                      variant="outlined"
                      placeholder="Введите ФН"
                      inputProps={{ maxLength: 16 }}
                      disabled={formProps.processed}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <NumberInput
                      label="ФД"
                      name="check_fd"
                      form={hookForm}
                      variant="outlined"
                      placeholder="Введите ФД"
                      inputProps={{ maxLength: 10 }}
                      disabled={formProps.processed}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <Box width="100%" height={65}>
                    <NumberInput
                      label="ФПД"
                      form={hookForm}
                      name="check_fpd"
                      variant="outlined"
                      placeholder="Введите ФПД"
                      inputProps={{ maxLength: 10 }}
                      disabled={formProps.processed}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Snackbar {...formProps.snackbar} variant="error" />
            </Grid>
          </Grid>
          <Box width="100%" maxWidth={260} mx="auto" mt={3} mb={2}>
            <SubmitButton
              fullWidth
              title="Зарегистрировать"
              disabled={formProps.processed}
              processed={formProps.processed}
            />
          </Box>
        </Box>
      </form>
    </Grid>
  )
}
