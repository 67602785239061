import { FC, JSX, useState, useEffect } from 'react'
import { AppBar, Box, Button, Container, Fade, Grid, Hidden, Theme, useMediaQuery, Grow } from '@mui/material'
import { AgeCheck } from '../../components'
import { startStyles } from './start.styles'
import { img, SloganSvg } from '../../imgs'

export const StartPage: FC = (): JSX.Element => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  return (
    <Fade in timeout={{ enter: 500 }}>
      <Box sx={startStyles.page} />
    </Fade>
  )
}
