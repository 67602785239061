import { SxProps, Theme } from '@mui/material'

type InfoUserStylesProps =
  | 'section'
  | 'content'
  | 'infoBox'
  | 'infoCheckBox'
  | 'infoTitle'
  | 'infoText'
  | 'separator'
  | 'gameInfo'
  | 'gameInfoTitle'
  | 'gameInfoText'
  | 'boxHolder'

export const infoUserStyles: Record<InfoUserStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 20,
    maxWidth: '1100px!important',
    [down('md')]: {},
    [down('sm')]: {
      fontSize: 17,
    },
  }),
  content: ({ breakpoints: { down } }: Theme) => ({
    p: '0 !important',
    maxWidth: '800px !important',
    [down(350)]: {
      p: '0 !important',
    },
  }),
  infoBox: ({ breakpoints: { down } }: Theme) => ({
    m: '0px auto',
    bgcolor: '#FFFFFF',
    borderRadius: '28px',
    p: '24px 40px',
    textAlign: 'center',
    [down('md')]: {
      height: 'auto',
      p: '20px',
      mt: '10px',
    },
    [down(460)]: {
      width: '100%',
    },
  }),
  boxHolder: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: 'calc(50% - 20px)',
    [down('md')]: {
      boxSizing: 'border-box',
      width: '100%',
      maxWidth: '340px!important',
    },
  }),
  infoCheckBox: ({ breakpoints: { down } }: Theme) => ({
    width: 1,
    bgcolor: '#FFFFFF',
    borderRadius: '28px',
    p: '40px 24px',
    fontSize: 22,
    fontWeight: 400,
    [down('md')]: {
      m: '0 auto',
      width: '100%',
    },
  }),
  infoTitle: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    fontWeight: 800,
    textTransform: 'uppercase',
    color: palette.primary.dark,
    [down('md')]: { textAlign: 'center', fontSize: 20 },
    [down('sm')]: { fontSize: 18 },
  }),
  infoText: ({ breakpoints: { down }, palette }: Theme) => ({
    mt: '20px',
    fontSize: 16,
    fontWeight: 400,
    color: palette.primary.dark,
    [down('md')]: { textAlign: 'center' },
    [down('sm')]: {
      fontSize: 15,
    },
  }),
  separator: ({ breakpoints: { down } }: Theme) => ({
    height: 290,
    borderLeft: '3px dotted rgba(255, 255, 255, 0.2)',
    [down('md')]: {
      width: 420,
      m: '30px 0',
      height: 'auto',
      borderTop: '3px dotted rgba(255, 255, 255, 0.2)',
    },
    [down('sm')]: {
      m: '25px 0',
      borderTop: '3px dotted rgba(255, 255, 255, 0.2)',
    },
    [down(460)]: {
      width: '85vw',
    },
  }),
  gameInfo: ({ breakpoints: { down } }: Theme) => ({
    pb: '20px',
  }),
  gameInfoTitle: ({ breakpoints: { down } }: Theme) => ({
    m: '0 auto',
    fontSize: 34,
    maxWidth: 670,
    width: '100%',
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
    [down('md')]: { fontSize: 30 },
    [down('sm')]: { fontSize: 24, maxWidth: 460, width: '100%' },
  }),
  gameInfoText: ({ breakpoints: { down } }: Theme) => ({
    mt: '35px',
    fontSize: 24,
    fontWeight: 400,
    textAlign: 'center',
    [down('md')]: { fontSize: 22, mt: '25px' },
    [down('sm')]: { fontSize: 17, maxWidth: 300, width: '100%', m: '15px auto 0' },
  }),
}
