/* eslint-disable max-len */
import { FC, JSX } from 'react'

interface IIcon {
  width?: number
  height?: number
}

export const UserIcon: FC<IIcon> = ({ width = 34, height = 34 }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 68 68" fill="none">
    <path
      d="M52 61V54.3333C52 51.858 51.0518 49.484 49.364 47.7337C47.6761 45.9833 45.3869 45 43 45H25C22.6131 45 20.3239 45.9833 18.636 47.7337C16.9482 49.484 16 51.858 16 54.3333V61"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M34 36C39.5228 36 44 31.5228 44 26C44 20.4772 39.5228 16 34 16C28.4772 16 24 20.4772 24 26C24 31.5228 28.4772 36 34 36Z"
      stroke="white"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="2" y="2" width="64" height="64" rx="32" stroke="white" strokeWidth="4" />
  </svg>
)
