import { FC, JSX } from 'react'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { numericInputProps } from './props-autocomplete'
import { GenericTextInput } from './generic-text-input'
import { InputType } from '../../types'

type NumberProps = {
  name: string
  label?: string
  inputProps?: any
  type?: InputType
  disabled?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  options?: RegisterOptions
  variant?: 'standard' | 'filled' | 'outlined'
}

export const NumberInput: FC<NumberProps> = ({
  form,
  name,
  options,
  inputProps,
  label = 'Код',
  type = 'text',
  disabled = false,
  placeholder = undefined,
  variant = 'outlined',
}): JSX.Element => {
  const { register, formState } = form

  return (
    <GenericTextInput
      type={type}
      label={label}
      variant={variant}
      disabled={disabled}
      placeholder={placeholder}
      helperText={formState.errors[name]?.message}
      register={register(name, { required: true, disabled, ...options })}
      InputProps={{ inputProps: { ...(numericInputProps as any), ...inputProps } }}
    />
  )
}
