/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, JSX, useState, MouseEvent } from 'react'
import { Box, Grid, Link, Popover, Fade, Theme, useMediaQuery, SxProps, Button, IconButton } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { MyLink } from '../my-link'
import { User } from '../../types'
import { UserIcon } from '../layout/header/icons'

interface IPopoverAuth {
  auth: boolean
  userData: User | undefined
}

export const PopoverAuth: FC<IPopoverAuth> = ({ userData, auth }): JSX.Element => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleClickLk = (event: MouseEvent<HTMLElement>) => {
    if (pathname === '/profile') {
      setAnchorEl(anchorEl ? null : event.currentTarget)
    } else {
      if (auth) navigate('../profile')
      else navigate({ pathname: '../', search: 'signin' })
      window.scroll(0, 0)
    }
  }

  const handleClose = () => setAnchorEl(null)

  const handleClickExit = () => {
    handleClose()
    navigate('../logout')
  }

  return (
    <>
      {md ? (
        <IconButton color="primary" onClick={handleClickLk}>
          <UserIcon width={sm ? 34 : 51} height={sm ? 34 : 51} />
        </IconButton>
      ) : (
        <Link component={MyLink} to="/profile" sx={popperStyles.link} onClick={handleClickLk}>
          <Box sx={popperStyles.name}>
            <Box id="text" alignContent="center">
              {userData?.firstname}
            </Box>
          </Box>
        </Link>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={popperStyles.popover}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Fade in={open} timeout={250}>
          <Box pt={2} sx={popperStyles.popoverBody}>
            <Box sx={popperStyles.popoverInfo}>
              <Grid container spacing={1} sx={popperStyles.holder}>
                <Grid item sx={popperStyles.text} style={{ color: '#00051F80' }}>
                  Имя:
                </Grid>
                <Grid item sx={popperStyles.text} style={{ color: '#00051F80' }}>
                  Телефон:
                </Grid>
                <Grid item sx={popperStyles.text} style={{ color: '#00051F80' }}>
                  E-mail:
                </Grid>
              </Grid>
              <Grid container spacing={1} ml={2} sx={popperStyles.holder}>
                <Grid item sx={popperStyles.text}>
                  {userData?.firstname}
                </Grid>
                <Grid item sx={popperStyles.text}>
                  {userData?.phone_format}
                </Grid>
                <Grid item sx={popperStyles.text}>
                  {userData?.email}
                </Grid>
              </Grid>
            </Box>
            <Box sx={popperStyles.btn_holder}>
              <Box onClick={handleClickExit} sx={popperStyles.linkLogout}>
                Выйти из аккаунта
              </Box>
            </Box>
          </Box>
        </Fade>
      </Popover>
    </>
  )
}

type PopperStylesProps =
  | 'popoverBody'
  | 'popover'
  | 'popoverInfo'
  | 'icon'
  | 'label'
  | 'text'
  | 'link'
  | 'line'
  | 'subscribe'
  | 'linkLogout'
  | 'name'
  | 'btn'
  | 'holder'
  | 'btn_holder'

const popperStyles: Record<PopperStylesProps, SxProps<Theme> | undefined> = {
  popoverBody: ({ palette }: Theme) => ({
    textTransform: 'none',
  }),
  popover: ({ breakpoints: { down }, palette }: Theme) => ({
    mt: '10px',
  }),
  popoverInfo: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'left',
    padding: '5px 20px',
  }),
  icon: ({ breakpoints: { down } }: Theme) => ({
    [down('md')]: {
      width: 34,
    },
  }),
  label: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 17,
    fontWeight: 500,
    color: palette.primary.main,
  }),
  holder: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    flexDirection: 'column',
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textTransform: 'none',
    display: 'inline-block',
    textOverflow: 'ellipsis',
    color: palette.primary.dark,
  }),
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    height: '100%',
    fontSize: '14px !important',
    fontWeight: 600,
    display: 'flex',
    cursor: 'pointer',
    transition: '0.3s',
    position: 'relative',
    textTransform: 'uppercase',
    textDecoration: 'none!important',
    color: `#FFFFFF!important`,
    boxSizing: 'border-box',
    alignItems: 'center',
    borderRadius: '19px',
    p: '0 15px',
    bgcolor: palette.secondary.contrastText,
    '-webkit-tap-highlight-color': 'transparent',
    '&:hover': {
      textDecoration: 'none',
      bgcolor: `#E72AB0!important`,
    },
    '&.active': {
      alignContent: 'center',
      color: '#FFFFFF!important',
      mb: '-15px',
    },
    [down('lg')]: {
      fontSize: 17,
    },
  }),
  line: ({ breakpoints: { down }, palette }: Theme) => ({
    height: 2,
    width: '100%',
    margin: '5px 0 2px',
    backgroundColor: 'rgba(153, 0, 20, 0.3)',
  }),
  subscribe: ({ breakpoints: { down }, palette, font }: Theme) => ({
    '& .MuiTypography-root': {
      color: '#1A1A18!important',
      fontSize: '12px!important',
      fontWeight: '400!important',
      lineHeight: '1.3!important',
      fontFamily: `${font.secondary}!important`,
    },
  }),
  btn_holder: ({ breakpoints: { down }, palette }: Theme) => ({
    borderTop: '1px solid #CFCFCF',
    margin: '0 20px',
  }),
  linkLogout: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    minWidth: 141,
    minHeight: 38,
    color: palette.secondary.contrastText,
    padding: '10px 0 0 5px',
    transition: '0.1s',
    cursor: 'pointer',
    '&:hover': {
      color: '#E72AB0',
    },
  }),
  name: () => ({
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    width: '100%',
    '#text': {
      maxWidth: 'calc(100vw - 900px)',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    '& svg': {
      transform: 'scale(1.3)',
      ml: '10px',
    },
  }),
  btn: ({ breakpoints: { down, up }, palette }: Theme) => ({
    fontSize: 18,
    minWidth: 182,
    minHeight: 50,
    fontWeight: 500,
    textTransform: 'none',
    color: `${palette.success.main}!important`,
    [down('lg')]: {
      fontSize: 17,
      minHeight: 46,
    },
    [up('md')]: {
      '&:hover': {
        color: `${palette.primary.main}!important`,
        bgcolor: palette.primary.dark,
      },
    },
  }),
}
