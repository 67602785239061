import { SxProps, Theme } from '@mui/material'
import { img } from '../../../../imgs'

type faqStylesProps = 'section' | 'title' | 'faq' | 'accordion' | 'icon' | 'pagination' | 'form' | 'titleForm'

export const faqStyles: Record<faqStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down }, palette }: Theme) => ({
    zIndex: 1,
    mt: '-10px',
    height: '100%',
    // p: '18.5vw 0 0',
    p: '100px 0 300px',
    position: 'relative',
    background: `url(${img.profile_bg}) bottom center no-repeat, linear-gradient(#CFEAF9, #FFFFFF)`,
    backgroundSize: '100% auto, auto',
    [down(1350)]: {
      p: '100px 0 22vw',
    },
    [down('md')]: {
      p: '9.09vw 0 22vw',
    },
    [down('sm')]: {
      p: '6vw 0 20vw',
    },
  }),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    mb: '50px',
    fontSize: 50,
    fontWeight: 800,
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.primary.dark,
    [down('md')]: {
      mb: '40px',
      fontSize: 36,
    },
    [down('sm')]: {
      mb: '25px',
      fontSize: 30,
    },
  }),
  faq: ({ breakpoints: { down }, palette }: Theme) => ({
    [down('sm')]: {
      p: '0 20px',
    },
  }),
  accordion: ({ breakpoints: { down }, palette }: Theme) => ({
    mt: '-5px',
    mb: '30px',
    [down('md')]: {
      mb: '25px',
    },
    [down('sm')]: {
      mb: '20px',
    },
  }),
  icon: ({ breakpoints: { down } }: Theme) => ({
    width: 28,
    height: 28,
    [down('md')]: {
      width: 18,
      height: 18,
    },
    '& svg': {
      width: 28,
      height: 28,
      [down('md')]: {
        width: 18,
        height: 18,
      },
    },
  }),
  pagination: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
  }),
  form: ({ breakpoints: { down }, palette }: Theme) => ({
    p: '30px',
    borderRadius: '40px',
    backgroundColor: palette.primary.main,
    [down('lg')]: {
      p: '30px 20px',
    },
    [down('md')]: {
      mt: '40px',
    },
    [down('sm')]: {
      mt: '30px',
    },
  }),
  titleForm: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 25,
    p: '0 0 30px',
    fontWeight: 500,
    color: palette.primary.dark,
    [down('md')]: {
      p: '0 0 25px',
      textAlign: 'center',
    },
    [down('sm')]: {
      fontSize: 22,
      p: '0 0 20px',
    },
    [down(350)]: {
      fontSize: 20,
    },
  }),
}
