import { SxProps, Theme } from '@mui/material'

type InfoProfileStylesProps = 'section' | 'tabs' | 'tabItem' | 'text'

export const infoProfileStyles: Record<InfoProfileStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    maxWidth: '800px!important',
    margin: '0 auto',
    boxSizing: 'border-box',
    pb: '250px',
    '& > div': {
      bgcolor: '#FFFFFF',
      borderRadius: '28px',
      p: '20px',
    },
    [down('md')]: {
      margin: '0 auto',
    },
    [down('sm')]: {
      pb: '100px',
    },
  }),
  tabs: ({ breakpoints: { down } }: Theme) => ({
    width: 500,
    [down('sm')]: {
      mb: '-30px',
    },
    [down(540)]: { width: '100%' },
  }),
  tabItem: ({ breakpoints: { down } }: Theme) => ({
    [down('sm')]: {},
  }),
  text: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 22,
    fontWeight: 700,
    color: '#B0A59D',
    textAlign: 'center',
    p: '50px 25px 150px',
    textTransform: 'uppercase',
    [down('sm')]: { fontSize: 16, p: '70px 25px' },
  }),
}
