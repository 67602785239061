import { FC, JSX, useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, Collapse, Grid } from '@mui/material'
import * as process from 'process'
import { Loader, Modal, TrackEvent } from '../../components'
import { useReduxDispatch, useReduxSelector } from '../../hooks'
import { SigninForm } from './signin.form'
import { socialAuth } from '../../api'
import { getInfoUser } from '../../store/auth/auth.service'

export const signInModalName = 'signinModal'

export const SignInModal: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const dispatch = useReduxDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [collapse, setCollapse] = useState<boolean>(true)
  const [processedAuthSocial, setProcessedAuthSocial] = useState<boolean>(false)

  const { REACT_APP_VERSION: VERSION } = process.env

  const [token, setToken] = useState<string | null>(searchParams.get('token'))
  const [message, setMessage] = useState<string | undefined>(undefined)

  const { modal } = useReduxSelector((state) => state.modals)
  const { show = false } = modal[signInModalName] || {}

  const authSocial = useCallback((tokenSocial: string) => {
    setProcessedAuthSocial(true)
    socialAuth({ token: tokenSocial })
      .then((res) => {
        if (res.status === 1) {
          dispatch(getInfoUser()).then(() => {
            setToken(null)
            setProcessedAuthSocial(false)
            navigate('../profile')
          })
        }
      })
      .catch((err) => {
        setMessage(err.response.data.message)
      })
  }, [])

  const onClose = () => {
    setCollapse(false)
    setSearchParams({})
  }

  useEffect(() => {
    if (!collapse) {
      setTimeout(() => setCollapse(true), 200)
    }
  }, [collapse])

  useEffect(() => {
    if (show && collapse) {
      if (token) authSocial(token)
      TrackEvent({ eventCategory: 'Authorization', eventAction: 'open_a_form' })
    }
  }, [show])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Modal open={show} title="Авторизация" name={signInModalName} onClose={onClose}>
      <Collapse in={collapse}>
        {!processedAuthSocial ? (
          <SigninForm clearToken={() => setToken(null)} />
        ) : (
          <Grid container justifyContent="center" alignItems="center" minHeight={300}>
            <Grid item>
              <>{message ? <SigninForm message={message} clearToken={() => setToken(null)} /> : <Loader />}</>
            </Grid>
          </Grid>
        )}
        <Box sx={{ display: 'none' }}>{VERSION}</Box>
      </Collapse>
    </Modal>
  )
}
