import createPalette from '@mui/material/styles/createPalette'

const palette = createPalette({
  mode: 'dark',

  primary: {
    main: '#FFFFFF',
    dark: '#051F4A',
    contrastText: '#4F0400',
  },
  secondary: {
    main: '#000000',
    dark: '#192C09',
    contrastText: '#AB0F7E',
  },

  error: { main: '#E51523' },
  warning: { main: '#E75415' },
  info: { main: '#123A6B' },
  success: { main: '#2FC221' },

  background: {
    paper: '#fff',
    default: '#fff',
  },

  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF',
    disabled: 'rgba(0, 0, 0, .2)',
  },

  action: {
    active: '#2FC221',
    hover: '#2FC221',
    focus: '#2FC221',
    selected: '#2FC221',
    disabled: 'rgba(0, 0, 0, .4)',
    disabledBackground: '#C9C9C9',
  },

  divider: 'rgba(0, 0, 0, .2)',
})

export default palette
