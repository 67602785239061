import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  getInfoBall,
  getInfoDocs,
  getInfoPrizes,
  getInfoRequest,
  getInfoBallBalance,
  getInfoDocsBalance,
  getInfoPrizesBalance,
  getInfoRequestBalance,
  getInfoShop,
} from './profile.service'
import { IProfileState } from './profile.types'
import { BallBalance, Balls, Checkpoint, Docs, Prizes } from '../../types'

const initialState: IProfileState = {
  data: {
    balls: { total: { all: 0, balance: 0, used: 0 }, list: null },
    docs: { total: 0, list: null },
    prizes: { total: 0, list: null },
    checkpoints: { list: null },
  },
  loading: false,
  error: undefined,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setResetProfile(state: IProfileState) {
      return { ...state, ...initialState }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInfoBallBalance.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoBallBalance.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: BallBalance }>) => {
        state.data = { ...state.data, balls: { ...state.data?.balls, total: { ...action.payload.data } } }
        state.loading = false
      }
    )
    builder.addCase(getInfoBallBalance.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoRequest.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoRequest.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { request: Checkpoint[] } }>) => {
        state.data = { ...state.data, checkpoints: { ...state.data?.checkpoints, list: action.payload.data.request } }
        state.loading = false
      }
    )
    builder.addCase(getInfoRequest.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoBall.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoBall.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { history: Balls[] } }>) => {
        state.data = { ...state.data, balls: { ...state.data?.balls, list: action.payload.data.history } }
        state.loading = false
      }
    )
    builder.addCase(getInfoBall.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoDocsBalance.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoDocsBalance.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { total: number } }>) => {
        state.data = { ...state.data, docs: { ...state.data?.docs, total: action.payload.data.total } }
        state.loading = false
      }
    )
    builder.addCase(getInfoDocsBalance.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoDocs.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoDocs.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { docs: Docs[] } }>) => {
        state.data = { ...state.data, docs: { ...state.data?.docs, list: action.payload.data.docs } }
        state.loading = false
      }
    )
    builder.addCase(getInfoDocs.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoPrizesBalance.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoPrizesBalance.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { balance: number } }>) => {
        state.data = { ...state.data, prizes: { ...state.data?.prizes, total: action.payload.data.balance } }
        state.loading = false
      }
    )
    builder.addCase(getInfoPrizesBalance.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })

    /*------------------------------*/

    builder.addCase(getInfoPrizes.pending, (state: IProfileState) => {
      state.loading = true
    })
    builder.addCase(
      getInfoPrizes.fulfilled,
      (state: IProfileState, action: PayloadAction<{ data: { prizes: Prizes[] } }>) => {
        state.data = { ...state.data, prizes: { ...state.data?.prizes, list: action.payload.data.prizes } }
        state.loading = false
      }
    )
    builder.addCase(getInfoPrizes.rejected, (state: IProfileState, action: PayloadAction<any>) => {
      state.error = action.payload
      state.loading = false
    })
  },
})

export const profileActions = profileSlice.actions
export const profileReducer = profileSlice.reducer
