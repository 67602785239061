import { FC, JSX, MutableRefObject, useState, useEffect, useCallback } from 'react'
import { Box, Button, Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import HtmlReactParser from 'html-react-parser'
import QRCode from 'react-qr-code'
import Slider from 'react-slick'
import 'react-multi-carousel/lib/styles.css'
import Carousel from 'react-multi-carousel'
import { productsStyles } from './products.styles'
import { img, SloganSvg } from '../../../../imgs'
import { useDetectDevice, useReduxSelector } from '../../../../hooks'
import { DetectDevice } from '../../../../types'
import { SigninForm } from '../../../../modals/signin/signin.form'
import { SignupForm } from '../../../../modals/signup/signup.form'
import { getSocialInfo } from '../../../../api'

const responsive = {
  desktop: {
    breakpoint: { max: 10000, min: 1100 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1100, min: 700 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 700, min: 0 },
    items: 1,
  },
}

type Card = {
  id: number
  url: string
  text: any
  title: any
  href: string
}

const Card: FC<Card> = ({ id, url, title, text, href }) => {
  return (
    <Grid key={`prizes-${id}`} item>
      <Box
        sx={productsStyles.card}
        style={
          id === 2
            ? {
                backgroundColor: '#13174A',
                width: '33.33vw',
                maxWidth: '480px',
                height: '36.8vw',
                maxHeight: '530px',
                padding: '0',
                marginTop: '-30px',
              }
            : {}
        }
      >
        <Box sx={productsStyles.img}>
          <img id={`stage-${id}`} src={id === 2 ? img.product_card_2_2 : url} alt="" />
        </Box>
        <Box
          sx={productsStyles.titleCard}
          style={id === 2 ? { color: '#FFFFFF', marginTop: '0px', padding: '0 24px' } : {}}
        >
          {title}
        </Box>
        {text && (
          <Box sx={productsStyles.textCard} style={id === 2 ? { color: '#FFFFFF', padding: '0 24px' } : {}}>
            {text}
          </Box>
        )}
        <Box
          component="a"
          sx={productsStyles.more}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={id === 2 ? { color: '#FFFFFF', padding: '0 24px' } : {}}
        >
          Подробнее -{'>'}
        </Box>
      </Box>
    </Grid>
  )
}

const Card2: FC<Card> = ({ id, url, title, text, href }) => {
  return (
    <Grid key={`prizes-${id}`} item>
      <Box
        sx={productsStyles.card}
        style={
          id === 2
            ? {
                backgroundColor: '#13174A',
                padding: '0',
              }
            : {}
        }
      >
        <Box
          sx={productsStyles.img}
          style={
            id === 2
              ? {
                  width: '100%',
                  maxWidth: '100%',
                  marginBottom: '-20px',
                }
              : {}
          }
        >
          <img id={`stage-${id}`} src={id === 2 ? img.product_card_2_2 : url} alt="" />
        </Box>
        <Box
          sx={productsStyles.titleCard}
          style={id === 2 ? { color: '#FFFFFF', marginTop: '0px', padding: '0 24px' } : {}}
        >
          {title}
        </Box>
        {text && (
          <Box sx={productsStyles.textCard} style={id === 2 ? { color: '#FFFFFF', padding: '0 24px' } : {}}>
            {text}
          </Box>
        )}
        <Box
          component="a"
          sx={productsStyles.more}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
          style={id === 2 ? { color: '#FFFFFF', padding: '0 24px' } : {}}
        >
          Подробнее -{'>'}
        </Box>
      </Box>
    </Grid>
  )
}

const custom_pag = (i: any) => {
  return (
    <div className="ts-slick__dots--custom">
      <div className="loading" />
    </div>
  )
}
const custom_dots = (dots: any) => {
  return <ul>{dots}</ul>
}

export const Products: FC = (): JSX.Element => {
  const navigate = useNavigate()
  const detectDevice = useDetectDevice()
  const [searchParams, setSearchParams] = useSearchParams()
  const [dataSocial, setDataSocial] = useState<any>(undefined)
  const [adaptiveOpacity, setAdaptiveOpacity] = useState<boolean>(true)
  const [loginRegistrationSwitch, setLoginRegistrationSwitch] = useState(false)
  const [loginRegistrationSection, setLoginRegistrationSection] = useState(false)

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const [token, setToken] = useState<string | null>(searchParams.get('token'))

  const loadSocialInfo = useCallback((tokenSocial: string) => {
    getSocialInfo(tokenSocial).then((res) => {
      if (res.status === 1) {
        setDataSocial(res.data.info)
      }
    })
  }, [])

  const onChangeStatus = (e: any) => {
    const button_id = e.target.id
    if (button_id === '1' && loginRegistrationSwitch === true) {
      setLoginRegistrationSwitch(false)
      setAdaptiveOpacity(false)
      setTimeout(() => {
        setAdaptiveOpacity(true)
        setLoginRegistrationSection(false)
      }, 200)
    } else if (button_id === '2' && loginRegistrationSwitch === false) {
      setLoginRegistrationSwitch(true)
      setAdaptiveOpacity(false)
      setTimeout(() => {
        setAdaptiveOpacity(true)
        setLoginRegistrationSection(true)
      }, 200)
    }
  }

  const settings = {
    customPaging: custom_pag,
    speed: 300,
    dots: true,
    appendDots: custom_dots,
    infinite: true,
    easing: 'cubic',
    slidesToShow: 1,
    initialSlide: 0,
    slidesToScroll: 1,
    className: 'custom-slider',
    arrows: false,
  }

  return (
    <Box sx={productsStyles.section}>
      <Container>
        <Box sx={productsStyles.title}>
          КОНТАКТНЫЕ ЛИНЗЫ ACUVUE
          <Box
            component="span"
            fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
            style={{ verticalAlign: 'super' }}
          >
            ®
          </Box>
        </Box>
        <Box display={{ xs: 'none', md: 'block' }}>
          <Container maxWidth="lg">
            <Grid container justifyContent={{ xs: 'center', sm: 'space-around' }}>
              {products.map((k: Card) => (
                <Card {...k} />
              ))}
            </Grid>
          </Container>
        </Box>
        <Box display={{ xs: 'block', md: 'none' }}>
          <Slider {...settings}> {products && products.map((k: Card) => <Card2 {...k} />)}</Slider>
        </Box>
        <Box style={{ marginTop: '80px' }}>
          <Carousel responsive={responsive} infinite>
            <Box sx={productsStyles.carouselItem}>
              <Box component="span" style={{ textAlign: 'center' }}>
                семейство OASYS
                <Box component="span" style={{ fontSize: '14px', verticalAlign: 'super' }}>
                  7
                </Box>{' '}
                (однодневные)
              </Box>
              <Box component="img" src={img.product_1} alt="" />
            </Box>
            <Box sx={productsStyles.carouselItem}>
              <Box component="span" style={{ textAlign: 'center' }}>
                семейство OASYS
                <Box component="span" style={{ fontSize: '14px', verticalAlign: 'super' }}>
                  7
                </Box>{' '}
                MAX (однодневные)
              </Box>
              <Box component="img" src={img.product_2} alt="" />
            </Box>
            <Box sx={productsStyles.carouselItem}>
              <Box component="span" style={{ textAlign: 'center' }}>
                семейство OASYS
                <Box component="span" style={{ fontSize: '14px', verticalAlign: 'super' }}>
                  7
                </Box>{' '}
                (двухнедельные)
              </Box>
              <Box component="img" src={img.product_3} alt="" />
            </Box>
            <Box sx={productsStyles.carouselItem}>
              <Box component="span" style={{ textAlign: 'center' }}>
                семейство MOIST
                <Box component="span" style={{ fontSize: '14px', verticalAlign: 'super' }}>
                  8
                </Box>{' '}
                (однодневные)
              </Box>
              <Box component="img" src={img.product_4} alt="" />
            </Box>
          </Carousel>
        </Box>
      </Container>
    </Box>
  )
}

const products: Card[] = [
  {
    id: 1,
    url: img.product_card_1,
    title: (
      <Box>
        ACUVUE
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        OASYS 1-DAY
        <br />
        with HydraLuxe
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          ®****
        </Box>
      </Box>
    ),
    text: (
      <Box>
        Для активного образа жизни и комфорта в течение всего дня
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          2
        </Box>
      </Box>
    ),
    href: 'https://www.acuvue.com/ru-ru/products/acuvue-oasys-1-day/',
  },
  {
    id: 2,
    url: img.product_card_2,
    title: (
      <Box>
        ACUVUE
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        OASYS MAX 1-Day
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          **
        </Box>{' '}
      </Box>
    ),
    text: (
      <Box>
        Непревзойденный комфорт
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          ******
        </Box>{' '}
        и отличное качество зрения при использовании гаджетов
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          6
        </Box>{' '}
      </Box>
    ),
    href: 'https://www.acuvue.com/ru-ru/products/acuvue-oasys-max-1-day/',
  },
  {
    id: 3,
    url: img.product_card_3,
    title: (
      <Box>
        ACUVUE
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        OASYS WITH HYDRACLEAR
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        PLUS
        <Box component="span" fontSize={{ xs: '2.33vw', sm: '1.27vw', md: '14px' }} style={{ verticalAlign: 'super' }}>
          *****
        </Box>{' '}
      </Box>
    ),
    text: (
      <Box>
        Комфорт для ваших глаз
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          1
        </Box>{' '}
        с заменой 1 раз в 2 недели
      </Box>
    ),
    href: 'https://www.acuvue.com/ru-ru/products/acuvue-oasys-2-week/',
  },
]
