import { FC, JSX, useEffect } from 'react'
import { Box } from '@mui/material'
import QRCode from 'react-qr-code'
import { mainStyles } from './main.styles'
import { Layout, FinishAction } from '../../components'
import { BannerSection, PrizesSection, WinnersSection, FaqSection, Products } from './section'
import { useDetectDevice, useReduxSelector } from '../../hooks'
import { DetectDevice } from '../../types'

export const MainPage: FC = (): JSX.Element => {
  const detectDevice = useDetectDevice()

  const { data: constants, init: isConstants } = useReduxSelector((state) => state.constants)

  useEffect(() => {
    setTimeout(() => {
      const tag = window.location.hash.replace('#', '')
      const element = document.getElementById(tag)
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    }, 0)
  }, [])

  return (
    <>
      {constants?.IS_PROJECT_STOP_ACTION && <FinishAction />}
      <Layout>
        <Box sx={mainStyles.page}>
          <BannerSection />
          <section id="prizes">
            <PrizesSection />
          </section>
          <section id="products">
            <Products />
          </section>
          <section id="winners">
            <WinnersSection />
          </section>
          <section id="faq">
            <FaqSection />
          </section>
        </Box>
      </Layout>
    </>
  )
}
