import { FC, JSX } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { useReduxSelector } from '../../hooks'
import { Preloader } from '../preloader'

interface PrivateRouteProps {
  component: FC
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }): JSX.Element => {
  const location = useLocation()

  const { loading, auth, data: userInfo } = useReduxSelector((state) => state.auth)

  switch (true) {
    case loading:
      return <Preloader />
    case !auth:
      return <Navigate to={{ pathname: '../', search: 'signin' }} />
    case !userInfo?.data_complete:
      return <Navigate to={{ pathname: '../', search: 'signup' }} />
    case userInfo?.person_need !== 'USER_NEED_FIVEPOST' && location.pathname === '/fivepost':
      return <Navigate to="/profile" />
    default:
      return <Component />
  }
}
