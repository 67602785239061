import { ReactNode } from 'react'

export interface Font {
  primary: string
  secondary?: string
  tertiary?: string
  other?: string
}

export type Constants = Readonly<{
  NOW?: number
  DEBUG?: boolean
  IS_IFRAME?: boolean
  PROJECT_DOMAIN?: string
  PROFILE?: 'test' | 'prod'
  PERSONA_STOP_PDN?: number
  RECAPTCHA_SITE_KEY?: string
  PROJECT_STOP_ORDER?: number
  PROJECT_ACTION_NAME?: string
  PROJECT_START_ORDER?: number
  PROJECT_STOP_ACTION?: number
  PROJECT_START_ACTION?: number
  IS_PERSONA_STOP_PDN?: boolean
  IS_PROJECT_STOP_ORDER?: boolean
  IS_PROJECT_START_ORDER?: boolean
  IS_PROJECT_STOP_ACTION?: boolean
  IS_PROJECT_START_ACTION?: boolean
  PROJECT_STOP_DOC_REGISTER?: number
  PROJECT_STOP_CODE_REGISTER?: number
  PROJECT_START_DOC_REGISTER?: number
  PROJECT_START_CODE_REGISTER?: number
  IS_PROJECT_STOP_DOC_REGISTER?: boolean
  IS_PROJECT_START_DOC_REGISTER?: boolean
  IS_PROJECT_STOP_CODE_REGISTER?: boolean
  IS_PROJECT_START_CODE_REGISTER?: boolean
}>

export type ServiceResponce<T> = {
  data: T
  message: string
  status: number
}

export type AlertProps = {
  title?: string
  rootModal?: string
  fullScreen?: boolean
  onClose?: () => void
  closeButton?: boolean
  onAction?: () => void
  onConfirm?: () => void
  emptyMessage?: boolean
  cancelBtnText?: string
  confirmBtnText?: string
  actionButtonText?: string
  onCloseConfirm?: () => void
  message?: ReactNode | string
}

export enum ApiAnswerStatus {
  SUCCESS = 1,
  BAD_REQUEST = 400,
  UNPROCESSABLE = 422,
  UNAUTHENTICATED = 401,
  NEED_FULL_REGISTER = 402,
}

export enum Token {
  PRIVATE = 'token_private',
}
