import { FC, JSX, ChangeEvent, ReactElement } from 'react'
import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText, SxProps, Theme } from '@mui/material'
import { Controller, UseFormReturn } from 'react-hook-form'

type CheckboxProps = {
  name: string
  icon: ReactElement
  disabled?: boolean
  required?: boolean
  checked: ReactElement
  form: UseFormReturn<any>
  label: string | ReactElement
  color?: 'primary' | 'secondary'
  onChange?(e: ChangeEvent<HTMLInputElement>, checked: boolean): void
}

export const CheckboxInput: FC<CheckboxProps> = ({
  form,
  name,
  label,
  icon,
  checked,
  required = false,
  disabled = false,
  color = 'primary',
  onChange: change,
}): JSX.Element => {
  const { control, formState } = form

  return (
    <Box py={1 / 2}>
      <FormControl>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name={name}
              rules={{ required }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  icon={icon}
                  color={color}
                  inputRef={ref}
                  onBlur={onBlur}
                  checked={value}
                  disabled={disabled}
                  checkedIcon={checked}
                  onChange={(e, c) => {
                    onChange(e)
                    if (change) change(e, c)
                  }}
                />
              )}
            />
          }
          label={label}
        />
        <FormHelperText sx={checkBoxStyles.helper}>
          <>{formState.errors[name]?.message}</>
        </FormHelperText>
      </FormControl>
    </Box>
  )
}

type CheckBoxStyles = 'helper'

const checkBoxStyles: Record<CheckBoxStyles, SxProps<Theme> | undefined> = {
  helper: () => ({ mt: '-3px', marginLeft: '33px' }),
}
