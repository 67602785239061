import { useDispatch } from 'react-redux'
import { bindActionCreators } from '@reduxjs/toolkit'
import { authActions } from '../../store/auth/auth.slice'
import { constActions } from '../../store/constants/constants.slice'
import { modalActions } from '../../store/modals/modal.slice'
import { notificationActions } from '../../store/notification/notification.slice'
import { propsActions } from '../../store/props/props.slice'
import { greetingsActions } from '../../store/greetings/greetings.slice'
import { profileActions } from '../../store/profile/profile.slice'
import { gameActions } from '../../store/game/game.slice'

const actions = {
  ...gameActions,
  ...authActions,
  ...propsActions,
  ...constActions,
  ...modalActions,
  ...profileActions,
  ...greetingsActions,
  ...notificationActions,
}

export const useReduxActions = () => {
  const dispatch = useDispatch()
  return bindActionCreators(actions, dispatch)
}
