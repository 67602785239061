import { FC, JSX } from 'react'
import { Button, ButtonProps, CircularProgress, SxProps, Theme } from '@mui/material'

type ButtonPropsCustom = ButtonProps & { processed?: boolean }

export const SubmitButton: FC<ButtonPropsCustom> = ({
  title,
  sx,
  disabled = false,
  processed = false,
  type = 'submit',
  color = 'secondary',
  variant = 'contained',
  onClick = () => {},
  ...props
}): JSX.Element => {
  return (
    <Button
      type={type}
      color={color}
      onClick={onClick}
      variant={variant}
      disabled={disabled || processed}
      sx={submitButtonStyles.actionSubmit}
      {...props}
    >
      {title || props.children}
      {processed && <CircularProgress size={24} sx={submitButtonStyles.buttonProgress} />}
    </Button>
  )
}

type SubmitButtonStylesProps = 'actionSubmit' | 'buttonProgress'

const submitButtonStyles: Record<SubmitButtonStylesProps, SxProps<Theme> | undefined> = {
  actionSubmit: ({ breakpoints: { down } }: Theme) => ({}),
  buttonProgress: ({ palette, spacing }: Theme) => ({
    top: '50%',
    left: '50%',
    position: 'absolute',
    marginTop: spacing(-3 / 2),
    marginLeft: spacing(-3 / 2),
    color: `#FFFFFF!important`,
  }),
}
