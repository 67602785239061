/* eslint-disable max-len */
import { FC, JSX } from 'react'

export const VkIcon: FC = (): JSX.Element => (
  <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 8C0 3.58172 3.58172 0 8 0H36C40.4183 0 44 3.58172 44 8V36C44 40.4183 40.4183 44 36 44H8C3.58172 44 0 40.4183 0 36V8Z"
      fill="#0077FF"
    />
    <path
      d="M11 21.56C11 16.582 11 14.093 12.5465 12.5465C14.093 11 16.582 11 21.56 11H22.44C27.418 11 29.907 11 31.4535 12.5465C33 14.093 33 16.582 33 21.56V22.44C33 27.418 33 29.907 31.4535 31.4535C29.907 33 27.418 33 22.44 33H21.56C16.582 33 14.093 33 12.5465 31.4535C11 29.907 11 27.418 11 22.44V21.56Z"
      fill="white"
    />
    <path
      d="M22.7065 26.9C17.6841 26.9 14.8194 23.4466 14.7 17.7H17.2158C17.2984 21.9178 19.1531 23.7044 20.6222 24.0728V17.7H22.9912V21.3376C24.4419 21.1811 25.9659 19.5234 26.4801 17.7H28.849C28.4542 19.9471 26.8015 21.6047 25.6262 22.2862C26.8015 22.8387 28.6838 24.2846 29.4 26.9H26.7923C26.2322 25.1503 24.8367 23.7965 22.9912 23.6123V26.9H22.7065Z"
      fill="#0077FF"
    />
  </svg>
)
