import { SxProps, Theme } from '@mui/material'
import { keyframes } from '@mui/system'
import { img } from '../../../../imgs'

type bannerStylesProps =
  | 'section'
  | 'title'
  | 'btn'
  | 'rules'
  | 'rulesLeft'
  | 'rulesRight'
  | 'sectionRules'
  | 'sectionRulesOption'
  | 'sectionRulesDescription'
  | 'sectionRulesDescription2'
  | 'description'
  | 'mainHolder'
  | 'bannerText'

const animate = keyframes`
  0% {
    top: -260px;
  }
  30% {
    top: 75px;
  }
  50% {
    top: 30px;
  }
  75% {
    top: 65px;
  }
  100% {
    top: 40px;
  }
`

export const bannerStyles: Record<bannerStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down } }: Theme) => ({
    height: '100%',
    overflowX: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    pt: '100px',
    background: `url(${img.bg_bg}) bottom center no-repeat, linear-gradient(#CFEAF9, #FFFFFF)`,
    backgroundSize: '100% auto, auto',
    pb: '30px',
    [down('md')]: {
      pt: '80px',
    },
    [down('sm')]: {
      pt: '0px',
    },
  }),
  mainHolder: ({ breakpoints: { down } }: Theme) => ({
    width: '100%',
    height: 'fit-content',
    position: 'relative',
    mb: '60px',
    '& img': {
      width: '100%',
    },
    [down('sm')]: {
      width: 'calc(100% + 40px)',
      ml: '-20px',
      mb: '64px',
    },
  }),
  bannerText: ({ breakpoints: { down } }: Theme) => ({
    position: 'absolute',
    height: 'fit-content',
    width: '500px',
    top: '30%',
    left: '7.14%',
    fontSize: '105px',
    color: '#FFF',
    fontWeight: 800,
    lineHeight: '100px',
    textShadow: '0px 10px 12px #0022408C',
    [down(1450)]: {
      width: '34.48vw',
      fontSize: '7.24vw',
      lineHeight: '6.89vw',
    },
    [down('md')]: {
      width: '45.45vw',
    },
    [down('sm')]: {
      width: '60vw',
      fontSize: '12vw',
      lineHeight: '12vw',
      top: '50%',
      left: '6%',
    },
  }),
  btn: ({ breakpoints: { down }, palette }: Theme) => ({
    bottom: '14%',
    left: '7.14%',
    fontSize: '20px!important',
    minHeight: 80,
    minWidth: 353,
    borderRadius: '87px',
    position: 'absolute',
    [down(1400)]: {
      minHeight: '5.71vw',
      fontSize: '1.41vw!important',
      minWidth: '25.21vw',
    },
    [down('md')]: {
      minHeight: '6.5vw',
      fontSize: '1.8vw!important',
      minWidth: '24vw',
    },
    [down('sm')]: {
      minWidth: 'calc(100% - 40px)',
      maxHeight: 44,
      p: '11px 40px!important',
      fontSize: '16px!important',
      bottom: '-54px',
      left: '20px',
    },
  }),
  title: ({ breakpoints: { down } }: Theme) => ({
    '& svg': {
      [down(1350)]: {
        width: '26.89vw',
        height: '21.33vw',
      },
      [down('md')]: {
        width: '29vw',
        height: '27vw',
      },
      [down('sm')]: {
        width: '44vw',
        height: '38vw',
      },
    },
  }),
  rules: ({ breakpoints: { down }, palette }: Theme) => ({
    display: 'flex',
    height: 530,
    flexDirection: 'row',
    p: '45px 105px',
    borderRadius: '28px',
    backgroundColor: '#FFFFFF',
    color: palette.primary.dark,
    boxSizing: 'border-box',
    [down(1350)]: {
      p: '30px 60px',
    },
    [down('md')]: {
      flexDirection: 'column',
      height: 'fit-content',
    },
    [down('sm')]: {
      p: '22px 15px',
    },
  }),
  rulesLeft: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '50%',
    height: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
    fontWeight: 800,
    p: '46.5px 0',
    boxSizing: 'border-box',
    [down('md')]: {
      width: '100%',
      p: '0',
    },
  }),
  rulesRight: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    width: '50%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& img': {
      height: '100%',
    },
    [down(1200)]: {
      '& img': {
        height: '90%',
      },
    },
    [down('md')]: {
      width: '100%',
      p: '50px',
      boxSizing: 'border-box',
      '& img': {
        height: 'auto',
        width: '100%',
      },
    },
    [down('sm')]: {
      p: '40px 40px 30px 40px',
    },
  }),
  sectionRules: ({ breakpoints: { down } }: Theme) => ({
    height: '100%',
    width: '50%',
    borderRadius: '0 28px 28px 0',
    bgcolor: '#FFFFFF',
    p: '50px',
    boxSizing: 'border-box',
    color: '#051F4A',
    fontWeight: 800,
    [down('md')]: {
      height: '50%',
      width: '100%',
      borderRadius: '28px 28px 0 0',
      p: '20px 30px',
    },
  }),
  sectionRulesOption: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 24,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    '& div:nth-of-type(1)': {
      minHeight: '40px',
      minWidth: '40px',
      fontSize: 28,
      border: '5px solid #AB0F7E',
      borderRadius: '50%',
      textAlign: 'center',
      alignContent: 'center',
      color: '#AB0F7E',
      fontWeight: 800,
    },
    '& div:nth-of-type(2)': {
      ml: '30px',
    },
    [down(1350)]: {
      fontSize: '1.77vw',
      '& div:nth-of-type(1)': {
        fontSize: '2.07vw',
      },
      '& div:nth-of-type(2)': {
        fontSize: '1.77vw',
      },
    },
    [down('md')]: {
      fontSize: '2.18vw',
      '& div:nth-of-type(1)': {
        fontSize: '2.54vw',
      },
      '& div:nth-of-type(2)': {
        fontSize: '2.18vw',
      },
    },
    [down(800)]: {
      '& div:nth-of-type(1)': {
        minHeight: '30px',
        minWidth: '30px',
        border: '4px solid #AB0F7E',
      },
      '& div:nth-of-type(2)': {
        ml: '20px',
      },
    },
    [down('sm')]: {
      fontSize: '4vw',
      '& div:nth-of-type(1)': {
        minHeight: '30px',
        minWidth: '30px',
        fontSize: '4vw',
      },
      '& div:nth-of-type(2)': {
        fontSize: '4vw',
      },
    },
  }),
  sectionRulesDescription: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 28,
    fontWeight: 600,
    '& a': {
      textDecoration: 'underline',
      color: palette.secondary.contrastText,
    },
    [down(1350)]: {
      fontSize: '2.07vw',
    },
    [down('md')]: {
      fontSize: '2.54vw',
    },
    [down('sm')]: {
      fontSize: '4.66vw',
    },
  }),
  sectionRulesDescription2: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    fontWeight: 600,
    color: '#737C8A',
    textTransform: 'none',
    [down(1350)]: {
      fontSize: '1.48vw',
    },
    [down('md')]: {
      fontSize: '1.81vw',
    },
    [down('sm')]: {
      fontSize: '3.33vw',
    },
  }),
  description: ({ breakpoints: { down } }: Theme) => ({
    border: '2px solid rgba(41, 191, 239, 1)',
    borderRadius: '30px',
    color: 'black',
    p: '30px',
    fontWeight: 500,
    fontSize: 18,
    Paragraph: '10px',
    lineHeight: '24px',
    mt: '-80px',
    [down(1350)]: {
      fontSize: '1.33vw',
      mt: '-6vw',
    },
    [down('md')]: {
      fontSize: '1.9vw',
      mt: '-10vw',
    },
    [down('sm')]: {
      fontSize: '3.9vw',
      mt: '-12vw',
    },
  }),
}
