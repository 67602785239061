import { FC, JSX, SyntheticEvent, useEffect, useState, useRef, MutableRefObject } from 'react'
import {
  Box,
  Grid,
  Table,
  Hidden,
  TableRow,
  useTheme,
  Container,
  TableCell,
  TableHead,
  useMediaQuery,
  Tab,
  Tabs,
} from '@mui/material'
import { WinnersList } from './winners.list'
import { WinnersSearchForm } from './winners-search.form'
import { getWinner } from '../../../../api'
import { TabPanel } from '../../../../components'
import { img } from '../../../../imgs'
import { winnersStyles } from './winners.styles'
import { useIsVisible } from '../prizes'

export const WinnersSection: FC = (): JSX.Element => {
  const initialPage = 1
  const theme = useTheme()

  const md = useMediaQuery(theme.breakpoints.down(960))
  const sm = useMediaQuery(theme.breakpoints.down(600))

  const [init, setInit] = useState<boolean>(true)
  const [initListWinner, setInitListWinner] = useState<boolean>(true)
  const [winners, setWinners] = useState<any>({ data: [] })
  const [winners2, setWinners2] = useState<any>({ data: [] })
  const [filter, setFilter] = useState<any>({ login: undefined })
  const [resetEmail, setResetEmail] = useState<boolean>(false)
  const [message, setMessage] = useState<boolean>(false)
  const [tab, setTab] = useState<number>(0)

  const findWinner = (f: any, pages: number) => {
    setFilter(f)
    getWinner(f, pages, 'site').then((r: any) => {
      setWinners(r)
      setMessage(true)
      if (((r.data?.length <= 0 && !filter.login) || r.data === undefined) && init) {
        setInitListWinner(false)
      }
    })
    getWinner(f, pages, 'shop').then((r: any) => {
      setWinners2(r)
      setMessage(true)
      if (((r.data?.length <= 0 && !filter.login) || r.data === undefined) && init) {
        setInitListWinner(false)
      }
    })
  }

  const handleChange = (event: SyntheticEvent, value: number) => {
    findWinner(filter, value)
  }

  const handleSearch = (value: any) => {
    setMessage(false)
    if (value) {
      findWinner({ login: value }, initialPage)
    } else {
      findWinner({ login: undefined }, initialPage)
      setResetEmail(false)
    }
  }

  const onChangeReset = (event: any) => {
    if (event.target.value && !resetEmail) setResetEmail(true)
  }

  useEffect(() => {
    findWinner({ login: undefined }, initialPage)
    setInit(false)
  }, [])

  const ref = useRef()
  const isVisible = useIsVisible(ref)

  const [pos1, setPos1] = useState({ x: '0px', y: '0px' })
  const [pos2, setPos2] = useState({ x: '0px', y: '0px' })

  useEffect(() => {
    function cursor(e: any) {
      const x = e.clientX
      const y = e.clientY
      setPos1({
        x: `${x * 0.005}px`,
        y: `${y * 0.005}px`,
      })
      setPos2({
        x: `${x * 0.015}px`,
        y: `${y * 0.015}px`,
      })
    }
    document.addEventListener('mousemove', cursor, false)
    return () => document.addEventListener('mousemove', cursor, false)
  }, [])

  const handleChangeTab = (event: SyntheticEvent, newValue: number) => {
    setTab(newValue)
  }

  const a11yProps = (index: number) => {
    return { id: `simple-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` }
  }

  return (
    <Box sx={winnersStyles.section} position="relative">
      <Box maxWidth="lg" sx={winnersStyles.title}>
        Победители
      </Box>
      <Container sx={winnersStyles.container}>
        <Box sx={winnersStyles.table}>
          <Grid container justifyContent="center" flexDirection="column">
            <Grid item xs="auto" justifyContent="center" display="flex">
              <Box sx={winnersStyles.tabs}>
                <Tabs value={tab} onChange={handleChangeTab}>
                  <Tab label="ЧЕКИ НА САЙТЕ" {...a11yProps(0)} sx={winnersStyles.tabItem} />
                  <Tab
                    label={
                      <Box>
                        ПОКУПКИ С MyACUVUE
                        <Box component="span" fontSize={{ xs: '16px', md: '20px' }} style={{ verticalAlign: 'super' }}>
                          ®
                        </Box>
                      </Box>
                    }
                    {...a11yProps(1)}
                    sx={winnersStyles.tabItem}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item>
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs="auto">
                  {initListWinner && (
                    <Box sx={winnersStyles.boxSearch}>
                      <Box width={sm ? 300 : 490}>
                        <WinnersSearchForm
                          resetEmail={resetEmail}
                          onSubmitForm={handleSearch}
                          onChangeReset={onChangeReset}
                          initListWinner={initListWinner}
                        />
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {winners && (
            <Box justifyContent="center" alignItems="flex-start" minHeight={md ? 200 : 270}>
              {winners.data?.length > 0 ? (
                <TabPanel value={tab} index={0}>
                  <WinnersList {...winners} changePage={handleChange} />
                </TabPanel>
              ) : (
                filter.login &&
                tab === 0 &&
                message && (
                  <Box textAlign="center" sx={winnersStyles.text}>
                    Участник с указанным номером телефона
                    <br />
                    не получил приз в данной категории
                  </Box>
                )
              )}
              {winners2.data?.length > 0 ? (
                <TabPanel value={tab} index={1}>
                  <WinnersList {...winners2} changePage={handleChange} />
                </TabPanel>
              ) : (
                filter.login &&
                tab === 1 &&
                message && (
                  <Box textAlign="center" sx={winnersStyles.text}>
                    Участник с указанным номером телефона
                    <br />
                    не получил приз в данной категории
                  </Box>
                )
              )}
              {((winners.data?.length <= 0 && !filter.login) || winners.data === undefined) && message && (
                <Grid item xs={12}>
                  <Box textAlign="center" sx={winnersStyles.text}>
                    Уважаемые участники, первый розыгрыш призов
                    <br />
                    состоится <span style={{ whiteSpace: 'nowrap' }}>10 ноября 2024 года</span>
                  </Box>
                </Grid>
              )}
            </Box>
          )}
        </Box>
      </Container>
      <Container sx={winnersStyles.bg} maxWidth="xl">
        <img src={img.winners_bg} alt="" />
      </Container>
      <Box ref={ref} style={isVisible ? {} : { visibility: 'hidden' }}>
        <Box style={sm || md ? {} : { transform: `translate(${pos1.x}, ${pos1.y})` }} sx={winnersStyles.bottle}>
          <img
            src={sm ? img.bottle2 : img.bottle1}
            className={isVisible ? 'animate__animated animate__bounceInRight' : ''}
            alt=""
          />
        </Box>
        <Box style={sm || md ? {} : { transform: `translate(${pos2.x}, ${pos2.y})` }} sx={winnersStyles.ice}>
          <img src={img.winners_ice} className={isVisible ? 'animate__animated animate__bounceInRight' : ''} alt="" />
        </Box>
      </Box>
      <Container maxWidth="lg" sx={{ position: 'relative' }}>
        <Box sx={winnersStyles.noalco}>
          <img src={sm ? img.icon_noalco2 : img.icon_noalco1} alt="" />
        </Box>
      </Container>
    </Box>
  )
}
