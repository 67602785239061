/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, JSX, useEffect, useState } from 'react'
import { Box, Collapse, Grid, Link, SxProps, Theme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Modal } from '../modal'
import { getPersonal } from '../../api'
import { SubmitButton } from '../submit-button'
import { useReduxActions, useReduxDispatch } from '../../hooks'

interface PdnWidgetProps {
  info?: any
  name?: string
  title?: string
  onShow?(): void
  onClose?(): void
}

export const PdnWidget: FC<PdnWidgetProps> = ({
  info,
  name = 'alert',
  onShow,
  onClose,
  title = 'Уважаемый участник!',
}): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()
  const dispatch = useReduxDispatch()

  const [showPdn, setShowPdn] = useState<boolean>(false)
  const [collapse, setCollapse] = useState<boolean>(true)
  const [processed, setProcessed] = useState<boolean>(false)
  const [showPdnAlert, setShowPdnAlert] = useState<boolean>(false)
  const [pdnProcessed, setPdnProcessed] = useState<boolean>(false)

  const { person_need } = info || {}

  const [alertGlobal, setAlertGlobal] = useState<boolean>(true)

  useEffect(() => {
    if (person_need && person_need !== 'USER_FULL' && person_need !== 'USER_NEED_WAIT_NDFL') {
      setShowPdn(true)
      setShowPdnAlert(true)
    } else if (person_need && person_need === 'USER_NEED_WAIT_NDFL') {
      setShowPdn(false)
      setShowPdnAlert(false)
    } else {
      setShowPdn(false)
      setShowPdnAlert(false)
    }
  }, [person_need])

  const personaGet = () => {
    try {
      setPdnProcessed(true)

      if (person_need) {
        if (person_need === 'USER_NEED_FIVEPOST') {
          navigate('../fivepost')
        } else {
          getPersonal(person_need)
            .then((res) => {
              if (res.status === 1) {
                document.location.href = res.data.redirect_url
              } else {
                setShowPdnAlert(false)
                setAlert({
                  alert: {
                    message: 'Сервис временно не доступен. Пожалуйста, попробуйте позже.',
                    onClose: () => {},
                  },
                })
              }
            })
            .catch(() => {})
            .finally(() => {
              setPdnProcessed(false)
            })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  const onCloseHandle = () => {
    setShowPdnAlert(false)
    setAlertGlobal(false)
    if (onClose) {
      setCollapse(false)
      onClose()
    }
  }

  useEffect(() => {
    if (onShow) {
      setCollapse(true)
      onShow()
    }
  }, [])

  return (
    <>
      {showPdn && (
        <Box maxWidth={305}>
          <SubmitButton
            fullWidth
            disabled={processed}
            processed={processed}
            sx={pdnWidgetStyles.button}
            onClick={() => {
              setProcessed(true)
              personaGet()
            }}
          >
            {person_need === 'USER_NEED_BANK' && <>Заполнить банковскую форму</>}
            {person_need === 'USER_NEED_ADDRESS' && <>Заполнить адресную форму</>}
            {person_need === 'USER_NEED_FIVEPOST' && <>Выбрать адрес пункта 5Post</>}
            {person_need === 'USER_NEED_NDFL' && <>Заполнить налоговую форму</>}
            {person_need === 'USER_NEED_NDFLADV' && <>Заполнить налоговую форму</>}
          </SubmitButton>
        </Box>
      )}

      <Modal
        sx={{ '& .MuiPaper-root': { maxWidth: '650px!important' }, '& .MuiDialogContent-root': { fontWeight: '500' } }}
        title={title}
        maxWidth="sm"
        name={name}
        open={showPdnAlert && alertGlobal}
        onClose={onCloseHandle}
      >
        <Collapse in={collapse}>
          <Grid item xs={12}>
            {person_need === 'USER_NEED_FIVEPOST' && (
              <Box sx={pdnWidgetStyles.text} textAlign="center">
                Для получения вещественных подарков необходимо выбрать адрес доставки 5Post. Обращаем внимание, что
                выбирается адрес один раз на все подарки
              </Box>
            )}

            {person_need === 'USER_NEED_NDFL' && (
              <Box sx={pdnWidgetStyles.text} textAlign="center">
                Сумма вашего выигрыша превышает 4&nbsp;000&nbsp;рублей.
                <br />
                Вам необходимо заполнить налоговую форму.
                <br />
                (В соответствии с НК РФ на призы свыше 4&nbsp;000&nbsp;рублей платится налог 35% от суммы, превышающей
                4&nbsp;000 рублей. Сумма налога будет компенсирована организатором.)
              </Box>
            )}

            {person_need === 'USER_NEED_WAIT_NDFL' && (
              <Box sx={pdnWidgetStyles.text} textAlign="center">
                Заказать получение приза на общую сумму превышающую 4&nbsp;000&nbsp;рублей возможно будет только после
                того, как указанные персональные данные в форме пройдут успешно проверку.
              </Box>
            )}

            {person_need === 'USER_NEED_NDFLADV' && (
              <Box sx={pdnWidgetStyles.text} textAlign="center">
                Вы выиграли приз.
                <br />
                Для получения приза заполните налоговую и адресную форму в&nbsp;течение 5&nbsp;рабочих дней
                с&nbsp;момента оповещения о&nbsp;выигрыше.
              </Box>
            )}

            {person_need === 'USER_NEED_BANK' && (
              <Box sx={pdnWidgetStyles.text} textAlign="center" pt={2}>
                Вы выиграли приз.
                <br />
                Для получения приза заполните банковскую форму в&nbsp;течение 5&nbsp;рабочих дней с&nbsp;момента
                оповещения о&nbsp;выигрыше.
              </Box>
            )}

            {person_need !== 'USER_NEED_WAIT_NDFL' && (
              <Box width="100%" maxWidth={300} mx="auto" mt={3}>
                <SubmitButton
                  fullWidth
                  type="button"
                  onClick={personaGet}
                  title="Заполнить форму"
                  disabled={pdnProcessed}
                  processed={pdnProcessed}
                />
              </Box>
            )}
          </Grid>
        </Collapse>
      </Modal>
    </>
  )
}

type PdnWidgetStylesProps = 'text' | 'button'

const pdnWidgetStyles: Record<PdnWidgetStylesProps, SxProps<Theme> | undefined> = {
  text: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 17,
    m: '10px 30px',
    [down('sm')]: {
      minHeight: 48,
      minWidth: 250,
    },
  }),
  button: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 18,
    minHeight: 60,
    p: '6px 30px',
    fontWeight: 500,
    backgroundColor: '#E51523!important',
    '&:hover': {
      color: palette.primary.main,
      backgroundColor: palette.success.main,
      [down('sm')]: {
        color: palette.primary.dark,
        backgroundColor: palette.primary.main,
      },
    },
    [down('sm')]: {
      fontSize: 17,
      minWidth: 250,
    },
  }),
}
