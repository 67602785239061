export default {
  labelTapToCancel: 'Отмена',
  labelTapToUndo: 'Отменить',
  labelFileProcessing: 'Загрузка',
  labelButtonRemoveItem: 'Удалить',
  labelTapToRetry: 'Нажмите повторно',
  labelFileLoadError: 'Ошибка загрузки файла',
  labelMaxFileSizeExceeded: 'Размер файла превышен',
  labelFileProcessingError: 'Ошибка загрузки файла',
  labelFileProcessingComplete: 'Загрузка завершена',
  labelMaxFileSize: 'Максимальный размер файла {filesize}',
  labelIdle:
    `<div><div style="margin-bottom: 10px"><span style="text-decoration: underline; color: #AB0F7E">Загрузите</span> ` +
    `или перетяните <span style="white-space: nowrap;">сюда фото чека.</span></div>` +
    `<div>На фото должен быть чётко виден QR-код и данные чека.</div></div>`,
}
