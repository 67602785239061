import { FC, JSX, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { usePrevious } from 'react-use'
import queryString, { ParsedQuery } from 'query-string'
import { useReduxActions, useReduxSelector } from '../hooks'
import { Alert, Greetings, TrackEvent } from '../components'
import { SignInModal, signInModalName, ForgotModal, forgotModalName, SignUpModal, signUpModalName } from '../modals'

export const ModalRoot: FC = (): JSX.Element => {
  const { setModal } = useReduxActions()

  const { auth } = useReduxSelector((state) => state.auth)
  const { alert } = useReduxSelector((state) => state.modals)

  const { parse } = queryString
  const { pathname, search, hash } = useLocation()
  const prevLocation = usePrevious({ location: { pathname, search, hash } })

  const openModal = (modal: string, query: ParsedQuery) => {
    setModal({ modal: { [modal]: { show: true, params: { ...query } } } })
  }

  useEffect(() => {
    const query = parse(search)

    if (!prevLocation || prevLocation.location.search !== search) {
      setModal({
        modal: {
          [signInModalName]: { show: false },
          [signUpModalName]: { show: false },
          [forgotModalName]: { show: false },
        },
      })

      switch (true) {
        case 'signin' in query:
          if (!auth) {
            const element = document.getElementById('login')
            element?.scrollIntoView({
              behavior: 'smooth',
            })
            document.getElementById('1')?.click()
          }
          break
        case 'signup' in query:
          if (!auth) {
            const element = document.getElementById('login')
            element?.scrollIntoView({
              behavior: 'smooth',
            })
            document.getElementById('2')?.click()
          }
          break
        case 'forgot' in query:
          if (!auth) openModal(forgotModalName, query)
          break
        default:
          break
      }
    }

    if ((!prevLocation || prevLocation.location.pathname !== pathname) && !('token' in query)) {
      TrackEvent({ eventCategory: 'Pageview', eventAction: 'open_page', eventLabel: pathname })
    }
  }, [pathname, prevLocation, search])

  return (
    <>
      <Alert {...alert} />
      <Greetings />
      <SignInModal />
      <SignUpModal />
      <ForgotModal />
    </>
  )
}
