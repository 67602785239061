import { FC, JSX, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Check as CheckIcon } from '@mui/icons-material'
import { Box, SxProps, Theme } from '@mui/material'
import { CheckboxInput } from '../form-control'
import { useReduxActions, useReduxSelector } from '../../hooks'
import { subscribeChange } from '../../api'
import { FormProps } from '../../types'

type UpdateSubscribeProps = { subscribe_email?: boolean }

export const UpdateSubscribeForm: FC = (): JSX.Element => {
  const { setAlert, updateUser } = useReduxActions()

  const { data: user } = useReduxSelector((state) => state.auth)

  const defaultValues: UpdateSubscribeProps = {
    subscribe_email: user?.subscribe_email,
  }

  const [form, setForm] = useState<FormProps<UpdateSubscribeProps>>({
    data: defaultValues,
    processed: false,
  })

  const hookForm = useForm({ defaultValues })

  const onSubmit = async (data: UpdateSubscribeProps) => {
    setForm({ ...form, processed: true })

    subscribeChange({ ...user, ...data })
      .then(({ status, message: messages }) => {
        if (status === 1) {
          setAlert({
            alert: {
              message: messages,
              onClose: () => {
                updateUser(data)
                setForm({ ...form, processed: false })
              },
            },
          })
        } else {
          updateUser(data)
          setForm({ ...form, processed: false })
        }
      })
      .catch((e) => {
        setForm({ ...form, processed: false })
      })
  }

  const handleChange = (name: string, checked: boolean) => {
    if (!form.processed) onSubmit({ [name]: checked })
  }

  const check = {
    icon: <Box sx={formStyles.icon} />,
    checked: (
      <Box sx={formStyles.iconActive}>
        <Box sx={formStyles.checkBox}>
          <CheckIcon sx={formStyles.check} />
        </Box>
      </Box>
    ),
  }

  const formLocale = {
    subscribeSmsCheckbox: (
      <Box fontSize={{ xs: 14, md: 14 }} fontWeight="500">
        Я согласен получать информацию
        <br />
        об акции по смс
      </Box>
    ),

    subscribeEmailCheckbox: (
      <Box fontSize={{ xs: 14, md: 14 }} fontWeight="500">
        Я согласен на получение <span style={{ whiteSpace: 'nowrap' }}>E-mail</span> рассылки от ООО «Джонсон & Джонсон»
      </Box>
    ),
  }

  return (
    <>
      <CheckboxInput
        form={hookForm}
        color="primary"
        icon={check.icon}
        name="subscribe_email"
        checked={check.checked}
        disabled={form.processed}
        label={formLocale.subscribeEmailCheckbox}
        onChange={(_, checked) => handleChange('subscribe_email', checked)}
      />
    </>
  )
}

type FormStylesProps = 'icon' | 'iconActive' | 'checkBox' | 'check'

const formStyles: Record<FormStylesProps, SxProps<Theme> | undefined> = {
  icon: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    borderRadius: '4px',
    backgroundColor: '#FFFFFF',
    border: `1px solid #AB0F7E`,
  }),
  iconActive: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    borderRadius: '4px',
    backgroundColor: palette.primary.main,
    border: `1px solid #AB0F7E`,
  }),
  checkBox: ({ palette }: Theme) => ({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#AB0F7E',
  }),
  check: ({ palette }: Theme) => ({
    color: '#FFFFFF',
    fontSize: '20px!important',
  }),
}
