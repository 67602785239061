import { ForwardRefExoticComponent, FC, JSX, forwardRef, ReactElement, Ref, useEffect } from 'react'
import {
  Box,
  Theme,
  Slide,
  Button,
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogProps as DialogPropsDefault,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'
import { str } from '../../utils'
import { useDetectDevice } from '../../hooks'
import { DetectDevice } from '../../types'

export interface DialogProps {
  name?: string
  open?: boolean
  hidden?: boolean
  processed?: boolean
  onOpen?: () => void
  onClose?: () => void
  closeButton?: boolean
  actionButton?: boolean
  colorIconClose?: string
  actionButtonText?: string
  onActionButton?: () => void
  titleTransform?: 'uppercase' | 'none'
}

const Transition: ForwardRefExoticComponent<any> = forwardRef(
  (props: any & { children: ReactElement<any, any> }, ref: Ref<unknown>): JSX.Element => (
    <Slide {...props} ref={ref} direction="up" easing="ease-out" timeout={{ enter: 300, exit: 300 }}>
      {props.children}
    </Slide>
  )
)

export const Modal: FC<DialogProps & DialogPropsDefault> = ({
  children,
  open = false,
  hidden = false,
  maxWidth = 'md',
  name = 'default',
  title = undefined,
  processed = false,
  fullScreen = false,
  onClose = () => {},
  closeButton = true,
  actionButton = false,
  actionButtonText = 'OK',
  onActionButton = () => {},
  colorIconClose = '#00051F80',
  titleTransform = 'none',
  ...props
}): JSX.Element => {
  const device = useDetectDevice()

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const dialogTitleId = `${name}-dialog-title`
  const dialogBodyId = `${name}-dialog-body`

  useEffect(() => {
    if (device === DetectDevice.IOS) {
      if (open) document.body.style.position = 'fixed'
      else document.body.style.position = 'relative'
    }
  }, [open])

  return (
    <Dialog
      {...props}
      scroll="body"
      title={title}
      open={open}
      hidden={hidden}
      maxWidth={false}
      fullScreen={fullScreen || sm}
      aria-labelledby={dialogTitleId}
      TransitionComponent={Transition}
    >
      {title && (
        <DialogTitle id={dialogTitleId} textTransform={titleTransform}>
          {str.normalize(title)}
        </DialogTitle>
      )}

      {closeButton && (
        <Box position="absolute" top={{ xs: 15, sm: 20 }} right={{ xs: 15, sm: 20 }} style={{ zIndex: 2 }}>
          <IconButton color="primary" disabled={processed} onClick={onClose} style={{ padding: 0 }}>
            <CloseIcon sx={{ color: colorIconClose, width: { xs: 30, sm: 38 }, height: { xs: 30, sm: 38 } }} />
          </IconButton>
        </Box>
      )}

      <DialogContent id={dialogBodyId}>{children}</DialogContent>

      {actionButton && (
        <DialogActions>
          <Box mx="auto" mt={2}>
            <Button onClick={onActionButton} disabled={processed} variant="contained" color="primary">
              {actionButtonText}
            </Button>
          </Box>
        </DialogActions>
      )}
    </Dialog>
  )
}
