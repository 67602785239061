import { FC, JSX, Fragment, ReactNode } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useReduxSelector } from '../../hooks'
import { StartPage } from '../../pages'
import { AgeCheck, Finish } from '..'

interface IStartAction {
  children: ReactNode
}

export const StartAction: FC<IStartAction> = ({ children }): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data: constants, init: isConstants } = useReduxSelector((state) => state.constants)

  if (constants?.PROFILE === 'test' && Number(searchParams.get('start')) === 1 && isConstants) {
    return <StartPage />
  }

  if (constants?.IS_PROJECT_START_ACTION && isConstants) {
    return <Fragment>{children}</Fragment>
  }

  return <StartPage />
}
