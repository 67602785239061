import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { NotificationAction } from './notification.types'

const initialState: NotificationAction = {
  token: undefined,
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setTokenNotification(state: NotificationAction, action: PayloadAction<NotificationAction>) {
      return { ...state, ...action.payload }
    },
  },
  extraReducers: () => {},
})

export const notificationActions = notificationSlice.actions
export const notificationReducer = notificationSlice.reducer
