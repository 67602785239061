import { FC, JSX, useEffect, useState } from 'react'
import { Box, Container, Grid, Theme, useMediaQuery } from '@mui/material'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { usePrevious } from 'react-use'
import { infoUserStyles } from './info-user.styles'
import { PdnWidget, RequireCheck, UpdateSubscribeForm, WidgetRegisterCheck } from '../../../../components'
import { useReduxActions, useReduxSelector } from '../../../../hooks'

interface IInfoUserSection {
  updateInfoUserRegDoc: () => void
}

type InfoModal = 'reg-doc'

export const InfoUserSection: FC<IInfoUserSection> = ({ updateInfoUserRegDoc }): JSX.Element => {
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()
  const { parse } = queryString
  const { pathname, search, hash } = useLocation()
  const prevLocation = usePrevious({ location: { pathname, search, hash } })

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))

  const { data: constants } = useReduxSelector((state) => state.constants)

  const { data: dataUser } = useReduxSelector((state) => state.auth)

  const {
    data: { balls, docs, prizes },
  } = useReduxSelector((state) => state.profile)

  const [modal, setModal] = useState<InfoModal | undefined>(undefined)

  const onOpenModal = (type: InfoModal) => {
    setModal(type)
  }

  const onCloseModal = () => setModal(undefined)

  useEffect(() => {
    const query = parse(search)

    const message =
      query?.pdn === 'success'
        ? 'Твои данные успешно сохранены'
        : query?.pdn === 'error'
        ? 'Твои данные не сохранены'
        : ''

    switch (true) {
      case query?.pdn === 'success' || query?.pdn === 'error':
        setAlert({ alert: { message } })
        navigate({ search: '' })
        break
      default:
        break
    }
  }, [pathname, prevLocation, search])

  return (
    <Container sx={infoUserStyles.section}>
      <Container sx={infoUserStyles.content}>
        <Grid
          container
          justifyContent="space-between"
          direction={md ? 'column' : 'row'}
          alignItems={{ xs: 'center', md: 'stretch' }}
        >
          <Grid item sx={infoUserStyles.boxHolder}>
            <Box sx={infoUserStyles.infoCheckBox}>
              <Grid
                container
                maxWidth="100%"
                alignItems="center"
                justifyContent="space-between"
                sx={infoUserStyles.infoTitle}
              >
                <Grid item>Мои чеки</Grid>
                <Grid item fontSize="150%">
                  {docs.total}
                </Grid>
              </Grid>
              <Grid
                container
                maxWidth="100%"
                alignItems="center"
                justifyContent="space-between"
                sx={infoUserStyles.infoTitle}
              >
                <Grid item>Мои призы</Grid>
                <Grid item fontSize="150%">
                  {prizes.total}
                </Grid>
              </Grid>
              <Box style={{ width: '100%', height: 1, backgroundColor: '#D8D8D8' }} m="34px 0 14px 0" />
              <UpdateSubscribeForm />
            </Box>
          </Grid>

          <Grid item sx={infoUserStyles.boxHolder}>
            <Box sx={infoUserStyles.infoBox}>
              <Box sx={infoUserStyles.infoTitle}>Зарегистрируйте чек</Box>
              <Box sx={infoUserStyles.infoText}>
                В чеке должен быть минимум один акционный продукт.
                <Box mt="10px">На фото должны быть чётко видны данные&#8239;&#8239;чека и QR-код.</Box>
                <Box mt="10px">Чек должен быть постоплатным, предоплатные чеки не принимаются.</Box>
              </Box>
              <Box display="flex" m={{ xs: '15px 0 20px', md: '15px 0 30px' }} justifyContent="center">
                <RequireCheck onOpenWidget={() => onOpenModal('reg-doc')} />
              </Box>
              <Box maxWidth={290} m="0 auto">
                <WidgetRegisterCheck
                  onClose={onCloseModal}
                  show={modal === 'reg-doc'}
                  onSuccess={updateInfoUserRegDoc}
                  onOpen={() => onOpenModal('reg-doc')}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Grid container justifyContent="center" m="16px 0">
          <Grid item>
            <PdnWidget info={dataUser} />
          </Grid>
        </Grid>
      </Container>
    </Container>
  )
}
