/* eslint-disable import/order */
import './styles/app.scss'
import './styles/toast.scss'
import './styles/loader.scss'
import './styles/pickers.scss'
import './styles/preloader.scss'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './app'

import { Provider } from 'react-redux'
import { ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { theme } from './theme'
import { store } from './store'

import ruLocale from 'date-fns/locale/ru'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { ErrorFallbackPage } from './pages'

import { updateAppToast } from './toast'

import 'moment-timezone'
import 'moment/locale/ru'
import moment from 'moment'
import Moment from 'react-moment'

moment.locale('ru')
moment.defaultFormat = 'DD.MM.YYYY'
Moment.globalLocale = 'ru'
Moment.globalFormat = 'DD.MM.YYYY'

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <BrowserRouter basename="/">
      <ThemeProvider theme={theme}>
        <ErrorBoundary FallbackComponent={ErrorFallbackPage}>
          <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ruLocale}>
            <App />
          </LocalizationProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
)
