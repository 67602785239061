import { FC, JSX } from 'react'
import { Box, Table, TableHead, TableBody, TableRow, TableCell, Hidden } from '@mui/material'
import Moment from 'react-moment'
import { IWinnersTableList } from '../../../../types'
import { PaginationCustom } from '../../../../components'

export const WinnersList: FC<IWinnersTableList> = ({
  page,
  total,
  per_page,
  last_page,
  data = [],
  changePage = () => {},
}): JSX.Element => {
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <Hidden smDown>
              <TableCell>
                <Box>Номер телефона</Box>
              </TableCell>
              <TableCell>
                <Box>Приз</Box>
              </TableCell>
            </Hidden>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item, index) => (
            <TableRow key={index}>
              <Hidden smDown>
                <TableCell>{item.user?.phone}</TableCell>

                <TableCell>{item.prize?.name}</TableCell>
              </Hidden>

              <Hidden smUp>
                <TableCell>
                  <Box textAlign="left" style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Box mb="5px" width="fit-content" whiteSpace="nowrap" m="0 5px">
                      <strong>Номер телефона</strong>:&nbsp;{item.user?.phone}
                    </Box>
                    <Box mb="5px" width="fit-content" m="0 5px">
                      <strong>Приз</strong>:&nbsp;{item.prize?.name}
                    </Box>
                  </Box>
                </TableCell>
              </Hidden>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {total > per_page && (
        <Box py={4} width="100%" display="flex" justifyContent="center">
          <PaginationCustom count={last_page} page={page} onChange={changePage} />
        </Box>
      )}
    </>
  )
}
