import { FC, JSX } from 'react'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { autocomleteInputOffProps } from './props-autocomplete'
import { GenericTextInput } from './generic-text-input'

type TextProps = {
  name: string
  rows?: number
  label?: string
  inputProps?: any
  focused?: boolean
  required?: boolean
  disabled?: boolean
  multiline?: boolean
  placeholder?: string
  form: UseFormReturn<any>
  options?: RegisterOptions
  variant?: 'standard' | 'filled' | 'outlined'
}

export const TextInput: FC<TextProps> = ({
  form,
  name,
  variant = 'outlined',
  placeholder,
  label = 'Имя',
  disabled = false,
  required = true,
  focused,
  options,
  inputProps,
  multiline = false,
  rows = 1,
}): JSX.Element => {
  const { register, formState } = form

  return (
    <GenericTextInput
      register={register(name, { required, ...options })}
      InputProps={{ ...(autocomleteInputOffProps as any), ...inputProps }}
      helperText={formState.errors[name]?.message}
      placeholder={placeholder}
      multiline={multiline}
      disabled={disabled}
      variant={variant}
      focused={focused}
      label={label}
      rows={rows}
    />
  )
}
