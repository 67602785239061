import { FC, JSX } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import { PrivateRoute, PrivateLogout } from '../components'
import { MainPage, LogoutPage, ProfilePage, NotFoundPage } from '../pages'

export const RoutesRoot: FC = (): JSX.Element => {
  const location = useLocation()

  return (
    <Routes location={location}>
      <Route key="index" path="/" element={<MainPage />} />
      <Route key="notfound" path="*" element={<NotFoundPage />} />
      <Route key="logout" path="/logout" element={<PrivateLogout component={LogoutPage} />} />
      <Route key="profile" path="/profile" element={<PrivateRoute component={ProfilePage} />} />
    </Routes>
  )
}
