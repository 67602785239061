/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, JSX, useState } from 'react'
import { Box, Collapse, Grid, Theme, useTheme, useMediaQuery, SxProps } from '@mui/material'
import { Modal } from '../modal'
import { img } from '../../imgs'
import { TrackEvent } from '../GA'

const RequireCheckModalName = 'requireCheck'

export const RequireCheck: FC<{ onOpenWidget?: () => void; type?: 'widget' }> = ({
  type,
  onOpenWidget,
}): JSX.Element => {
  const { breakpoints: points } = useTheme()

  const sm = useMediaQuery(points.down(600))

  const [collapse, setCollapse] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)

  const onOpen = () => {
    setCollapse(true)
    setShow(true)
    TrackEvent({
      eventCategory: 'Click',
      eventAction: 'click_button',
      eventLabel: 'Требования к чеку',
    })
  }

  const onCloseModal = () => {
    setCollapse(false)
    setShow(false)
  }

  const handleWidget = () => {
    onCloseModal()
    if (onOpenWidget) setTimeout(() => onOpenWidget(), 200)
  }

  return (
    <>
      <Box sx={type === 'widget' ? requireStyles.linkWidget : requireStyles.link} onClick={onOpen}>
        Посмотреть требования к чеку
      </Box>

      <Modal
        open={show}
        sx={{ '& .MuiPaper-root': { maxWidth: '720px!important' } }}
        title="Требования к чеку"
        name={RequireCheckModalName}
        onClose={onCloseModal}
      >
        <Collapse in={collapse}>
          <Grid container justifyContent="center" alignItems="flex-start" spacing={sm ? 0 : 3}>
            <Grid item xs={10} sm={5}>
              <img src={img.check} width="100%" alt="" />
            </Grid>
            <Grid item xs={11} sm={6}>
              <Box sx={requireStyles.text}>
                <Box sx={requireStyles.titleText}>На чеке должно быть:</Box>
                <ul>
                  <li>Наименование Магазина</li>
                  <li>Номер чека</li>
                  <li>Дата и время покупки</li>
                  <li>Наличие не менее 1 (одной) единицы акционного продукта</li>
                  <li>Итоговая сумма покупки</li>
                  <li>QR-код, а в его отсутствие - номера ФН, ФД, ФП/ФПД</li>
                </ul>
              </Box>
              <Box sx={requireStyles.footerText}>
                *При отсутствии в чеке любого из перечисленных пунктов чек будет отклонен
              </Box>
              <Box sx={requireStyles.footerText}>
                ** Чек должен быть постоплатным, предоплатные чеки не принимаются.
              </Box>
            </Grid>
            {/* <Grid item> */}
            {/*  <Button fullWidth onClick={handleWidget}> */}
            {/*    <Box sx={btnRed}>Зарегистрировать чек</Box> */}
            {/*  </Button> */}
            {/* </Grid> */}
          </Grid>
        </Collapse>
      </Modal>
    </>
  )
}

type requireStylesProps = 'link' | 'linkWidget' | 'titleText' | 'footerText' | 'text'

export const requireStyles: Record<requireStylesProps, SxProps<Theme> | undefined> = {
  link: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    transition: '0.3s',
    textAlign: 'center',
    display: 'inline-block',
    textDecoration: 'underline',
    color: palette.secondary.contrastText,
    '-webkit-tap-highlight-color': 'transparent',
    textDecorationColor: `${palette.secondary.contrastText}!important`,
    '&:hover': { color: '#E72AB0', textDecorationColor: `#E72AB0!important` },
    // [down('lg')]: { fontSize: 19 },
    [down('md')]: { mb: '10px' },
    [down('sm')]: {
      fontSize: 17,
      fontWeight: 400,
    },
  }),
  linkWidget: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 16,
    fontWeight: 600,
    cursor: 'pointer',
    transition: '0.2s',
    textAlign: 'center',
    display: 'inline-block',
    textDecoration: 'underline',
    color: palette.secondary.contrastText,
    '-webkit-tap-highlight-color': 'transparent',
    textDecorationColor: `${palette.secondary.contrastText}!important`,
    '&:hover': { textDecorationColor: `#E72AB0!important` },
    [down('md')]: { mb: '10px' },
    [down('sm')]: {
      fontSize: 17,
    },
  }),
  titleText: ({ breakpoints: { down } }: Theme) => ({
    fontSize: 18,
    fontWeight: 700,
    [down('md')]: {},
  }),
  footerText: ({ breakpoints: { down } }: Theme) => ({
    mt: '10px',
    fontSize: 16,
    lineHeight: 1.3,
    fontWeight: 400,
    [down('md')]: {},
  }),
  text: ({ palette }: Theme) => ({
    m: '20px 0',
    fontSize: 18,
    lineHeight: 2,
    fontWeight: 400,
    '& ul': { pl: '25px', lineHeight: 2 },
    '& li': { lineHeight: 1.4, mb: '10px' },
    '& li::marker': { color: palette.secondary.contrastText },
  }),
}
