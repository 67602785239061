/* eslint-disable max-len */
import { FC, JSX } from 'react'

interface IIcon {
  width?: number
  height?: number
}

export const CloseIcon: FC<IIcon> = ({ width = 34, height = 34 }): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 68 68" fill="none">
    <path d="M58 10L10 58M10 10L58 58" stroke="black" strokeWidth="6.34" />
  </svg>
)
