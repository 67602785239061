import { ObjectShape, object, setLocale } from 'yup'
import { str } from '../../utils'

setLocale({
  mixed: {
    required: 'Поле не заполнено',
    default: 'Поле заполнено неверно',
    notType: 'Неверный тип данных',
  },

  string: {
    email: str.normalize('Указан некорректный e-mail') as string,
    min: ({ min }) => `Минимальная длина поля ${min} символов`,
    max: ({ max }) => `Максимальная длина поля ${max} символов`,
    matches: 'Поле заполнено неверно',
    length: ({ length }) => `Должно быть ровно ${length} символов`,
  },

  number: {
    min: ({ min }) => `Минимальное число: ${min}`,
    max: ({ max }) => `Максимальное число: ${max}`,
    lessThan: ({ less }) => `Число должно быть меньше чем ${less}`,
    moreThan: ({ more }) => `Число должно быть больше чем ${more}`,
    positive: 'Ожидается положительное число',
    negative: 'Ожидается отрицательное число',
  },

  date: {
    min: ({ min }) => `Минимальная дата в акции ${min}`,
    max: ({ max }) => `Максимальная дата в акции ${max}`,
  },
})

export const regexp = {
  // eslint-disable-next-line max-len
  date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
  time: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/i,
}

export const validation = (s: ObjectShape) => object().shape(s)
