import { FC, JSX } from 'react'
import { Navigate } from 'react-router-dom'
import { useReduxSelector } from '../../hooks'
import { Preloader } from '../preloader'

interface PrivateLogoutProp {
  component: FC
}

export const PrivateLogout: FC<PrivateLogoutProp> = ({ component: Component }): JSX.Element => {
  const { loading, auth } = useReduxSelector((state) => state.auth)

  switch (true) {
    case loading:
      return <Preloader />
    case !auth:
      return <Navigate to={{ pathname: '/' }} />
    default:
      return <Component />
  }
}
