import React, { FC, JSX, useState } from 'react'
import {
  Box,
  Grid,
  Table,
  Theme,
  Hidden,
  Popover,
  SxProps,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  IconButton,
  useMediaQuery,
} from '@mui/material'
import Moment from 'react-moment'
import { Help as HelpIcon } from '@mui/icons-material'
import { Loader, PaginationCustom } from '../../../../../components'
import { Docs, IDocTableList } from '../../../../../types'
import { useReduxSelector } from '../../../../../hooks'
import { str } from '../../../../../utils'

interface StatusProps {
  status?: string
  statusName?: string
  anchorOrigin?: void
  transformOrigin?: void
  moderateReason?: string
}

const Status: React.FC<StatusProps> = ({
  anchorOrigin = {},
  transformOrigin = {},
  status,
  statusName,
  moderateReason,
}): JSX.Element => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))

  const [anchorEl, setAnchorEl] = useState(null)

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Box display="flex" flexDirection="row" justifyContent={{ xs: 'flex-start', sm: 'center' }}>
        <Box flex="0 0 auto">
          {sm && str.normalize('Статус:&nbsp;')}
          {statusName}
        </Box>
        {moderateReason && status === 'REJECT' && (
          <Box flex="0 0 auto">
            <IconButton
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              color="primary"
              sx={infoDocsStyles.iconButton}
              onClick={handlePopoverOpen}
              tabIndex={-1}
            >
              <HelpIcon sx={infoDocsStyles.icon} />
            </IconButton>
            <Popover
              open={open}
              anchorEl={anchorEl}
              disableRestoreFocus
              id="mouse-over-popover"
              sx={infoDocsStyles.popover}
              onClose={handlePopoverClose}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right', ...anchorOrigin }}
              transformOrigin={{ vertical: 'top', horizontal: 'right', ...transformOrigin }}
            >
              <Box p={2}>{moderateReason}</Box>
            </Popover>
          </Box>
        )}
      </Box>
    </>
  )
}

const countArray = (ar: any[]) => (ar ? ar.length : 0)

export const DocsList: FC<IDocTableList> = ({ updateInfoDoc = () => {} }): JSX.Element => {
  const {
    loading,
    data: { docs },
  } = useReduxSelector((state) => state.profile)

  const [page, setPage] = useState<number>(1)
  const [perPage, setPerPage] = useState<number>(5)

  const handleChange = (event: any, value: number) => {
    setPage(value)
  }

  return !loading ? (
    docs.list && countArray(docs.list) > 0 ? (
      <Box mt={2}>
        <Table>
          <TableHead>
            <TableRow>
              <Hidden smDown>
                <TableCell>
                  <Box>Дата загрузки</Box>
                </TableCell>
                <TableCell>
                  <Box>ФП чека</Box>
                </TableCell>
                <TableCell>
                  <Box>Статус</Box>
                </TableCell>
              </Hidden>
            </TableRow>
          </TableHead>
          {docs?.list.length > 0 && (
            <TableBody>
              {docs?.list.slice(perPage * (page - 1), page * perPage).map((i: Docs, index: number) => (
                <TableRow key={index}>
                  <Hidden smDown>
                    <TableCell>
                      <Moment format="DD.MM.YYYY">{i.create_datetime}</Moment>
                    </TableCell>
                    <TableCell>
                      <Box>{i.number}</Box>
                    </TableCell>
                    <TableCell>
                      {typeof i.status.id === 'string' && (
                        <Status status={i.status.id} statusName={i.status.name} moderateReason={i.status_info} />
                      )}
                    </TableCell>
                  </Hidden>

                  <Hidden smUp>
                    <TableCell>
                      <Box textAlign="left">
                        <Box mb="5px">
                          Дата загрузки:&nbsp;<Moment format="DD.MM.YYYY">{i.create_datetime}</Moment>
                        </Box>
                        <Box mb="5px">ФП чека:&nbsp;{i.number}</Box>
                        <Box mb="5px">
                          {typeof i.status.id === 'string' && (
                            <Status status={i.status.id} statusName={i.status.name} moderateReason={i.status_info} />
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </Hidden>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>

        {docs.list && countArray(docs.list) > perPage && (
          <Box pt={3} width="100%" display="flex" justifyContent="center">
            <PaginationCustom page={page} onChange={handleChange} count={Math.ceil(countArray(docs.list) / perPage)} />
          </Box>
        )}
      </Box>
    ) : (
      <Box sx={infoDocsStyles.text} my={1} textAlign="center">
        Здесь будет информация
        <br />о ваших чеках
      </Box>
    )
  ) : (
    <Grid container justifyContent="center" alignItems="center" height={300}>
      <Grid item>
        <Loader />
      </Grid>
    </Grid>
  )
}

type InfoDocsStylesProps = 'text' | 'iconButton' | 'icon' | 'popover'

const infoDocsStyles: Record<InfoDocsStylesProps, SxProps<Theme> | undefined> = {
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 20,
    fontWeight: 400,
    padding: '50px 25px',
    color: palette.primary.dark,
    [down('sm')]: { fontSize: 17 },
  }),
  iconButton: ({ breakpoints: { down }, palette }: Theme) => ({
    paddingTop: '0px!important',
    paddingBottom: '0px!important',
    '&:hover': {
      backgroundColor: 'transparent!important',
    },
  }),
  icon: ({ breakpoints: { down }, palette }: Theme) => ({
    width: '26px!important',
    height: '26px!important',
    color: palette.primary.dark,
  }),
  popover: ({ breakpoints: { down }, palette }: Theme) => ({
    '& .MuiPaper-root': {
      fontSize: 18,
      color: palette.primary.main,
      backgroundColor: '#343F2B!important',
    },
  }),
}
