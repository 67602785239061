import { SxProps, Theme } from '@mui/material'
import { img } from '../../../../imgs'

type WinnersStylesProps =
  | 'section'
  | 'container'
  | 'title'
  | 'boxSearch'
  | 'text'
  | 'table'
  | 'bg'
  | 'bottle'
  | 'ice'
  | 'noalco'
  | 'tabs'
  | 'tabItem'

export const winnersStyles: Record<WinnersStylesProps, SxProps<Theme> | undefined> = {
  section: ({ breakpoints: { down }, font, palette }: Theme) => ({
    fontSize: 20,
    width: '100%',
    height: '100%',
    pt: '100px',
    overflow: 'hidden',
    [down('lg')]: {
      p: '10vw 0',
    },
    [down('sm')]: {
      fontSize: 15,
      p: '10vw 0 0',
    },
  }),
  container: ({ breakpoints: { down } }: Theme) => ({}),
  title: ({ breakpoints: { down }, palette }: Theme) => ({
    fontSize: 42,
    fontWeight: 800,
    m: '0 auto',
    textAlign: 'center',
    textTransform: 'uppercase',
    color: palette.primary.dark,
    [down(1350)]: {
      fontSize: '3.11vw',
    },
    [down('md')]: {
      fontSize: '3.81vw',
    },
    [down('sm')]: {
      fontSize: '7vw',
    },
  }),
  boxSearch: ({ breakpoints: { down } }: Theme) => ({
    display: 'flex',
    justifyContent: 'center',
    mb: '20px',
    '& .MuiOutlinedInput-input': {
      [down('sm')]: {
        fontSize: '18px!important',
      },
    },
    '& .MuiInputLabel-root': {
      // transform: 'translate(17px, 50%)',
      marginTop: '2px!important',
      fontSize: '16px!important',
      [down('sm')]: {
        // transform: 'translate(17px, 70%)',
      },
    },
    '& .MuiInputLabel-shrink': {
      marginTop: '0px!important',
      // fontSize: '12px!important',
      // transform: 'translate(17px, 10%)!important',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: '#CFCFCF!important',
      '& span': {
        fontSize: '12px!important',
      },
    },
    '& .MuiButtonBase-root': {
      backgroundColor: '#AB0F7E!important',
      mr: '10px',
      borderRadius: '50%',
    },
    '& .MuiInputBase-input': {
      padding: '0px 0px 0px 14px',
      alignContent: 'centet',
    },
    [down('md')]: {},
    [down('sm')]: {},
  }),
  text: ({ breakpoints: { down }, palette }: Theme) => ({
    mt: '80px',
    fontSize: 20,
    lineHeight: 1.4,
    fontWeight: 500,
    color: palette.primary.dark,
    [down('sm')]: {
      mt: '60px',
      fontSize: 17,
    },
  }),
  table: ({ breakpoints: { down }, palette }: Theme) => ({
    width: '100%',
    pt: '20px',
  }),
  bg: ({ breakpoints: { down }, palette }: Theme) => ({
    position: 'absolute',
    top: '0',
    left: '-10%',
    zIndex: -1,
    '& img': {
      width: '90%',
    },
    [down(1800)]: {
      top: '160px',
    },
    [down(1400)]: {
      top: 'unset',
      bottom: '-140px',
    },
    [down('sm')]: {
      mt: '10vw',
      bottom: '-240px',
      '& img': {
        width: '800px',
      },
    },
  }),
  bottle: ({ breakpoints: { down }, palette }: Theme) => ({
    position: 'absolute',
    bottom: 0,
    right: '100px',
    zIndex: -1,
    width: '160px',
    '& img': {
      width: '100%',
    },
    [down(1800)]: {
      display: 'none',
    },
    [down('lg')]: {
      display: 'block',
      width: '100px',
      right: '70px',
    },
    [down('md')]: {
      width: '80px',
      right: '50px',
    },
    [down('sm')]: {
      width: '130px',
    },
  }),
  ice: ({ breakpoints: { down }, palette }: Theme) => ({
    position: 'absolute',
    bottom: 0,
    right: '-140px',
    zIndex: -1,
    width: '360px',
    '& img': {
      animationDelay: '100ms',
      width: '100%',
    },
    [down(1800)]: {
      display: 'none',
    },
    [down('lg')]: {
      display: 'block',
      right: '-50px',
      width: '200px',
    },
    [down('md')]: {
      width: '160px',
    },
    [down('sm')]: {
      width: '180px',
    },
  }),
  noalco: ({ breakpoints: { down }, palette }: Theme) => ({
    position: 'absolute',
    bottom: '20px',
    left: '20px',
    zIndex: -1,
    width: '133px',
    '& img': {
      width: '100%',
    },
    [down(1600)]: {
      left: '200px',
    },
    [down('lg')]: {
      bottom: '-8vw',
    },
    [down('md')]: {
      left: '20px',
    },
    [down('sm')]: {
      width: '180px',
      left: '30%',
    },
    [down(450)]: {
      left: '20px',
    },
  }),
  tabs: ({ breakpoints: { down } }: Theme) => ({
    width: 1,
    maxWidth: 900,
    [down(540)]: { width: '100%' },
  }),
  tabItem: ({ breakpoints: { down } }: Theme) => ({
    fontWeight: 800,
    whiteSpace: 'normal',
    [down('sm')]: {
      fontSize: '20px',
    },
  }),
}
