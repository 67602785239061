import { FC, JSX, useCallback, useEffect, useLayoutEffect, useState } from 'react'
import { Box, Container } from '@mui/material'
import Moment from 'react-moment'
import { profileStyles } from './profile.styles'
import { Layout } from '../../components'
import { InfoUserSection, InfoProfileSection, GameSection } from './section'
import {
  getInfoShop,
  getInfoBall,
  getInfoDocs,
  getInfoPrizes,
  getInfoRequest,
  getInfoBallBalance,
  getInfoDocsBalance,
  getInfoPrizesBalance,
  getInfoRequestBalance,
} from '../../store/profile/profile.service'
import { useReduxActions, useReduxDispatch, useReduxSelector } from '../../hooks'
import { getUserMessage, getUserMessageRead } from '../../api'
import { MessageUser, Prizes, ServiceResponce, TypePrize } from '../../types'
import { getInfoUser } from '../../store/auth/auth.service'
import { getInfoGame } from '../../store/game/game.service'

export const ProfilePage: FC = (): JSX.Element => {
  const dispatch = useReduxDispatch()
  const { setAlert } = useReduxActions()

  const { data: constants } = useReduxSelector((state) => state.constants)

  const {
    data: { balls, prizes },
  } = useReduxSelector((state) => state.profile)

  const { PROJECT_START_ACTION = 0, PROJECT_STOP_ACTION = 0 } = constants || {}

  const [showSelectColorPrizes, setShowSelectColorPrizes] = useState<boolean>(false)
  const [selectColorPrizes, setSelectColorPrizes] = useState<{ id: number; type: TypePrize[] }[] | undefined>(undefined)

  const uploadInfoUser = useCallback(() => {
    dispatch(getInfoUser())
    setTimeout(() => uploadInfoUser(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoBallsBalance = useCallback(() => {
    dispatch(getInfoBallBalance())
    setTimeout(() => uploadInfoBallsBalance(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoDocsBalance = useCallback(() => {
    dispatch(getInfoDocsBalance())
    setTimeout(() => uploadInfoDocsBalance(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoPrizesBalance = useCallback(() => {
    dispatch(getInfoPrizesBalance())
    setTimeout(() => uploadInfoPrizesBalance(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoBalls = useCallback(() => {
    dispatch(getInfoBall())
    setTimeout(() => uploadInfoBalls(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoDocs = useCallback(() => {
    dispatch(getInfoDocs())
    setTimeout(() => uploadInfoDocs(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadInfoPrizes = useCallback(() => {
    dispatch(getInfoPrizes())
    setTimeout(() => uploadInfoPrizes(), 1000 * 60 * 30) // 30 минут
  }, [])

  const uploadGameData = useCallback(() => {
    dispatch(getInfoGame())
    setTimeout(() => uploadGameData(), 1000 * 60 * 30)
  }, [])

  const uploadMessageLk = useCallback(() => {
    getUserMessage().then(({ data }: ServiceResponce<{ message: MessageUser }>) => {
      if (data?.message) {
        setAlert({
          alert: {
            message: data.message.message,
            onClose: () => {
              getUserMessageRead(data?.message).then(() => {
                uploadMessageLk()
              })
            },
          },
        })
      }
    })
  }, [])

  useLayoutEffect(() => {
    uploadInfoDocsBalance()
    uploadInfoPrizesBalance()
    uploadInfoDocs()
    uploadMessageLk()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Box sx={profileStyles.page}>
        <Box sx={profileStyles.userSection}>
          <Container maxWidth="lg">
            <Box sx={profileStyles.title}>Личный кабинет</Box>
            <Box sx={profileStyles.subTitle}>
              Срок регистрации чеков c
              <span style={{ whiteSpace: 'nowrap' }}>
                <Moment format=" DD.MM.YYYY г.">{PROJECT_START_ACTION}</Moment> по{' '}
                <Moment format="DD.MM.YYYY г.">{PROJECT_STOP_ACTION}</Moment>
              </span>
            </Box>
          </Container>
          <InfoUserSection updateInfoUserRegDoc={uploadInfoDocs} />
        </Box>

        <Container sx={profileStyles.infoSection}>
          <InfoProfileSection uploadInfoProfile={() => {}} />
        </Container>
      </Box>
    </Layout>
  )
}
