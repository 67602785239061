import { SxProps, Theme } from '@mui/material'

type mainStylesProps = 'page'

export const mainStyles: Record<mainStylesProps, SxProps<Theme> | undefined> = {
  page: ({ breakpoints: { down }, font, palette }: Theme) => ({
    position: 'relative',
    overflow: 'hidden',
    background: '#FFFFFF',
  }),
}
