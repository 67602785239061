import { FC, JSX, useEffect, useRef, useState, RefObject, useMemo, MutableRefObject } from 'react'
import { Box, Container, Grid, Button, Theme, useMediaQuery } from '@mui/material'
import HtmlReactParser from 'html-react-parser'
import { useNavigate } from 'react-router-dom'
import Slider from 'react-slick'
import { prizesStyles } from './prizes.styles'
import { img } from '../../../../imgs'
import { EnergyIcon } from './icons'
import { useDetectDevice, useReduxSelector } from '../../../../hooks'
import { DetectDevice } from '../../../../types'

type Card = {
  id: number
  url: string
  text: any
  title: string
}

const Card: FC<Card> = ({ id, url, title, text }) => {
  return (
    <Grid key={`prizes-${id}`} item>
      <Box sx={prizesStyles.card}>
        <Box sx={prizesStyles.img}>
          <img id={`stage-${id}`} src={url} alt="" />
        </Box>
        <Box sx={prizesStyles.titleCard}>{HtmlReactParser(title)}</Box>
        {text && <Box sx={prizesStyles.textCard}>{text}</Box>}
      </Box>
    </Grid>
  )
}

const custom_pag = (i: any) => {
  return (
    <div className="ts-slick__dots--custom">
      <div className="loading" />
    </div>
  )
}
const custom_dots = (dots: any) => {
  return <ul>{dots}</ul>
}

export function useIsVisible(ref: MutableRefObject<Element | undefined>) {
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => setIntersecting(entry.isIntersecting))

    observer.observe(ref.current!)
    return () => {
      observer.disconnect()
    }
  }, [ref])

  return isIntersecting
}

export const PrizesSection: FC = (): JSX.Element => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('sm'))
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down('md'))
  const navigate = useNavigate()
  const detectDevice = useDetectDevice()

  const { auth, data: userData } = useReduxSelector((state) => state.auth)

  const onNavigate = () => {
    if (auth) navigate('../profile')
    // else navigate({ pathname: '../', search: 'signin' })
    else {
      const element = document.getElementById('login')
      element?.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const settings = {
    customPaging: custom_pag,
    speed: 300,
    dots: true,
    appendDots: custom_dots,
    infinite: true,
    easing: 'cubic',
    slidesToShow: 1,
    initialSlide: 0,
    slidesToScroll: 1,
    className: 'custom-slider',
    arrows: false,
  }

  return (
    <Box sx={prizesStyles.section}>
      <Container maxWidth="lg" sx={prizesStyles.top}>
        <Box sx={prizesStyles.topLeft}>
          <Box sx={prizesStyles.topLeftTitle}>
            Покупайте контактные линзы ACUVUE
            <Box
              component="span"
              fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
              style={{ verticalAlign: 'super' }}
            >
              ®
            </Box>{' '}
            <Box component="span" sx={prizesStyles.wrap}>
              с приложением MyACUVUE
              <Box
                component="span"
                fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
                style={{ verticalAlign: 'super' }}
              >
                ®
              </Box>
            </Box>{' '}
            и участвуйте автоматически,{' '}
            <Box component="span" sx={prizesStyles.wrap}>
              без загрузки чеков!
            </Box>
          </Box>
          <Box style={{ display: 'flex', flexDirection: 'column', marginTop: md ? '20px' : '50px' }}>
            <Box style={{ display: 'flex', flexDirection: 'row' }}>
              <Box component="img" src={img.prizes_qr} sx={prizesStyles.qr} />
              <Box sx={prizesStyles.qrDesc}>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">Все для заботы о зрении в одном приложении</Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">
                    Кешбэк баллами и акции от ACUVUE
                    <Box
                      component="span"
                      fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }}
                      style={{ verticalAlign: 'super' }}
                    >
                      ®
                    </Box>
                  </Box>
                </Box>
                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Box component="img" src={img.prizes_check_mark} alt="" />
                  <Box component="span">Возможность заказать доставку линз в оптику</Box>
                </Box>
              </Box>
            </Box>
            <Box style={{ display: md ? 'none' : 'flex', flexDirection: 'row', marginTop: '14px' }}>
              <Button sx={prizesStyles.btn}>
                <a href="https://myacuvue.onelink.me/o8U8/ad9cbu3v" target="_blank" rel="noopener noreferrer">
                  Зарегистрироваться
                </a>
              </Button>
              <Box sx={prizesStyles.learnNew}>
                <a href="https://l.ead.me/bfLLGE" target="_blank" rel="noopener noreferrer">
                  Узнать больше
                </a>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={prizesStyles.topRight}>
          <Box component="img" src={img.prizes_top3} alt="" mt="3vw" ml="auto" />
          <Box component="img" src={img.prizes_top1} alt="" />
          <Box component="img" src={img.prizes_top2} alt="" mt="3vw" mr="auto" />
        </Box>
        <Box
          style={{
            display: md ? 'flex' : 'none',
            flexDirection: 'column',
            marginTop: '40px',
            alignItems: 'center',
          }}
        >
          <Button sx={prizesStyles.btn}>
            <a href="https://myacuvue.onelink.me/o8U8/ad9cbu3v" target="_blank" rel="noopener noreferrer">
              Зарегистрироваться
            </a>
          </Button>
          <Box sx={prizesStyles.learnNew}>
            <a href="https://l.ead.me/bfLLGE" target="_blank" rel="noopener noreferrer">
              Узнать больше
            </a>
          </Box>
        </Box>
      </Container>
      <Box maxWidth="lg" sx={prizesStyles.title}>
        30 призов каждый месяц
      </Box>
      <Box maxWidth="lg" sx={prizesStyles.titleDesc}>
        Покупайте контактные линзы ACUVUE
        <Box component="span" fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        и участвуйте в розыгрышах призов
      </Box>
      <Box display={{ xs: 'none', sm: 'block' }}>
        <Container maxWidth="lg">
          <Grid container justifyContent={{ xs: 'center', sm: 'space-around' }}>
            {prizes.map((k: Card) => (
              <Card {...k} />
            ))}
          </Grid>
        </Container>
      </Box>
      <Box display={{ xs: 'block', sm: 'none' }}>
        <Slider {...settings}> {prizes && prizes.map((k: Card) => <Card {...k} />)}</Slider>
      </Box>
      <Container maxWidth="lg" sx={prizesStyles.btn_holder}>
        <Button sx={prizesStyles.btn} onClick={onNavigate}>
          УЧАСТВОВАТЬ
        </Button>
      </Container>
      <Container maxWidth="lg" sx={prizesStyles.mainPrize}>
        <Box sx={prizesStyles.mainPrizeTitle}>ГЛАВНЫЙ ПРИЗ – ПОЕЗДКА НА ДВОИХ В СОЧИ!</Box>
        <Box component="img" src={img.main_prize} sx={prizesStyles.mainPrizeImg} alt="" />
        <Box sx={prizesStyles.mainPrizeDesc}>
          <p style={{ fontWeight: 800 }}>
            Легендарный горнолыжный фестиваль в «Роза Хутор», наполненный ритмом музыки, азартом развлечений и
            захватывающими активностями.
          </p>
          <p>
            <Box component="span" style={{ fontWeight: 800 }}>
              С нас:
            </Box>{' '}
            перелет, трансфер, проживание, ски-пасс и билет на фест на двоих (для Вас и Вашего +1)
          </p>
          <p>
            <Box component="span" style={{ fontWeight: 800 }}>
              С Вас:
            </Box>{' '}
            классное настроение и линзы ACUVUE
            <Box
              component="span"
              fontSize={{ xs: '3.33vw', sm: '1.81vw', md: '20px' }}
              style={{ verticalAlign: 'super' }}
            >
              ®
            </Box>{' '}
            для четкого зрения!
          </p>
          <p style={{ fontWeight: 800, marginTop: '28px' }}>
            Участвуйте в акции и получите шанс посетить легендарный фестиваль!
          </p>
        </Box>
      </Container>
    </Box>
  )
}

const prizes: Card[] = [
  {
    id: 1,
    url: img.prizesStage1,
    title: 'Колонка',
    text: (
      <Box>
        Привнесите комфорт в вашу повседневную жизнь с умной колонкой и линзами ACUVUE
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>
      </Box>
    ),
  },
  {
    id: 2,
    url: img.prizesStage2,
    title: 'Наушники',
    text: (
      <Box>
        Наслаждайтесь музыкой на полную, а линзы ACUVUE
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        могут позволить вам видеть мир без ограничений
      </Box>
    ),
  },
  {
    id: 3,
    url: img.prizesStage3,
    title: 'Очки-массажер',
    text: (
      <Box>
        Снимите напряжение с очками-массажерами, а линзы ACUVUE
        <Box component="span" fontSize={{ xs: '1.66vw', sm: '0.9vw', md: '10px' }} style={{ verticalAlign: 'super' }}>
          ®
        </Box>{' '}
        позаботятся о вашем зрении
      </Box>
    ),
  },
]
