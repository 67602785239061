import { FC, JSX, useState, useEffect, useRef } from 'react'
import { Box, Grid } from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import queryString from 'query-string'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { EmailInput, formLocale, Snackbar, SubmitButton, validation } from '../../components'
import { FormProps } from '../../types'
import { forgotPassword } from '../../api'
import { useReduxActions, useReduxSelector } from '../../hooks'

interface ForgotUser {
  email: string
}

type ForgotProps = { email: string }

const schema = validation({
  email: yup
    .string()
    .required()
    .max(50)
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Указан некорректный e-mail'),
})

export const ForgotForm: FC<{ rootModal?: string }> = ({ rootModal }): JSX.Element => {
  const { parse } = queryString
  const { search } = useLocation()
  const navigate = useNavigate()
  const { setAlert } = useReduxActions()
  const recaptchaRef = useRef<ReCAPTCHA | null>(null)
  const parsed = parse(search)

  const { serverError } = formLocale

  const { data: constants } = useReduxSelector((state) => state.constants)

  const hookForm = useForm<any>({
    defaultValues: { email: parsed.login },
    resolver: yupResolver(schema),
    mode: 'onBlur',
  })

  const [formProps, setFormProps] = useState<FormProps<ForgotProps>>({
    data: { email: '' },
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const onRecaptchaChange = async (recaptcha: string) => {
    const { email = '' } = formProps.data || {}

    forgotPassword({ email, recaptcha })
      .then(({ status = 0, message: messages }) => {
        if (status === 1) {
          setAlert({
            alert: {
              message: messages,
              rootModal,
              onClose: () => {
                navigate(`../?signin${hookForm.getValues('email') ? `&login=${hookForm.getValues('email')}` : ''}`, {
                  replace: true,
                })
              },
            },
          })
        } else {
          setFormProps({
            ...formProps,
            processed: false,
            snackbar: { ...formProps.snackbar, message: messages },
          })
        }
      })
      .catch(() => {
        setFormProps({
          ...formProps,
          processed: false,
          snackbar: { ...formProps.snackbar, message: serverError },
        })
      })
      .finally(() => {
        recaptchaRef.current?.reset()
      })
  }

  const onSubmitForm = async (data: ForgotUser) => {
    setFormProps({ ...formProps, data, processed: true })
    recaptchaRef.current?.execute()
  }

  const onRecaptchaExpired = () => {
    setFormProps({
      ...formProps,
      processed: false,
      snackbar: undefined,
    })
  }

  useEffect(() => hookForm.setFocus('email'), [])

  return (
    <form onSubmit={hookForm.handleSubmit(onSubmitForm)} autoComplete="off" noValidate>
      {constants?.RECAPTCHA_SITE_KEY && (
        <ReCAPTCHA
          ref={recaptchaRef}
          size="invisible"
          sitekey={constants?.RECAPTCHA_SITE_KEY}
          onChange={(token) => onRecaptchaChange(token ?? '')}
          onErrored={() => console.error('onRecaptchaErrored')}
          onExpired={onRecaptchaExpired}
        />
      )}

      <Box pt={1} pb={4}>
        <Grid container justifyContent="center">
          <Grid item width="100%" maxWidth={390}>
            <Box width="100%" height={75}>
              <EmailInput name="email" variant="outlined" form={hookForm} disabled={formProps.processed} />
            </Box>
            <Snackbar {...formProps.snackbar} variant="error" />
            <Grid container justifyContent="center" mt={{ xs: 2, sm: 4 }}>
              <Grid item>
                <Box width={{ xs: 280, sm: 310 }}>
                  <SubmitButton
                    fullWidth
                    title="Восстановить"
                    disabled={formProps.processed}
                    processed={formProps.processed}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </form>
  )
}
